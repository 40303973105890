import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Application",
      "ApplicationMethod",
      "ApplicationNature",
      "ApplicationType",
      "Approval",
      "Area",
      "Company",
      "CompletionCertificate",
      "ComponentDataAgentInfo",
      "ComponentDataApplicationServiceProvider",
      "ComponentDataEntry",
      "ComponentDataGeometrySetting",
      "ComponentDataPaymentItem",
      "ComponentDataRequiredDocuments",
      "ComponentDataSet",
      "ComponentDataTextField",
      "ComponentPageContactUs",
      "ComponentPageFreeQuote",
      "ComponentPageHome",
      "ComponentPagePrivacy",
      "ComponentPageTerms",
      "ComponentSharedMetaSocial",
      "ComponentSharedSeo",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiImages",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiReview",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "ContactRequest",
      "DiscussionTopic",
      "DocumentTemplate",
      "DocumentType",
      "EmailDesignerEmailTemplate",
      "EmailEmitterEmail",
      "EmailMessage",
      "I18NLocale",
      "InviteRequiredDocumentType",
      "Invoice",
      "KickoffInvite",
      "KickoffRecord",
      "LetterTemplate",
      "MenusMenu",
      "MenusMenuItem",
      "Note",
      "NotificationMessage",
      "Organization",
      "Payment",
      "Project",
      "Province",
      "Quotation",
      "QuoteItem",
      "Setting",
      "Task",
      "TemplateSubject",
      "UploadFile",
      "UploadFolder",
      "UserProfile",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Website"
    ],
    "WebsiteContentDynamicZone": [
      "ComponentPageFreeQuote",
      "ComponentPageHome",
      "ComponentPagePrivacy",
      "ComponentPageTerms",
      "Error"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Application",
      "ApplicationMethod",
      "ApplicationNature",
      "ApplicationType",
      "Approval",
      "Area",
      "Company",
      "CompletionCertificate",
      "ComponentDataAgentInfo",
      "ComponentDataApplicationServiceProvider",
      "ComponentDataEntry",
      "ComponentDataGeometrySetting",
      "ComponentDataPaymentItem",
      "ComponentDataRequiredDocuments",
      "ComponentDataSet",
      "ComponentDataTextField",
      "ComponentPageContactUs",
      "ComponentPageFreeQuote",
      "ComponentPageHome",
      "ComponentPagePrivacy",
      "ComponentPageTerms",
      "ComponentSharedMetaSocial",
      "ComponentSharedSeo",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiImages",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiReview",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "ContactRequest",
      "DiscussionTopic",
      "DocumentTemplate",
      "DocumentType",
      "EmailDesignerEmailTemplate",
      "EmailEmitterEmail",
      "EmailMessage",
      "I18NLocale",
      "InviteRequiredDocumentType",
      "Invoice",
      "KickoffInvite",
      "KickoffRecord",
      "LetterTemplate",
      "MenusMenu",
      "MenusMenuItem",
      "Note",
      "NotificationMessage",
      "Organization",
      "Payment",
      "Project",
      "Province",
      "Quotation",
      "QuoteItem",
      "Setting",
      "Task",
      "TemplateSubject",
      "UploadFile",
      "UploadFolder",
      "UserProfile",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Website"
    ],
    "WebsiteContentDynamicZone": [
      "ComponentPageFreeQuote",
      "ComponentPageHome",
      "ComponentPagePrivacy",
      "ComponentPageTerms",
      "Error"
    ]
  }
};
      export default result;
    
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    name
    provider
    size
    previewUrl
    alternativeText
    url
  }
}
    `;
export const ApplicationMethodFragmentDoc = gql`
    fragment ApplicationMethod on ApplicationMethodEntity {
  id
  attributes {
    title
    slug
  }
}
    `;
export const DocumentTypeFragmentDoc = gql`
    fragment DocumentType on DocumentTypeEntity {
  id
  attributes {
    title
    requirements
    default
    uuid
  }
}
    `;
export const ApplicationTypeFragmentDoc = gql`
    fragment ApplicationType on ApplicationTypeEntity {
  id
  attributes {
    title
    subtitle
    description
    includes
    excludes
    requirements
    turnaround
    slug
    documentTypes {
      data {
        ...DocumentType
      }
    }
  }
}
    `;
export const AreaFragmentDoc = gql`
    fragment Area on AreaEntity {
  id
  attributes {
    name
    fillColor
    strokeColor
    active
    status
    statusUpdated
    kml {
      data {
        ...File
      }
    }
    geoJson {
      data {
        ...File
      }
    }
    geometryType
    company {
      data {
        id
        attributes {
          name
          companyType
          organization {
            data {
              id
              attributes {
                slug
              }
            }
          }
          applicationType {
            data {
              id
              attributes {
                slug
                title
              }
            }
          }
        }
      }
    }
    category
    provinces {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
}
    `;
export const CompanyFragmentDoc = gql`
    fragment Company on CompanyEntity {
  id
  attributes {
    name
    address
    phone
    companyEmail
    vatNumber
    companyType
    createdAt
    thirdPartyApprovalsRequired
    applicationMethods {
      data {
        ...ApplicationMethod
      }
    }
    documentTemplates {
      data {
        ...File
      }
    }
    appMethodDescription
    documentTypes {
      data {
        ...DocumentType
      }
    }
    registrationNumber
    applicationType {
      data {
        ...ApplicationType
      }
    }
    organization {
      data {
        id
        attributes {
          slug
          name
        }
      }
    }
    areas(pagination: {limit: -1}) {
      data {
        ...Area
      }
    }
    userProfiles {
      data {
        id
        attributes {
          fullName
          primaryEmail
          secondaryEmail
          primaryPhone
          secondaryPhone
          address
        }
      }
    }
    geometryLastUpdate
    link
  }
}
    `;
export const ApplicationNatureFragmentDoc = gql`
    fragment ApplicationNature on ApplicationNatureEntity {
  id
  attributes {
    title
    documentTypes {
      data {
        ...DocumentType
      }
    }
  }
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on UserProfileEntity {
  id
  attributes {
    uuid
    createdAt
    profileType
    isDefaultAgent
    orgAgentStatus
    avatar {
      data {
        ...File
      }
    }
    applicantProjects(pagination: {limit: -1}) {
      data {
        id
        attributes {
          applications(pagination: {limit: -1}) {
            data {
              id
            }
          }
        }
      }
    }
    ownerProjects(pagination: {limit: -1}) {
      data {
        id
        attributes {
          applications(pagination: {limit: -1}) {
            data {
              id
            }
          }
        }
      }
    }
    primaryEmail
    secondaryEmail
    primaryPhone
    secondaryPhone
    address
    fullName
    responsibility
    levelOfApproval
    status
    organization {
      data {
        id
        attributes {
          name
          slug
        }
      }
    }
    company {
      data {
        ...Company
      }
    }
    consultingCompanies {
      data {
        ...Company
      }
    }
    tasks {
      data {
        id
      }
    }
    user {
      data {
        id
        attributes {
          email
          role {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const EmailMessageFragmentDoc = gql`
    fragment EmailMessage on EmailMessageEntity {
  id
  attributes {
    status
    createdAt
    subject
    deliveryMessage
    deliveredAt
    openedAt
    markedAsSpam
    spamComplaintAt
    to
    from
    messageId
    html
    references {
      data {
        id
      }
    }
    attachments {
      data {
        ...File
      }
    }
  }
}
    `;
export const ApprovalFragmentDoc = gql`
    fragment Approval on ApprovalEntity {
  id
  attributes {
    uuid
    name
    type
    createdAt
    status
    description
    deliveryNotes
    isRenewalApplied
    isAffected
    isSiteVisitRequired
    approvalDocumentType
    feedback
    expiryDate
    deliveryDate
    refApproval {
      data {
        id
      }
    }
    emailMessages {
      data {
        ...EmailMessage
      }
    }
    approvalMapLetter {
      data {
        ...File
      }
    }
    approvalLetter {
      data {
        ...File
      }
    }
    attachments {
      data {
        ...File
      }
    }
    company {
      data {
        ...Company
      }
    }
    contacts {
      data {
        ...UserProfile
      }
    }
    agents {
      id
      assignStatus
      agentProfile {
        data {
          ...UserProfile
        }
      }
    }
  }
}
    `;
export const ApplicationFragmentDoc = gql`
    fragment Application on ApplicationEntity {
  id
  attributes {
    title
    uuid
    createdAt
    submittedDate
    involvement
    serviceType
    description
    isGovernmentWork
    duplicationIndex
    isInfoPopupShow
    appLetter {
      data {
        ...File
      }
    }
    requiredDocuments {
      id
      description
      docTypeName
      documents {
        data {
          ...File
        }
      }
    }
    company {
      data {
        ...Company
      }
    }
    applicationType {
      data {
        id
        attributes {
          description
          title
          slug
        }
      }
    }
    progress
    project {
      data {
        id
        attributes {
          uuid
          title
          start
          end
          locationInfo
          description
          category
          projectCenter
          provinces {
            data {
              id
              attributes {
                name
              }
            }
          }
          municipalCompanies {
            data {
              id
              attributes {
                name
              }
            }
          }
          projectNature {
            data {
              ...ApplicationNature
            }
          }
          owner {
            data {
              ...UserProfile
            }
          }
          organization {
            data {
              id
              attributes {
                slug
                name
              }
            }
          }
          mapSnapshot {
            data {
              ...File
            }
          }
          geoJson {
            data {
              ...File
            }
          }
          kml {
            data {
              ...File
            }
          }
          applicantCompany {
            data {
              id
              attributes {
                name
                companyEmail
              }
            }
          }
          applicant {
            data {
              id
              attributes {
                primaryEmail
                fullName
              }
            }
          }
        }
      }
    }
    serviceProviders {
      data {
        id
      }
    }
    agentsInfo {
      id
      name
      assignStatus
      agentProfile {
        data {
          ...UserProfile
        }
      }
    }
    status
    approvedDate
    approvalMode
    spIsAffected
    applicationLetter
    payments {
      data {
        id
        attributes {
          type
          status
        }
      }
    }
    quotation {
      data {
        id
        attributes {
          status
        }
      }
    }
    invoice {
      data {
        id
        attributes {
          status
        }
      }
    }
    notes {
      data {
        id
        attributes {
          name
          description
        }
      }
    }
    approvals(pagination: {limit: -1}) {
      data {
        ...Approval
      }
    }
    renewals(pagination: {limit: -1}) {
      data {
        ...Approval
      }
    }
    category
    isPaymentSkipped
    paymentSkipReason
  }
}
    `;
export const ApplicationTableFragmentDoc = gql`
    fragment ApplicationTable on ApplicationEntity {
  id
  attributes {
    title
    uuid
    createdAt
    submittedDate
    approvedDate
    applicationType {
      data {
        id
        attributes {
          title
          slug
        }
      }
    }
    category
    progress
    status
    isArchived
    agentsInfo {
      id
      assignStatus
      agentProfile {
        data {
          id
          attributes {
            fullName
            primaryEmail
          }
        }
      }
    }
    approvals(pagination: {limit: -1}) {
      data {
        id
        attributes {
          status
          name
          agents {
            name
            assignStatus
            agentProfile {
              data {
                id
                attributes {
                  fullName
                  primaryEmail
                }
              }
            }
          }
          company {
            data {
              id
              attributes {
                name
                companyEmail
                organization {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    project {
      data {
        id
        attributes {
          title
          start
          end
          organization {
            data {
              id
              attributes {
                name
                slug
              }
            }
          }
          applicant {
            data {
              id
              attributes {
                fullName
                primaryEmail
                company {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          applicantCompany {
            data {
              id
              attributes {
                name
                organization {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const AreaWithCompanyFragmentDoc = gql`
    fragment AreaWithCompany on AreaEntity {
  id
  attributes {
    name
    fillColor
    strokeColor
    kml {
      data {
        ...File
      }
    }
    geoJson {
      data {
        ...File
      }
    }
    company {
      data {
        id
        attributes {
          name
          documentTypes {
            data {
              ...DocumentType
            }
          }
          applicationType {
            data {
              ...ApplicationType
            }
          }
          companyType
          areas {
            data {
              id
            }
          }
          userProfiles {
            data {
              id
              attributes {
                profileType
                isDefaultAgent
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const ClientFragmentDoc = gql`
    fragment Client on UsersPermissionsUser {
  id
  email
  blocked
  confirmed
  role {
    data {
      id
      attributes {
        name
      }
    }
  }
  userProfile {
    data {
      attributes {
        fullName
      }
    }
  }
}
    `;
export const CompanyInfoFragmentDoc = gql`
    fragment CompanyInfo on CompanyEntity {
  id
  attributes {
    name
    companyEmail
  }
}
    `;
export const CompletionCertificateFragmentDoc = gql`
    fragment CompletionCertificate on CompletionCertificateEntity {
  id
  attributes {
    date
    description
    location
    status
    pdfDocumentLink
    discussionTopics {
      data {
        id
      }
    }
    owner {
      data {
        ...UserProfile
      }
    }
    participants {
      data {
        ...UserProfile
      }
    }
    application {
      data {
        id
      }
    }
    documents {
      data {
        ...File
      }
    }
    photos {
      data {
        ...File
      }
    }
    mapSnapshots {
      data {
        ...File
      }
    }
  }
}
    `;
export const ContactRequestFragmentDoc = gql`
    fragment ContactRequest on ContactRequest {
  firstName
  lastName
  email
  message
  phoneNumber
}
    `;
export const ContactUsFragmentDoc = gql`
    fragment ContactUs on ComponentPageContactUs {
  id
  pathname
}
    `;
export const DiscussionTopicFragmentDoc = gql`
    fragment DiscussionTopic on DiscussionTopicEntity {
  id
  attributes {
    title
    category
  }
}
    `;
export const InviteRequiredDocumentTypeFragmentDoc = gql`
    fragment InviteRequiredDocumentType on InviteRequiredDocumentTypeEntity {
  id
  attributes {
    slug
    name
    description
  }
}
    `;
export const InvoiceFragmentDoc = gql`
    fragment Invoice on InvoiceEntity {
  id
  attributes {
    name
    status
    totalAmount
    createdAt
    updatedAt
    dueDate
    pdfLink
    paymentLink
    successPaymentConfirmed
    paymentDocuments {
      data {
        ...File
      }
    }
    quotation {
      data {
        id
      }
    }
    billTo {
      data {
        id
      }
    }
    application {
      data {
        id
      }
    }
  }
}
    `;
export const KickoffInviteFragmentDoc = gql`
    fragment KickoffInvite on KickoffInviteEntity {
  id
  attributes {
    date
    time
    location
    status
    description
    docDescription
    inviteRequiredDocumentTypes {
      data {
        id
        attributes {
          name
          description
        }
      }
    }
    application {
      data {
        id
      }
    }
    owner {
      data {
        ...UserProfile
      }
    }
    participants {
      data {
        ...UserProfile
      }
    }
    mapSnapshot {
      data {
        ...File
      }
    }
  }
}
    `;
export const KickoffRecordFragmentDoc = gql`
    fragment KickoffRecord on KickoffRecordEntity {
  id
  attributes {
    date
    time
    description
    location
    status
    discussionTopics {
      data {
        id
      }
    }
    kickoffInvite {
      data {
        id
      }
    }
    owner {
      data {
        ...UserProfile
      }
    }
    participants {
      data {
        ...UserProfile
      }
    }
    application {
      data {
        id
      }
    }
    approvalDocuments {
      data {
        ...File
      }
    }
    photos {
      data {
        ...File
      }
    }
    mapSnapshot {
      data {
        ...File
      }
    }
  }
}
    `;
export const LetterTemplateFragmentDoc = gql`
    fragment LetterTemplate on LetterTemplateEntity {
  id
  attributes {
    name
    description
    signatoryName
    signatoryPosition
    letterTitle
    letterBg {
      data {
        ...File
      }
    }
    preview {
      data {
        ...File
      }
    }
    organization {
      data {
        id
      }
    }
    affectedText
    notAffectedText
    rejectedText
    headerSpace
    footerSpace
    createdAt
  }
}
    `;
export const NoteFragmentDoc = gql`
    fragment Note on NoteEntity {
  id
  attributes {
    name
    description
    attachment {
      data {
        ...File
      }
    }
    owner {
      data {
        id
      }
    }
    createdAt
  }
}
    `;
export const NotificationMessageFragmentDoc = gql`
    fragment NotificationMessage on NotificationMessageEntity {
  id
  attributes {
    createdAt
    message
    title
    isHidden
    images {
      data {
        ...File
      }
    }
    files {
      data {
        ...File
      }
    }
    to {
      data {
        id
      }
    }
    from {
      data {
        id
      }
    }
    application {
      data {
        id
        attributes {
          title
          uuid
        }
      }
    }
    type
    requestType
  }
}
    `;
export const OrganizationFragmentDoc = gql`
    fragment Organization on OrganizationEntity {
  id
  attributes {
    uuid
    name
    slug
    letterTemplates {
      data {
        id
        attributes {
          name
          signatoryPosition
          signatoryName
          letterTitle
          description
          affectedText
          notAffectedText
          rejectedText
          letterBg {
            data {
              ...File
            }
          }
          preview {
            data {
              ...File
            }
          }
        }
      }
    }
    singleLogo {
      data {
        ...File
      }
    }
    logoWithCompanyName {
      data {
        ...File
      }
    }
    logoWithCompanyNameDark {
      data {
        ...File
      }
    }
    companies {
      data {
        id
        attributes {
          name
          userProfiles {
            data {
              id
              attributes {
                fullName
                primaryEmail
                avatar {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    isActive
    primaryColor
    fontColor
    activationDate
    licenceExpiryDate
    heroSection {
      id
      title
      subtitle
      description
      media {
        data {
          ...File
        }
      }
    }
  }
}
    `;
export const PaymentFragmentDoc = gql`
    fragment Payment on PaymentEntity {
  id
  attributes {
    title
    status
    createdAt
    type
    link
    stripeProductPriceId
    amount
    totalAmount
    vat
    application {
      data {
        id
        attributes {
          uuid
        }
      }
    }
    documents {
      data {
        ...File
      }
    }
    itemDetails
    invoiceDate
    invoiceDueDate
    items {
      id
      name
      quantity
    }
    quotationPdfLink
    invoicePdfLink
  }
}
    `;
export const ProvinceFragmentDoc = gql`
    fragment Province on ProvinceEntity {
  id
  attributes {
    uuid
    name
    fillColor
    strokeColor
    kml {
      data {
        ...File
      }
    }
    geoJson {
      data {
        ...File
      }
    }
  }
}
    `;
export const ProjectFragmentDoc = gql`
    fragment Project on ProjectEntity {
  id
  attributes {
    uuid
    title
    start
    end
    status
    createdAt
    description
    category
    isArchived
    provinces {
      data {
        ...Province
      }
    }
    municipalCompanies {
      data {
        ...Company
      }
    }
    projectNature {
      data {
        ...ApplicationNature
      }
    }
    organization {
      data {
        id
        attributes {
          slug
        }
      }
    }
    applicantCompany {
      data {
        id
        attributes {
          name
          companyEmail
        }
      }
    }
    applicant {
      data {
        id
        attributes {
          fullName
          primaryEmail
        }
      }
    }
    owner {
      data {
        id
        attributes {
          primaryEmail
          company {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
    applications(pagination: {limit: -1}) {
      data {
        id
        attributes {
          uuid
          createdAt
          title
          status
          duplicationIndex
          submittedDate
          progress
          approvalMode
          approvedDate
          category
          requiredDocuments {
            id
            docTypeName
            description
          }
          agentsInfo {
            id
            name
            assignStatus
            agentProfile {
              data {
                id
              }
            }
          }
          applicationType {
            data {
              id
              attributes {
                title
                slug
              }
            }
          }
          approvals(pagination: {limit: -1}) {
            data {
              id
              attributes {
                name
                company {
                  data {
                    id
                    attributes {
                      organization {
                        data {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    projectCenter
    locationInfo
    mapSnapshot {
      data {
        ...File
      }
    }
    geoJson {
      data {
        ...File
      }
    }
    kml {
      data {
        ...File
      }
    }
  }
}
    `;
export const ProjectTableFragmentDoc = gql`
    fragment ProjectTable on ProjectEntity {
  id
  attributes {
    uuid
    title
    start
    end
    status
    createdAt
    description
    category
    isArchived
    applicantCompany {
      data {
        id
        attributes {
          name
        }
      }
    }
    organization {
      data {
        id
        attributes {
          name
          slug
        }
      }
    }
    owner {
      data {
        id
        attributes {
          primaryEmail
          company {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
    applications(pagination: {limit: -1}) {
      data {
        id
        attributes {
          title
          duplicationIndex
          agentsInfo {
            id
            name
            assignStatus
            agentProfile {
              data {
                id
                attributes {
                  fullName
                  primaryEmail
                }
              }
            }
          }
          approvals {
            data {
              id
              attributes {
                name
                company {
                  data {
                    id
                    attributes {
                      organization {
                        data {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const QuoteItemFragmentDoc = gql`
    fragment QuoteItem on QuoteItemEntity {
  id
  attributes {
    name
    description
    price
  }
}
    `;
export const QuotationFragmentDoc = gql`
    fragment Quotation on QuotationEntity {
  id
  attributes {
    name
    status
    totalAmount
    vat
    invoice {
      data {
        id
      }
    }
    quoteItems {
      id
      name
      quantity
      quoteItem {
        data {
          ...QuoteItem
        }
      }
    }
    createdAt
    updatedAt
    dueDate
    quotationPdfLink
  }
}
    `;
export const RoleFragmentDoc = gql`
    fragment Role on UsersPermissionsRoleEntity {
  id
  attributes {
    name
    description
  }
}
    `;
export const SettingsFragmentDoc = gql`
    fragment Settings on SettingEntity {
  id
  attributes {
    chunkSize
    afrigisBuffer
    description
    easetechSearchBuffer
    maxProjectArea
    maxProjectLength
    maxProjectPoints
    quotationVat
    geometryOutdated
    geometrySettings {
      id
      applicationType {
        data {
          id
          attributes {
            title
          }
        }
      }
      name
      slug
      color
    }
  }
}
    `;
export const TaskFragmentDoc = gql`
    fragment Task on TaskEntity {
  id
  attributes {
    uuid
    title
    description
    createdAt
    start
    end
    owner {
      data {
        id
        attributes {
          uuid
        }
      }
    }
    applicant {
      data {
        id
        attributes {
          fullName
          primaryEmail
        }
      }
    }
    project {
      data {
        id
        attributes {
          title
        }
      }
    }
    application {
      data {
        id
        attributes {
          title
        }
      }
    }
    status
  }
}
    `;
export const DocumentTemplateFragmentDoc = gql`
    fragment DocumentTemplate on DocumentTemplateEntity {
  id
  attributes {
    name
    description
    status
    size
    updatedAt
    slug
  }
}
    `;
export const TemplateSubjectFragmentDoc = gql`
    fragment TemplateSubject on TemplateSubjectEntity {
  id
  attributes {
    name
    description
    updatedAt
    createdAt
    group
    document_templates {
      data {
        ...DocumentTemplate
      }
    }
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UsersPermissionsUser {
  id
  email
  blocked
  confirmed
  role {
    data {
      id
      attributes {
        name
      }
    }
  }
  organization {
    data {
      id
      attributes {
        slug
      }
    }
  }
}
    `;
export const SeoFragmentDoc = gql`
    fragment Seo on ComponentSharedSeo {
  id
  canonicalURL
  keywords
  metaTitle
  metaViewport
  metaDescription
}
    `;
export const HomeFragmentDoc = gql`
    fragment Home on ComponentPageHome {
  id
  pathname
  hero {
    subtitle
    id
    description
    title
    media {
      data {
        ...File
      }
    }
  }
  clientReviews {
    id
    title
    content
    clientName
    feedbackDate
    rate
  }
  partnerLogos {
    id
    image {
      data {
        ...File
      }
    }
  }
  benefitsHeadline {
    title
    subtitle
  }
  benefits {
    id
    title
    subtitle
  }
}
    `;
export const PrivacyFragmentDoc = gql`
    fragment Privacy on ComponentPagePrivacy {
  id
  pathname
  title
  content
}
    `;
export const TermsFragmentDoc = gql`
    fragment Terms on ComponentPageTerms {
  id
  pathname
  title
  content
}
    `;
export const FreeQuoteFragmentDoc = gql`
    fragment FreeQuote on ComponentPageFreeQuote {
  id
  pathname
  hero {
    subtitle
    id
    description
    title
    media {
      data {
        ...File
      }
    }
  }
  heroBenefits {
    id
    title
    subtitle
  }
}
    `;
export const WebsiteFragmentDoc = gql`
    fragment Website on WebsiteEntity {
  id
  attributes {
    seo {
      ...Seo
    }
    content {
      __typename
      ...Home
      ...Privacy
      ...Terms
      ...FreeQuote
    }
  }
}
    `;
export const PaginationFragmentDoc = gql`
    fragment Pagination on Pagination {
  page
  pageCount
  pageSize
  total
}
    `;
export const MetaFragmentDoc = gql`
    fragment Meta on ResponseCollectionMeta {
  pagination {
    ...Pagination
  }
}
    `;
export const AppApprovalProgressUpdateDocument = gql`
    mutation appApprovalProgressUpdate($input: AppApprovalProgressUpdateInput!) {
  appApprovalProgressUpdate(input: $input)
}
    `;
export type AppApprovalProgressUpdateMutationFn = Apollo.MutationFunction<AppApprovalProgressUpdateMutation, AppApprovalProgressUpdateMutationVariables>;
export type AppApprovalProgressUpdateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AppApprovalProgressUpdateMutation, AppApprovalProgressUpdateMutationVariables>, 'mutation'>;

    export const AppApprovalProgressUpdateComponent = (props: AppApprovalProgressUpdateComponentProps) => (
      <ApolloReactComponents.Mutation<AppApprovalProgressUpdateMutation, AppApprovalProgressUpdateMutationVariables> mutation={AppApprovalProgressUpdateDocument} {...props} />
    );
    
export function useAppApprovalProgressUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AppApprovalProgressUpdateMutation, AppApprovalProgressUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppApprovalProgressUpdateMutation, AppApprovalProgressUpdateMutationVariables>(AppApprovalProgressUpdateDocument, options);
      }
export type AppApprovalProgressUpdateMutationHookResult = ReturnType<typeof useAppApprovalProgressUpdateMutation>;
export type AppApprovalProgressUpdateMutationResult = Apollo.MutationResult<AppApprovalProgressUpdateMutation>;
export const ApplicationLetterDocument = gql`
    mutation applicationLetter($input: ApplicationLetterInput!) {
  applicationLetter(input: $input)
}
    `;
export type ApplicationLetterMutationFn = Apollo.MutationFunction<ApplicationLetterMutation, ApplicationLetterMutationVariables>;
export type ApplicationLetterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApplicationLetterMutation, ApplicationLetterMutationVariables>, 'mutation'>;

    export const ApplicationLetterComponent = (props: ApplicationLetterComponentProps) => (
      <ApolloReactComponents.Mutation<ApplicationLetterMutation, ApplicationLetterMutationVariables> mutation={ApplicationLetterDocument} {...props} />
    );
    
export function useApplicationLetterMutation(baseOptions?: Apollo.MutationHookOptions<ApplicationLetterMutation, ApplicationLetterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplicationLetterMutation, ApplicationLetterMutationVariables>(ApplicationLetterDocument, options);
      }
export type ApplicationLetterMutationHookResult = ReturnType<typeof useApplicationLetterMutation>;
export type ApplicationLetterMutationResult = Apollo.MutationResult<ApplicationLetterMutation>;
export const ApprovalLetterDocument = gql`
    mutation approvalLetter($input: ApprovalTemplateLetterInput!) {
  approvalTemplateLetter(input: $input) {
    id
    url
    name
  }
}
    `;
export type ApprovalLetterMutationFn = Apollo.MutationFunction<ApprovalLetterMutation, ApprovalLetterMutationVariables>;
export type ApprovalLetterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApprovalLetterMutation, ApprovalLetterMutationVariables>, 'mutation'>;

    export const ApprovalLetterComponent = (props: ApprovalLetterComponentProps) => (
      <ApolloReactComponents.Mutation<ApprovalLetterMutation, ApprovalLetterMutationVariables> mutation={ApprovalLetterDocument} {...props} />
    );
    
export function useApprovalLetterMutation(baseOptions?: Apollo.MutationHookOptions<ApprovalLetterMutation, ApprovalLetterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApprovalLetterMutation, ApprovalLetterMutationVariables>(ApprovalLetterDocument, options);
      }
export type ApprovalLetterMutationHookResult = ReturnType<typeof useApprovalLetterMutation>;
export type ApprovalLetterMutationResult = Apollo.MutationResult<ApprovalLetterMutation>;
export const ApprovalMapLetterDocument = gql`
    mutation approvalMapLetter($input: ApprovalTemplateMapDataInput!) {
  approvalTemplateMapData(input: $input) {
    id
    url
    name
  }
}
    `;
export type ApprovalMapLetterMutationFn = Apollo.MutationFunction<ApprovalMapLetterMutation, ApprovalMapLetterMutationVariables>;
export type ApprovalMapLetterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ApprovalMapLetterMutation, ApprovalMapLetterMutationVariables>, 'mutation'>;

    export const ApprovalMapLetterComponent = (props: ApprovalMapLetterComponentProps) => (
      <ApolloReactComponents.Mutation<ApprovalMapLetterMutation, ApprovalMapLetterMutationVariables> mutation={ApprovalMapLetterDocument} {...props} />
    );
    
export function useApprovalMapLetterMutation(baseOptions?: Apollo.MutationHookOptions<ApprovalMapLetterMutation, ApprovalMapLetterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApprovalMapLetterMutation, ApprovalMapLetterMutationVariables>(ApprovalMapLetterDocument, options);
      }
export type ApprovalMapLetterMutationHookResult = ReturnType<typeof useApprovalMapLetterMutation>;
export type ApprovalMapLetterMutationResult = Apollo.MutationResult<ApprovalMapLetterMutation>;
export const ArchiveProjectDocument = gql`
    mutation archiveProject($input: ArchiveProjectInput!) {
  archiveProject(input: $input)
}
    `;
export type ArchiveProjectMutationFn = Apollo.MutationFunction<ArchiveProjectMutation, ArchiveProjectMutationVariables>;
export type ArchiveProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ArchiveProjectMutation, ArchiveProjectMutationVariables>, 'mutation'>;

    export const ArchiveProjectComponent = (props: ArchiveProjectComponentProps) => (
      <ApolloReactComponents.Mutation<ArchiveProjectMutation, ArchiveProjectMutationVariables> mutation={ArchiveProjectDocument} {...props} />
    );
    
export function useArchiveProjectMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveProjectMutation, ArchiveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveProjectMutation, ArchiveProjectMutationVariables>(ArchiveProjectDocument, options);
      }
export type ArchiveProjectMutationHookResult = ReturnType<typeof useArchiveProjectMutation>;
export type ArchiveProjectMutationResult = Apollo.MutationResult<ArchiveProjectMutation>;
export const ConfirmDeliveryApprovalItemDocument = gql`
    mutation confirmDeliveryApprovalItem($input: ConfirmDeliveryApprovalItemInput!) {
  confirmDeliveryApprovalItem(input: $input)
}
    `;
export type ConfirmDeliveryApprovalItemMutationFn = Apollo.MutationFunction<ConfirmDeliveryApprovalItemMutation, ConfirmDeliveryApprovalItemMutationVariables>;
export type ConfirmDeliveryApprovalItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ConfirmDeliveryApprovalItemMutation, ConfirmDeliveryApprovalItemMutationVariables>, 'mutation'>;

    export const ConfirmDeliveryApprovalItemComponent = (props: ConfirmDeliveryApprovalItemComponentProps) => (
      <ApolloReactComponents.Mutation<ConfirmDeliveryApprovalItemMutation, ConfirmDeliveryApprovalItemMutationVariables> mutation={ConfirmDeliveryApprovalItemDocument} {...props} />
    );
    
export function useConfirmDeliveryApprovalItemMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmDeliveryApprovalItemMutation, ConfirmDeliveryApprovalItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmDeliveryApprovalItemMutation, ConfirmDeliveryApprovalItemMutationVariables>(ConfirmDeliveryApprovalItemDocument, options);
      }
export type ConfirmDeliveryApprovalItemMutationHookResult = ReturnType<typeof useConfirmDeliveryApprovalItemMutation>;
export type ConfirmDeliveryApprovalItemMutationResult = Apollo.MutationResult<ConfirmDeliveryApprovalItemMutation>;
export const ConfirmEmailDocument = gql`
    mutation confirmEmail($confirmation: String!) {
  emailConfirmation(confirmation: $confirmation) {
    jwt
    user {
      id
    }
  }
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export type ConfirmEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>, 'mutation'>;

    export const ConfirmEmailComponent = (props: ConfirmEmailComponentProps) => (
      <ApolloReactComponents.Mutation<ConfirmEmailMutation, ConfirmEmailMutationVariables> mutation={ConfirmEmailDocument} {...props} />
    );
    
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export const CreateApplicationDocument = gql`
    mutation createApplication($input: ApplicationInput!) {
  createApplication(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateApplicationMutationFn = Apollo.MutationFunction<CreateApplicationMutation, CreateApplicationMutationVariables>;
export type CreateApplicationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateApplicationMutation, CreateApplicationMutationVariables>, 'mutation'>;

    export const CreateApplicationComponent = (props: CreateApplicationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateApplicationMutation, CreateApplicationMutationVariables> mutation={CreateApplicationDocument} {...props} />
    );
    
export function useCreateApplicationMutation(baseOptions?: Apollo.MutationHookOptions<CreateApplicationMutation, CreateApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApplicationMutation, CreateApplicationMutationVariables>(CreateApplicationDocument, options);
      }
export type CreateApplicationMutationHookResult = ReturnType<typeof useCreateApplicationMutation>;
export type CreateApplicationMutationResult = Apollo.MutationResult<CreateApplicationMutation>;
export const CreateApprovalDocument = gql`
    mutation createApproval($input: ApprovalInput!) {
  createApproval(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateApprovalMutationFn = Apollo.MutationFunction<CreateApprovalMutation, CreateApprovalMutationVariables>;
export type CreateApprovalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateApprovalMutation, CreateApprovalMutationVariables>, 'mutation'>;

    export const CreateApprovalComponent = (props: CreateApprovalComponentProps) => (
      <ApolloReactComponents.Mutation<CreateApprovalMutation, CreateApprovalMutationVariables> mutation={CreateApprovalDocument} {...props} />
    );
    
export function useCreateApprovalMutation(baseOptions?: Apollo.MutationHookOptions<CreateApprovalMutation, CreateApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApprovalMutation, CreateApprovalMutationVariables>(CreateApprovalDocument, options);
      }
export type CreateApprovalMutationHookResult = ReturnType<typeof useCreateApprovalMutation>;
export type CreateApprovalMutationResult = Apollo.MutationResult<CreateApprovalMutation>;
export const CreateApprovalItemsDocument = gql`
    mutation createApprovalItems($input: CreateApprovalItemInput!) {
  createApprovalItem(input: $input) {
    approvals
  }
}
    `;
export type CreateApprovalItemsMutationFn = Apollo.MutationFunction<CreateApprovalItemsMutation, CreateApprovalItemsMutationVariables>;
export type CreateApprovalItemsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateApprovalItemsMutation, CreateApprovalItemsMutationVariables>, 'mutation'>;

    export const CreateApprovalItemsComponent = (props: CreateApprovalItemsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateApprovalItemsMutation, CreateApprovalItemsMutationVariables> mutation={CreateApprovalItemsDocument} {...props} />
    );
    
export function useCreateApprovalItemsMutation(baseOptions?: Apollo.MutationHookOptions<CreateApprovalItemsMutation, CreateApprovalItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApprovalItemsMutation, CreateApprovalItemsMutationVariables>(CreateApprovalItemsDocument, options);
      }
export type CreateApprovalItemsMutationHookResult = ReturnType<typeof useCreateApprovalItemsMutation>;
export type CreateApprovalItemsMutationResult = Apollo.MutationResult<CreateApprovalItemsMutation>;
export const CreateAreaDocument = gql`
    mutation createArea($input: AreaInput!) {
  createArea(data: $input) {
    data {
      ...Area
    }
  }
}
    ${AreaFragmentDoc}
${FileFragmentDoc}`;
export type CreateAreaMutationFn = Apollo.MutationFunction<CreateAreaMutation, CreateAreaMutationVariables>;
export type CreateAreaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAreaMutation, CreateAreaMutationVariables>, 'mutation'>;

    export const CreateAreaComponent = (props: CreateAreaComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAreaMutation, CreateAreaMutationVariables> mutation={CreateAreaDocument} {...props} />
    );
    
export function useCreateAreaMutation(baseOptions?: Apollo.MutationHookOptions<CreateAreaMutation, CreateAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAreaMutation, CreateAreaMutationVariables>(CreateAreaDocument, options);
      }
export type CreateAreaMutationHookResult = ReturnType<typeof useCreateAreaMutation>;
export type CreateAreaMutationResult = Apollo.MutationResult<CreateAreaMutation>;
export const CreateCertificateDocument = gql`
    mutation createCertificate($input: CompletionCertificateInput!) {
  createCompletionCertificate(data: $input) {
    data {
      ...CompletionCertificate
    }
  }
}
    ${CompletionCertificateFragmentDoc}
${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type CreateCertificateMutationFn = Apollo.MutationFunction<CreateCertificateMutation, CreateCertificateMutationVariables>;
export type CreateCertificateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCertificateMutation, CreateCertificateMutationVariables>, 'mutation'>;

    export const CreateCertificateComponent = (props: CreateCertificateComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCertificateMutation, CreateCertificateMutationVariables> mutation={CreateCertificateDocument} {...props} />
    );
    
export function useCreateCertificateMutation(baseOptions?: Apollo.MutationHookOptions<CreateCertificateMutation, CreateCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCertificateMutation, CreateCertificateMutationVariables>(CreateCertificateDocument, options);
      }
export type CreateCertificateMutationHookResult = ReturnType<typeof useCreateCertificateMutation>;
export type CreateCertificateMutationResult = Apollo.MutationResult<CreateCertificateMutation>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CompanyInput!) {
  createCompany(data: $input) {
    data {
      ...Company
    }
  }
}
    ${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${FileFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;
export type CreateCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCompanyMutation, CreateCompanyMutationVariables>, 'mutation'>;

    export const CreateCompanyComponent = (props: CreateCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCompanyMutation, CreateCompanyMutationVariables> mutation={CreateCompanyDocument} {...props} />
    );
    
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export const CreateContactRequestDocument = gql`
    mutation createContactRequest($input: ContactRequestInput!) {
  createContactRequest(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateContactRequestMutationFn = Apollo.MutationFunction<CreateContactRequestMutation, CreateContactRequestMutationVariables>;
export type CreateContactRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateContactRequestMutation, CreateContactRequestMutationVariables>, 'mutation'>;

    export const CreateContactRequestComponent = (props: CreateContactRequestComponentProps) => (
      <ApolloReactComponents.Mutation<CreateContactRequestMutation, CreateContactRequestMutationVariables> mutation={CreateContactRequestDocument} {...props} />
    );
    
export function useCreateContactRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactRequestMutation, CreateContactRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactRequestMutation, CreateContactRequestMutationVariables>(CreateContactRequestDocument, options);
      }
export type CreateContactRequestMutationHookResult = ReturnType<typeof useCreateContactRequestMutation>;
export type CreateContactRequestMutationResult = Apollo.MutationResult<CreateContactRequestMutation>;
export const CreateKickoffInviteDocument = gql`
    mutation createKickoffInvite($input: KickoffInviteInput!) {
  createKickoffInvite(data: $input) {
    data {
      ...KickoffInvite
    }
  }
}
    ${KickoffInviteFragmentDoc}
${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type CreateKickoffInviteMutationFn = Apollo.MutationFunction<CreateKickoffInviteMutation, CreateKickoffInviteMutationVariables>;
export type CreateKickoffInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateKickoffInviteMutation, CreateKickoffInviteMutationVariables>, 'mutation'>;

    export const CreateKickoffInviteComponent = (props: CreateKickoffInviteComponentProps) => (
      <ApolloReactComponents.Mutation<CreateKickoffInviteMutation, CreateKickoffInviteMutationVariables> mutation={CreateKickoffInviteDocument} {...props} />
    );
    
export function useCreateKickoffInviteMutation(baseOptions?: Apollo.MutationHookOptions<CreateKickoffInviteMutation, CreateKickoffInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKickoffInviteMutation, CreateKickoffInviteMutationVariables>(CreateKickoffInviteDocument, options);
      }
export type CreateKickoffInviteMutationHookResult = ReturnType<typeof useCreateKickoffInviteMutation>;
export type CreateKickoffInviteMutationResult = Apollo.MutationResult<CreateKickoffInviteMutation>;
export const CreateInvoiceDocument = gql`
    mutation createInvoice($input: InvoiceInput!) {
  createInvoice(data: $input) {
    data {
      ...Invoice
    }
  }
}
    ${InvoiceFragmentDoc}
${FileFragmentDoc}`;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export type CreateInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>, 'mutation'>;

    export const CreateInvoiceComponent = (props: CreateInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInvoiceMutation, CreateInvoiceMutationVariables> mutation={CreateInvoiceDocument} {...props} />
    );
    
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export const CreateNoteDocument = gql`
    mutation createNote($input: NoteInput!) {
  createNote(data: $input) {
    data {
      ...Note
    }
  }
}
    ${NoteFragmentDoc}
${FileFragmentDoc}`;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;
export type CreateNoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNoteMutation, CreateNoteMutationVariables>, 'mutation'>;

    export const CreateNoteComponent = (props: CreateNoteComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNoteMutation, CreateNoteMutationVariables> mutation={CreateNoteDocument} {...props} />
    );
    
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export const CreateNotificationMessageDocument = gql`
    mutation createNotificationMessage($input: NotificationMessageInput!) {
  createNotificationMessage(data: $input) {
    data {
      ...NotificationMessage
    }
  }
}
    ${NotificationMessageFragmentDoc}
${FileFragmentDoc}`;
export type CreateNotificationMessageMutationFn = Apollo.MutationFunction<CreateNotificationMessageMutation, CreateNotificationMessageMutationVariables>;
export type CreateNotificationMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNotificationMessageMutation, CreateNotificationMessageMutationVariables>, 'mutation'>;

    export const CreateNotificationMessageComponent = (props: CreateNotificationMessageComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNotificationMessageMutation, CreateNotificationMessageMutationVariables> mutation={CreateNotificationMessageDocument} {...props} />
    );
    
export function useCreateNotificationMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationMessageMutation, CreateNotificationMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationMessageMutation, CreateNotificationMessageMutationVariables>(CreateNotificationMessageDocument, options);
      }
export type CreateNotificationMessageMutationHookResult = ReturnType<typeof useCreateNotificationMessageMutation>;
export type CreateNotificationMessageMutationResult = Apollo.MutationResult<CreateNotificationMessageMutation>;
export const CreatePaymentDocument = gql`
    mutation createPayment($input: PaymentInput!) {
  createPayment(data: $input) {
    data {
      ...Payment
    }
  }
}
    ${PaymentFragmentDoc}
${FileFragmentDoc}`;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;
export type CreatePaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreatePaymentMutation, CreatePaymentMutationVariables>, 'mutation'>;

    export const CreatePaymentComponent = (props: CreatePaymentComponentProps) => (
      <ApolloReactComponents.Mutation<CreatePaymentMutation, CreatePaymentMutationVariables> mutation={CreatePaymentDocument} {...props} />
    );
    
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export const CreateProjectDocument = gql`
    mutation createProject($input: ProjectInput!) {
  createProject(data: $input) {
    data {
      ...Project
    }
  }
}
    ${ProjectFragmentDoc}
${ProvinceFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${ApplicationNatureFragmentDoc}`;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;
export type CreateProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateProjectMutation, CreateProjectMutationVariables>, 'mutation'>;

    export const CreateProjectComponent = (props: CreateProjectComponentProps) => (
      <ApolloReactComponents.Mutation<CreateProjectMutation, CreateProjectMutationVariables> mutation={CreateProjectDocument} {...props} />
    );
    
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export const CreateProjectEmailNotificationInputDocument = gql`
    mutation createProjectEmailNotificationInput($input: CreateProjectEmailNotificationInput!) {
  createProjectEmailNotification(input: $input)
}
    `;
export type CreateProjectEmailNotificationInputMutationFn = Apollo.MutationFunction<CreateProjectEmailNotificationInputMutation, CreateProjectEmailNotificationInputMutationVariables>;
export type CreateProjectEmailNotificationInputComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateProjectEmailNotificationInputMutation, CreateProjectEmailNotificationInputMutationVariables>, 'mutation'>;

    export const CreateProjectEmailNotificationInputComponent = (props: CreateProjectEmailNotificationInputComponentProps) => (
      <ApolloReactComponents.Mutation<CreateProjectEmailNotificationInputMutation, CreateProjectEmailNotificationInputMutationVariables> mutation={CreateProjectEmailNotificationInputDocument} {...props} />
    );
    
export function useCreateProjectEmailNotificationInputMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectEmailNotificationInputMutation, CreateProjectEmailNotificationInputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectEmailNotificationInputMutation, CreateProjectEmailNotificationInputMutationVariables>(CreateProjectEmailNotificationInputDocument, options);
      }
export type CreateProjectEmailNotificationInputMutationHookResult = ReturnType<typeof useCreateProjectEmailNotificationInputMutation>;
export type CreateProjectEmailNotificationInputMutationResult = Apollo.MutationResult<CreateProjectEmailNotificationInputMutation>;
export const CreateProvinceDocument = gql`
    mutation createProvince($input: ProvinceInput!) {
  createProvince(data: $input) {
    data {
      ...Province
    }
  }
}
    ${ProvinceFragmentDoc}
${FileFragmentDoc}`;
export type CreateProvinceMutationFn = Apollo.MutationFunction<CreateProvinceMutation, CreateProvinceMutationVariables>;
export type CreateProvinceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateProvinceMutation, CreateProvinceMutationVariables>, 'mutation'>;

    export const CreateProvinceComponent = (props: CreateProvinceComponentProps) => (
      <ApolloReactComponents.Mutation<CreateProvinceMutation, CreateProvinceMutationVariables> mutation={CreateProvinceDocument} {...props} />
    );
    
export function useCreateProvinceMutation(baseOptions?: Apollo.MutationHookOptions<CreateProvinceMutation, CreateProvinceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProvinceMutation, CreateProvinceMutationVariables>(CreateProvinceDocument, options);
      }
export type CreateProvinceMutationHookResult = ReturnType<typeof useCreateProvinceMutation>;
export type CreateProvinceMutationResult = Apollo.MutationResult<CreateProvinceMutation>;
export const CreateQuoteItemDocument = gql`
    mutation createQuoteItem($input: QuoteItemInput!) {
  createQuoteItem(data: $input) {
    data {
      ...QuoteItem
    }
  }
}
    ${QuoteItemFragmentDoc}`;
export type CreateQuoteItemMutationFn = Apollo.MutationFunction<CreateQuoteItemMutation, CreateQuoteItemMutationVariables>;
export type CreateQuoteItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateQuoteItemMutation, CreateQuoteItemMutationVariables>, 'mutation'>;

    export const CreateQuoteItemComponent = (props: CreateQuoteItemComponentProps) => (
      <ApolloReactComponents.Mutation<CreateQuoteItemMutation, CreateQuoteItemMutationVariables> mutation={CreateQuoteItemDocument} {...props} />
    );
    
export function useCreateQuoteItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteItemMutation, CreateQuoteItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteItemMutation, CreateQuoteItemMutationVariables>(CreateQuoteItemDocument, options);
      }
export type CreateQuoteItemMutationHookResult = ReturnType<typeof useCreateQuoteItemMutation>;
export type CreateQuoteItemMutationResult = Apollo.MutationResult<CreateQuoteItemMutation>;
export const CreateKickoffRecordDocument = gql`
    mutation createKickoffRecord($input: KickoffRecordInput!) {
  createKickoffRecord(data: $input) {
    data {
      ...KickoffRecord
    }
  }
}
    ${KickoffRecordFragmentDoc}
${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type CreateKickoffRecordMutationFn = Apollo.MutationFunction<CreateKickoffRecordMutation, CreateKickoffRecordMutationVariables>;
export type CreateKickoffRecordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateKickoffRecordMutation, CreateKickoffRecordMutationVariables>, 'mutation'>;

    export const CreateKickoffRecordComponent = (props: CreateKickoffRecordComponentProps) => (
      <ApolloReactComponents.Mutation<CreateKickoffRecordMutation, CreateKickoffRecordMutationVariables> mutation={CreateKickoffRecordDocument} {...props} />
    );
    
export function useCreateKickoffRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateKickoffRecordMutation, CreateKickoffRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKickoffRecordMutation, CreateKickoffRecordMutationVariables>(CreateKickoffRecordDocument, options);
      }
export type CreateKickoffRecordMutationHookResult = ReturnType<typeof useCreateKickoffRecordMutation>;
export type CreateKickoffRecordMutationResult = Apollo.MutationResult<CreateKickoffRecordMutation>;
export const CreateTaskDocument = gql`
    mutation createTask($input: TaskInput!) {
  createTask(data: $input) {
    data {
      ...Task
    }
  }
}
    ${TaskFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;
export type CreateTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTaskMutation, CreateTaskMutationVariables>, 'mutation'>;

    export const CreateTaskComponent = (props: CreateTaskComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTaskMutation, CreateTaskMutationVariables> mutation={CreateTaskDocument} {...props} />
    );
    
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export const CreateUserDocument = gql`
    mutation createUser($input: UsersPermissionsUserInput!) {
  createUsersPermissionsUser(data: $input) {
    data {
      id
      attributes {
        email
        username
      }
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;
export type CreateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserMutation, CreateUserMutationVariables>, 'mutation'>;

    export const CreateUserComponent = (props: CreateUserComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserMutation, CreateUserMutationVariables> mutation={CreateUserDocument} {...props} />
    );
    
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export const CreateUserProfileDocument = gql`
    mutation createUserProfile($input: UserProfileInput!) {
  createUserProfile(data: $input) {
    data {
      ...UserProfile
    }
  }
}
    ${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type CreateUserProfileMutationFn = Apollo.MutationFunction<CreateUserProfileMutation, CreateUserProfileMutationVariables>;
export type CreateUserProfileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserProfileMutation, CreateUserProfileMutationVariables>, 'mutation'>;

    export const CreateUserProfileComponent = (props: CreateUserProfileComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserProfileMutation, CreateUserProfileMutationVariables> mutation={CreateUserProfileDocument} {...props} />
    );
    
export function useCreateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserProfileMutation, CreateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserProfileMutation, CreateUserProfileMutationVariables>(CreateUserProfileDocument, options);
      }
export type CreateUserProfileMutationHookResult = ReturnType<typeof useCreateUserProfileMutation>;
export type CreateUserProfileMutationResult = Apollo.MutationResult<CreateUserProfileMutation>;
export const CustomForgotPasswordDocument = gql`
    mutation customForgotPassword($input: ForgotPasswordInput!) {
  customForgotPassword(input: $input)
}
    `;
export type CustomForgotPasswordMutationFn = Apollo.MutationFunction<CustomForgotPasswordMutation, CustomForgotPasswordMutationVariables>;
export type CustomForgotPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CustomForgotPasswordMutation, CustomForgotPasswordMutationVariables>, 'mutation'>;

    export const CustomForgotPasswordComponent = (props: CustomForgotPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<CustomForgotPasswordMutation, CustomForgotPasswordMutationVariables> mutation={CustomForgotPasswordDocument} {...props} />
    );
    
export function useCustomForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CustomForgotPasswordMutation, CustomForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomForgotPasswordMutation, CustomForgotPasswordMutationVariables>(CustomForgotPasswordDocument, options);
      }
export type CustomForgotPasswordMutationHookResult = ReturnType<typeof useCustomForgotPasswordMutation>;
export type CustomForgotPasswordMutationResult = Apollo.MutationResult<CustomForgotPasswordMutation>;
export const CustomLoginDocument = gql`
    mutation customLogin($input: LoginUserInput!) {
  customLogin(input: $input) {
    jwt
    user {
      id
    }
  }
}
    `;
export type CustomLoginMutationFn = Apollo.MutationFunction<CustomLoginMutation, CustomLoginMutationVariables>;
export type CustomLoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CustomLoginMutation, CustomLoginMutationVariables>, 'mutation'>;

    export const CustomLoginComponent = (props: CustomLoginComponentProps) => (
      <ApolloReactComponents.Mutation<CustomLoginMutation, CustomLoginMutationVariables> mutation={CustomLoginDocument} {...props} />
    );
    
export function useCustomLoginMutation(baseOptions?: Apollo.MutationHookOptions<CustomLoginMutation, CustomLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomLoginMutation, CustomLoginMutationVariables>(CustomLoginDocument, options);
      }
export type CustomLoginMutationHookResult = ReturnType<typeof useCustomLoginMutation>;
export type CustomLoginMutationResult = Apollo.MutationResult<CustomLoginMutation>;
export const CustomRegisterDocument = gql`
    mutation customRegister($input: RegisterUserInput!) {
  customRegister(input: $input) {
    jwt
    user {
      id
    }
    isExistingProfile
  }
}
    `;
export type CustomRegisterMutationFn = Apollo.MutationFunction<CustomRegisterMutation, CustomRegisterMutationVariables>;
export type CustomRegisterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CustomRegisterMutation, CustomRegisterMutationVariables>, 'mutation'>;

    export const CustomRegisterComponent = (props: CustomRegisterComponentProps) => (
      <ApolloReactComponents.Mutation<CustomRegisterMutation, CustomRegisterMutationVariables> mutation={CustomRegisterDocument} {...props} />
    );
    
export function useCustomRegisterMutation(baseOptions?: Apollo.MutationHookOptions<CustomRegisterMutation, CustomRegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomRegisterMutation, CustomRegisterMutationVariables>(CustomRegisterDocument, options);
      }
export type CustomRegisterMutationHookResult = ReturnType<typeof useCustomRegisterMutation>;
export type CustomRegisterMutationResult = Apollo.MutationResult<CustomRegisterMutation>;
export const CustomResetPasswordDocument = gql`
    mutation customResetPassword($input: ResetPasswordInput!) {
  customResetPassword(input: $input)
}
    `;
export type CustomResetPasswordMutationFn = Apollo.MutationFunction<CustomResetPasswordMutation, CustomResetPasswordMutationVariables>;
export type CustomResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CustomResetPasswordMutation, CustomResetPasswordMutationVariables>, 'mutation'>;

    export const CustomResetPasswordComponent = (props: CustomResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<CustomResetPasswordMutation, CustomResetPasswordMutationVariables> mutation={CustomResetPasswordDocument} {...props} />
    );
    
export function useCustomResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CustomResetPasswordMutation, CustomResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomResetPasswordMutation, CustomResetPasswordMutationVariables>(CustomResetPasswordDocument, options);
      }
export type CustomResetPasswordMutationHookResult = ReturnType<typeof useCustomResetPasswordMutation>;
export type CustomResetPasswordMutationResult = Apollo.MutationResult<CustomResetPasswordMutation>;
export const DeleteApplicationDocument = gql`
    mutation deleteApplication($id: ID!) {
  deleteApplication(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteApplicationMutationFn = Apollo.MutationFunction<DeleteApplicationMutation, DeleteApplicationMutationVariables>;
export type DeleteApplicationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteApplicationMutation, DeleteApplicationMutationVariables>, 'mutation'>;

    export const DeleteApplicationComponent = (props: DeleteApplicationComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteApplicationMutation, DeleteApplicationMutationVariables> mutation={DeleteApplicationDocument} {...props} />
    );
    
export function useDeleteApplicationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApplicationMutation, DeleteApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApplicationMutation, DeleteApplicationMutationVariables>(DeleteApplicationDocument, options);
      }
export type DeleteApplicationMutationHookResult = ReturnType<typeof useDeleteApplicationMutation>;
export type DeleteApplicationMutationResult = Apollo.MutationResult<DeleteApplicationMutation>;
export const DeleteApprovalDocument = gql`
    mutation deleteApproval($id: ID!) {
  deleteApproval(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteApprovalMutationFn = Apollo.MutationFunction<DeleteApprovalMutation, DeleteApprovalMutationVariables>;
export type DeleteApprovalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteApprovalMutation, DeleteApprovalMutationVariables>, 'mutation'>;

    export const DeleteApprovalComponent = (props: DeleteApprovalComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteApprovalMutation, DeleteApprovalMutationVariables> mutation={DeleteApprovalDocument} {...props} />
    );
    
export function useDeleteApprovalMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalMutation, DeleteApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalMutation, DeleteApprovalMutationVariables>(DeleteApprovalDocument, options);
      }
export type DeleteApprovalMutationHookResult = ReturnType<typeof useDeleteApprovalMutation>;
export type DeleteApprovalMutationResult = Apollo.MutationResult<DeleteApprovalMutation>;
export const DeleteAreaDocument = gql`
    mutation deleteArea($id: ID!) {
  deleteArea(id: $id) {
    data {
      ...Area
    }
  }
}
    ${AreaFragmentDoc}
${FileFragmentDoc}`;
export type DeleteAreaMutationFn = Apollo.MutationFunction<DeleteAreaMutation, DeleteAreaMutationVariables>;
export type DeleteAreaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAreaMutation, DeleteAreaMutationVariables>, 'mutation'>;

    export const DeleteAreaComponent = (props: DeleteAreaComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAreaMutation, DeleteAreaMutationVariables> mutation={DeleteAreaDocument} {...props} />
    );
    
export function useDeleteAreaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAreaMutation, DeleteAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAreaMutation, DeleteAreaMutationVariables>(DeleteAreaDocument, options);
      }
export type DeleteAreaMutationHookResult = ReturnType<typeof useDeleteAreaMutation>;
export type DeleteAreaMutationResult = Apollo.MutationResult<DeleteAreaMutation>;
export const DeleteCertificateDocument = gql`
    mutation deleteCertificate($id: ID!) {
  deleteCompletionCertificate(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteCertificateMutationFn = Apollo.MutationFunction<DeleteCertificateMutation, DeleteCertificateMutationVariables>;
export type DeleteCertificateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCertificateMutation, DeleteCertificateMutationVariables>, 'mutation'>;

    export const DeleteCertificateComponent = (props: DeleteCertificateComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCertificateMutation, DeleteCertificateMutationVariables> mutation={DeleteCertificateDocument} {...props} />
    );
    
export function useDeleteCertificateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCertificateMutation, DeleteCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCertificateMutation, DeleteCertificateMutationVariables>(DeleteCertificateDocument, options);
      }
export type DeleteCertificateMutationHookResult = ReturnType<typeof useDeleteCertificateMutation>;
export type DeleteCertificateMutationResult = Apollo.MutationResult<DeleteCertificateMutation>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: ID!) {
  deleteCompany(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export type DeleteCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>, 'mutation'>;

    export const DeleteCompanyComponent = (props: DeleteCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCompanyMutation, DeleteCompanyMutationVariables> mutation={DeleteCompanyDocument} {...props} />
    );
    
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export const DeleteFileDocument = gql`
    mutation deleteFile($id: ID!) {
  deleteUploadFile(id: $id) {
    data {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;
export type DeleteFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteFileMutation, DeleteFileMutationVariables>, 'mutation'>;

    export const DeleteFileComponent = (props: DeleteFileComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteFileMutation, DeleteFileMutationVariables> mutation={DeleteFileDocument} {...props} />
    );
    
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export const DeleteKickoffInviteDocument = gql`
    mutation deleteKickoffInvite($id: ID!) {
  deleteKickoffInvite(id: $id) {
    data {
      ...KickoffInvite
    }
  }
}
    ${KickoffInviteFragmentDoc}
${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type DeleteKickoffInviteMutationFn = Apollo.MutationFunction<DeleteKickoffInviteMutation, DeleteKickoffInviteMutationVariables>;
export type DeleteKickoffInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteKickoffInviteMutation, DeleteKickoffInviteMutationVariables>, 'mutation'>;

    export const DeleteKickoffInviteComponent = (props: DeleteKickoffInviteComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteKickoffInviteMutation, DeleteKickoffInviteMutationVariables> mutation={DeleteKickoffInviteDocument} {...props} />
    );
    
export function useDeleteKickoffInviteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKickoffInviteMutation, DeleteKickoffInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKickoffInviteMutation, DeleteKickoffInviteMutationVariables>(DeleteKickoffInviteDocument, options);
      }
export type DeleteKickoffInviteMutationHookResult = ReturnType<typeof useDeleteKickoffInviteMutation>;
export type DeleteKickoffInviteMutationResult = Apollo.MutationResult<DeleteKickoffInviteMutation>;
export const DeleteKickoffRecordDocument = gql`
    mutation deleteKickoffRecord($id: ID!) {
  deleteKickoffRecord(id: $id) {
    data {
      ...KickoffRecord
    }
  }
}
    ${KickoffRecordFragmentDoc}
${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type DeleteKickoffRecordMutationFn = Apollo.MutationFunction<DeleteKickoffRecordMutation, DeleteKickoffRecordMutationVariables>;
export type DeleteKickoffRecordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteKickoffRecordMutation, DeleteKickoffRecordMutationVariables>, 'mutation'>;

    export const DeleteKickoffRecordComponent = (props: DeleteKickoffRecordComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteKickoffRecordMutation, DeleteKickoffRecordMutationVariables> mutation={DeleteKickoffRecordDocument} {...props} />
    );
    
export function useDeleteKickoffRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKickoffRecordMutation, DeleteKickoffRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKickoffRecordMutation, DeleteKickoffRecordMutationVariables>(DeleteKickoffRecordDocument, options);
      }
export type DeleteKickoffRecordMutationHookResult = ReturnType<typeof useDeleteKickoffRecordMutation>;
export type DeleteKickoffRecordMutationResult = Apollo.MutationResult<DeleteKickoffRecordMutation>;
export const DeleteNoteDocument = gql`
    mutation deleteNote($id: ID!) {
  deleteNote(id: $id) {
    data {
      ...Note
    }
  }
}
    ${NoteFragmentDoc}
${FileFragmentDoc}`;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;
export type DeleteNoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteNoteMutation, DeleteNoteMutationVariables>, 'mutation'>;

    export const DeleteNoteComponent = (props: DeleteNoteComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteNoteMutation, DeleteNoteMutationVariables> mutation={DeleteNoteDocument} {...props} />
    );
    
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($id: ID!) {
  deleteProject(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;
export type DeleteProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProjectMutation, DeleteProjectMutationVariables>, 'mutation'>;

    export const DeleteProjectComponent = (props: DeleteProjectComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProjectMutation, DeleteProjectMutationVariables> mutation={DeleteProjectDocument} {...props} />
    );
    
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export const DeleteProvinceDocument = gql`
    mutation deleteProvince($id: ID!) {
  deleteProvince(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteProvinceMutationFn = Apollo.MutationFunction<DeleteProvinceMutation, DeleteProvinceMutationVariables>;
export type DeleteProvinceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProvinceMutation, DeleteProvinceMutationVariables>, 'mutation'>;

    export const DeleteProvinceComponent = (props: DeleteProvinceComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProvinceMutation, DeleteProvinceMutationVariables> mutation={DeleteProvinceDocument} {...props} />
    );
    
export function useDeleteProvinceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProvinceMutation, DeleteProvinceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProvinceMutation, DeleteProvinceMutationVariables>(DeleteProvinceDocument, options);
      }
export type DeleteProvinceMutationHookResult = ReturnType<typeof useDeleteProvinceMutation>;
export type DeleteProvinceMutationResult = Apollo.MutationResult<DeleteProvinceMutation>;
export const DeleteTaskDocument = gql`
    mutation deleteTask($id: ID!) {
  deleteTask(id: $id) {
    data {
      ...Task
    }
  }
}
    ${TaskFragmentDoc}`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;
export type DeleteTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTaskMutation, DeleteTaskMutationVariables>, 'mutation'>;

    export const DeleteTaskComponent = (props: DeleteTaskComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTaskMutation, DeleteTaskMutationVariables> mutation={DeleteTaskDocument} {...props} />
    );
    
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export type ForgotPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>, 'mutation'>;

    export const ForgotPasswordComponent = (props: ForgotPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> mutation={ForgotPasswordDocument} {...props} />
    );
    
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      id
      email
      username
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const PaymentLinkDocument = gql`
    mutation paymentLink($input: PaymentLinkInput!) {
  paymentLink(input: $input)
}
    `;
export type PaymentLinkMutationFn = Apollo.MutationFunction<PaymentLinkMutation, PaymentLinkMutationVariables>;
export type PaymentLinkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PaymentLinkMutation, PaymentLinkMutationVariables>, 'mutation'>;

    export const PaymentLinkComponent = (props: PaymentLinkComponentProps) => (
      <ApolloReactComponents.Mutation<PaymentLinkMutation, PaymentLinkMutationVariables> mutation={PaymentLinkDocument} {...props} />
    );
    
export function usePaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<PaymentLinkMutation, PaymentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaymentLinkMutation, PaymentLinkMutationVariables>(PaymentLinkDocument, options);
      }
export type PaymentLinkMutationHookResult = ReturnType<typeof usePaymentLinkMutation>;
export type PaymentLinkMutationResult = Apollo.MutationResult<PaymentLinkMutation>;
export const PricingSubmitRequestDocument = gql`
    mutation pricingSubmitRequest($input: PricingRequestFormSubmitInput!) {
  pricingRequestFormSubmit(input: $input)
}
    `;
export type PricingSubmitRequestMutationFn = Apollo.MutationFunction<PricingSubmitRequestMutation, PricingSubmitRequestMutationVariables>;
export type PricingSubmitRequestComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PricingSubmitRequestMutation, PricingSubmitRequestMutationVariables>, 'mutation'>;

    export const PricingSubmitRequestComponent = (props: PricingSubmitRequestComponentProps) => (
      <ApolloReactComponents.Mutation<PricingSubmitRequestMutation, PricingSubmitRequestMutationVariables> mutation={PricingSubmitRequestDocument} {...props} />
    );
    
export function usePricingSubmitRequestMutation(baseOptions?: Apollo.MutationHookOptions<PricingSubmitRequestMutation, PricingSubmitRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PricingSubmitRequestMutation, PricingSubmitRequestMutationVariables>(PricingSubmitRequestDocument, options);
      }
export type PricingSubmitRequestMutationHookResult = ReturnType<typeof usePricingSubmitRequestMutation>;
export type PricingSubmitRequestMutationResult = Apollo.MutationResult<PricingSubmitRequestMutation>;
export const QuoteIssuedEmailNotificationDocument = gql`
    mutation quoteIssuedEmailNotification($input: QuoteIssuedEmailNotificationInput!) {
  quoteIssuedEmailNotification(input: $input)
}
    `;
export type QuoteIssuedEmailNotificationMutationFn = Apollo.MutationFunction<QuoteIssuedEmailNotificationMutation, QuoteIssuedEmailNotificationMutationVariables>;
export type QuoteIssuedEmailNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<QuoteIssuedEmailNotificationMutation, QuoteIssuedEmailNotificationMutationVariables>, 'mutation'>;

    export const QuoteIssuedEmailNotificationComponent = (props: QuoteIssuedEmailNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<QuoteIssuedEmailNotificationMutation, QuoteIssuedEmailNotificationMutationVariables> mutation={QuoteIssuedEmailNotificationDocument} {...props} />
    );
    
export function useQuoteIssuedEmailNotificationMutation(baseOptions?: Apollo.MutationHookOptions<QuoteIssuedEmailNotificationMutation, QuoteIssuedEmailNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuoteIssuedEmailNotificationMutation, QuoteIssuedEmailNotificationMutationVariables>(QuoteIssuedEmailNotificationDocument, options);
      }
export type QuoteIssuedEmailNotificationMutationHookResult = ReturnType<typeof useQuoteIssuedEmailNotificationMutation>;
export type QuoteIssuedEmailNotificationMutationResult = Apollo.MutationResult<QuoteIssuedEmailNotificationMutation>;
export const RegisterDocument = gql`
    mutation register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      role {
        id
        name
      }
      email
      username
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export type RegisterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterMutation, RegisterMutationVariables>, 'mutation'>;

    export const RegisterComponent = (props: RegisterComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterMutation, RegisterMutationVariables> mutation={RegisterDocument} {...props} />
    );
    
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export const RegistrationSubmitHsDocument = gql`
    mutation registrationSubmitHs($input: RegistrationFormSubmitInput!) {
  registrationFormSubmit(input: $input)
}
    `;
export type RegistrationSubmitHsMutationFn = Apollo.MutationFunction<RegistrationSubmitHsMutation, RegistrationSubmitHsMutationVariables>;
export type RegistrationSubmitHsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegistrationSubmitHsMutation, RegistrationSubmitHsMutationVariables>, 'mutation'>;

    export const RegistrationSubmitHsComponent = (props: RegistrationSubmitHsComponentProps) => (
      <ApolloReactComponents.Mutation<RegistrationSubmitHsMutation, RegistrationSubmitHsMutationVariables> mutation={RegistrationSubmitHsDocument} {...props} />
    );
    
export function useRegistrationSubmitHsMutation(baseOptions?: Apollo.MutationHookOptions<RegistrationSubmitHsMutation, RegistrationSubmitHsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegistrationSubmitHsMutation, RegistrationSubmitHsMutationVariables>(RegistrationSubmitHsDocument, options);
      }
export type RegistrationSubmitHsMutationHookResult = ReturnType<typeof useRegistrationSubmitHsMutation>;
export type RegistrationSubmitHsMutationResult = Apollo.MutationResult<RegistrationSubmitHsMutation>;
export const ReportPdfDocument = gql`
    mutation reportPdf($input: ReportPdfInput!) {
  reportPdf(input: $input)
}
    `;
export type ReportPdfMutationFn = Apollo.MutationFunction<ReportPdfMutation, ReportPdfMutationVariables>;
export type ReportPdfComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReportPdfMutation, ReportPdfMutationVariables>, 'mutation'>;

    export const ReportPdfComponent = (props: ReportPdfComponentProps) => (
      <ApolloReactComponents.Mutation<ReportPdfMutation, ReportPdfMutationVariables> mutation={ReportPdfDocument} {...props} />
    );
    
export function useReportPdfMutation(baseOptions?: Apollo.MutationHookOptions<ReportPdfMutation, ReportPdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportPdfMutation, ReportPdfMutationVariables>(ReportPdfDocument, options);
      }
export type ReportPdfMutationHookResult = ReturnType<typeof useReportPdfMutation>;
export type ReportPdfMutationResult = Apollo.MutationResult<ReportPdfMutation>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
  resetPassword(
    password: $password
    passwordConfirmation: $passwordConfirmation
    code: $code
  ) {
    jwt
    user {
      id
      email
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export type ResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordMutation, ResetPasswordMutationVariables>, 'mutation'>;

    export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> mutation={ResetPasswordDocument} {...props} />
    );
    
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export const RestoreProjectDocument = gql`
    mutation restoreProject($input: RestoreProjectInput!) {
  restoreProject(input: $input)
}
    `;
export type RestoreProjectMutationFn = Apollo.MutationFunction<RestoreProjectMutation, RestoreProjectMutationVariables>;
export type RestoreProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RestoreProjectMutation, RestoreProjectMutationVariables>, 'mutation'>;

    export const RestoreProjectComponent = (props: RestoreProjectComponentProps) => (
      <ApolloReactComponents.Mutation<RestoreProjectMutation, RestoreProjectMutationVariables> mutation={RestoreProjectDocument} {...props} />
    );
    
export function useRestoreProjectMutation(baseOptions?: Apollo.MutationHookOptions<RestoreProjectMutation, RestoreProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestoreProjectMutation, RestoreProjectMutationVariables>(RestoreProjectDocument, options);
      }
export type RestoreProjectMutationHookResult = ReturnType<typeof useRestoreProjectMutation>;
export type RestoreProjectMutationResult = Apollo.MutationResult<RestoreProjectMutation>;
export const ResubmitApplicationDocument = gql`
    mutation resubmitApplication($input: ResubmitApplicationInput!) {
  resubmittingApplication(input: $input)
}
    `;
export type ResubmitApplicationMutationFn = Apollo.MutationFunction<ResubmitApplicationMutation, ResubmitApplicationMutationVariables>;
export type ResubmitApplicationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResubmitApplicationMutation, ResubmitApplicationMutationVariables>, 'mutation'>;

    export const ResubmitApplicationComponent = (props: ResubmitApplicationComponentProps) => (
      <ApolloReactComponents.Mutation<ResubmitApplicationMutation, ResubmitApplicationMutationVariables> mutation={ResubmitApplicationDocument} {...props} />
    );
    
export function useResubmitApplicationMutation(baseOptions?: Apollo.MutationHookOptions<ResubmitApplicationMutation, ResubmitApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResubmitApplicationMutation, ResubmitApplicationMutationVariables>(ResubmitApplicationDocument, options);
      }
export type ResubmitApplicationMutationHookResult = ReturnType<typeof useResubmitApplicationMutation>;
export type ResubmitApplicationMutationResult = Apollo.MutationResult<ResubmitApplicationMutation>;
export const SendAgentAssignNotificationDocument = gql`
    mutation sendAgentAssignNotification($input: SendAgentAssignNotificationInput!) {
  sendAgentAssignNotification(input: $input)
}
    `;
export type SendAgentAssignNotificationMutationFn = Apollo.MutationFunction<SendAgentAssignNotificationMutation, SendAgentAssignNotificationMutationVariables>;
export type SendAgentAssignNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendAgentAssignNotificationMutation, SendAgentAssignNotificationMutationVariables>, 'mutation'>;

    export const SendAgentAssignNotificationComponent = (props: SendAgentAssignNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<SendAgentAssignNotificationMutation, SendAgentAssignNotificationMutationVariables> mutation={SendAgentAssignNotificationDocument} {...props} />
    );
    
export function useSendAgentAssignNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendAgentAssignNotificationMutation, SendAgentAssignNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAgentAssignNotificationMutation, SendAgentAssignNotificationMutationVariables>(SendAgentAssignNotificationDocument, options);
      }
export type SendAgentAssignNotificationMutationHookResult = ReturnType<typeof useSendAgentAssignNotificationMutation>;
export type SendAgentAssignNotificationMutationResult = Apollo.MutationResult<SendAgentAssignNotificationMutation>;
export const SendApprovalItemEmailDocument = gql`
    mutation sendApprovalItemEmail($input: SendApprovalItemEmailInput!) {
  sendApprovalItemEmail(input: $input)
}
    `;
export type SendApprovalItemEmailMutationFn = Apollo.MutationFunction<SendApprovalItemEmailMutation, SendApprovalItemEmailMutationVariables>;
export type SendApprovalItemEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendApprovalItemEmailMutation, SendApprovalItemEmailMutationVariables>, 'mutation'>;

    export const SendApprovalItemEmailComponent = (props: SendApprovalItemEmailComponentProps) => (
      <ApolloReactComponents.Mutation<SendApprovalItemEmailMutation, SendApprovalItemEmailMutationVariables> mutation={SendApprovalItemEmailDocument} {...props} />
    );
    
export function useSendApprovalItemEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendApprovalItemEmailMutation, SendApprovalItemEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendApprovalItemEmailMutation, SendApprovalItemEmailMutationVariables>(SendApprovalItemEmailDocument, options);
      }
export type SendApprovalItemEmailMutationHookResult = ReturnType<typeof useSendApprovalItemEmailMutation>;
export type SendApprovalItemEmailMutationResult = Apollo.MutationResult<SendApprovalItemEmailMutation>;
export const SendCompaniesNotificationsDocument = gql`
    mutation sendCompaniesNotifications($input: SendCompaniesNotificationsInput!) {
  sendCompaniesNotifications(input: $input)
}
    `;
export type SendCompaniesNotificationsMutationFn = Apollo.MutationFunction<SendCompaniesNotificationsMutation, SendCompaniesNotificationsMutationVariables>;
export type SendCompaniesNotificationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendCompaniesNotificationsMutation, SendCompaniesNotificationsMutationVariables>, 'mutation'>;

    export const SendCompaniesNotificationsComponent = (props: SendCompaniesNotificationsComponentProps) => (
      <ApolloReactComponents.Mutation<SendCompaniesNotificationsMutation, SendCompaniesNotificationsMutationVariables> mutation={SendCompaniesNotificationsDocument} {...props} />
    );
    
export function useSendCompaniesNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SendCompaniesNotificationsMutation, SendCompaniesNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendCompaniesNotificationsMutation, SendCompaniesNotificationsMutationVariables>(SendCompaniesNotificationsDocument, options);
      }
export type SendCompaniesNotificationsMutationHookResult = ReturnType<typeof useSendCompaniesNotificationsMutation>;
export type SendCompaniesNotificationsMutationResult = Apollo.MutationResult<SendCompaniesNotificationsMutation>;
export const SendInviteDocument = gql`
    mutation sendInvite($input: SendInviteInput!) {
  sendInvite(input: $input)
}
    `;
export type SendInviteMutationFn = Apollo.MutationFunction<SendInviteMutation, SendInviteMutationVariables>;
export type SendInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendInviteMutation, SendInviteMutationVariables>, 'mutation'>;

    export const SendInviteComponent = (props: SendInviteComponentProps) => (
      <ApolloReactComponents.Mutation<SendInviteMutation, SendInviteMutationVariables> mutation={SendInviteDocument} {...props} />
    );
    
export function useSendInviteMutation(baseOptions?: Apollo.MutationHookOptions<SendInviteMutation, SendInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInviteMutation, SendInviteMutationVariables>(SendInviteDocument, options);
      }
export type SendInviteMutationHookResult = ReturnType<typeof useSendInviteMutation>;
export type SendInviteMutationResult = Apollo.MutationResult<SendInviteMutation>;
export const SendRecordDocument = gql`
    mutation sendRecord($input: SendRecordInput!) {
  sendRecord(input: $input)
}
    `;
export type SendRecordMutationFn = Apollo.MutationFunction<SendRecordMutation, SendRecordMutationVariables>;
export type SendRecordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendRecordMutation, SendRecordMutationVariables>, 'mutation'>;

    export const SendRecordComponent = (props: SendRecordComponentProps) => (
      <ApolloReactComponents.Mutation<SendRecordMutation, SendRecordMutationVariables> mutation={SendRecordDocument} {...props} />
    );
    
export function useSendRecordMutation(baseOptions?: Apollo.MutationHookOptions<SendRecordMutation, SendRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendRecordMutation, SendRecordMutationVariables>(SendRecordDocument, options);
      }
export type SendRecordMutationHookResult = ReturnType<typeof useSendRecordMutation>;
export type SendRecordMutationResult = Apollo.MutationResult<SendRecordMutation>;
export const SendRequestInfoNotificationDocument = gql`
    mutation sendRequestInfoNotification($input: SendRequestInfoNotificationInput!) {
  sendRequestInfoNotification(input: $input)
}
    `;
export type SendRequestInfoNotificationMutationFn = Apollo.MutationFunction<SendRequestInfoNotificationMutation, SendRequestInfoNotificationMutationVariables>;
export type SendRequestInfoNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendRequestInfoNotificationMutation, SendRequestInfoNotificationMutationVariables>, 'mutation'>;

    export const SendRequestInfoNotificationComponent = (props: SendRequestInfoNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<SendRequestInfoNotificationMutation, SendRequestInfoNotificationMutationVariables> mutation={SendRequestInfoNotificationDocument} {...props} />
    );
    
export function useSendRequestInfoNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendRequestInfoNotificationMutation, SendRequestInfoNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendRequestInfoNotificationMutation, SendRequestInfoNotificationMutationVariables>(SendRequestInfoNotificationDocument, options);
      }
export type SendRequestInfoNotificationMutationHookResult = ReturnType<typeof useSendRequestInfoNotificationMutation>;
export type SendRequestInfoNotificationMutationResult = Apollo.MutationResult<SendRequestInfoNotificationMutation>;
export const SubmitApplicationEmailNotificationDocument = gql`
    mutation submitApplicationEmailNotification($input: SubmittedAppEmailNotificationInput!) {
  submittedAppEmailSending(input: $input)
}
    `;
export type SubmitApplicationEmailNotificationMutationFn = Apollo.MutationFunction<SubmitApplicationEmailNotificationMutation, SubmitApplicationEmailNotificationMutationVariables>;
export type SubmitApplicationEmailNotificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitApplicationEmailNotificationMutation, SubmitApplicationEmailNotificationMutationVariables>, 'mutation'>;

    export const SubmitApplicationEmailNotificationComponent = (props: SubmitApplicationEmailNotificationComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitApplicationEmailNotificationMutation, SubmitApplicationEmailNotificationMutationVariables> mutation={SubmitApplicationEmailNotificationDocument} {...props} />
    );
    
export function useSubmitApplicationEmailNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SubmitApplicationEmailNotificationMutation, SubmitApplicationEmailNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitApplicationEmailNotificationMutation, SubmitApplicationEmailNotificationMutationVariables>(SubmitApplicationEmailNotificationDocument, options);
      }
export type SubmitApplicationEmailNotificationMutationHookResult = ReturnType<typeof useSubmitApplicationEmailNotificationMutation>;
export type SubmitApplicationEmailNotificationMutationResult = Apollo.MutationResult<SubmitApplicationEmailNotificationMutation>;
export const Template01Document = gql`
    mutation template01($input: Template01Input!) {
  template01(input: $input)
}
    `;
export type Template01MutationFn = Apollo.MutationFunction<Template01Mutation, Template01MutationVariables>;
export type Template01ComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<Template01Mutation, Template01MutationVariables>, 'mutation'>;

    export const Template01Component = (props: Template01ComponentProps) => (
      <ApolloReactComponents.Mutation<Template01Mutation, Template01MutationVariables> mutation={Template01Document} {...props} />
    );
    
export function useTemplate01Mutation(baseOptions?: Apollo.MutationHookOptions<Template01Mutation, Template01MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Template01Mutation, Template01MutationVariables>(Template01Document, options);
      }
export type Template01MutationHookResult = ReturnType<typeof useTemplate01Mutation>;
export type Template01MutationResult = Apollo.MutationResult<Template01Mutation>;
export const TestEmailSendingDocument = gql`
    mutation testEmailSending {
  testEmailSending
}
    `;
export type TestEmailSendingMutationFn = Apollo.MutationFunction<TestEmailSendingMutation, TestEmailSendingMutationVariables>;
export type TestEmailSendingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TestEmailSendingMutation, TestEmailSendingMutationVariables>, 'mutation'>;

    export const TestEmailSendingComponent = (props: TestEmailSendingComponentProps) => (
      <ApolloReactComponents.Mutation<TestEmailSendingMutation, TestEmailSendingMutationVariables> mutation={TestEmailSendingDocument} {...props} />
    );
    
export function useTestEmailSendingMutation(baseOptions?: Apollo.MutationHookOptions<TestEmailSendingMutation, TestEmailSendingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestEmailSendingMutation, TestEmailSendingMutationVariables>(TestEmailSendingDocument, options);
      }
export type TestEmailSendingMutationHookResult = ReturnType<typeof useTestEmailSendingMutation>;
export type TestEmailSendingMutationResult = Apollo.MutationResult<TestEmailSendingMutation>;
export const TransferApprovalsDocument = gql`
    mutation transferApprovals {
  transferApprovals
}
    `;
export type TransferApprovalsMutationFn = Apollo.MutationFunction<TransferApprovalsMutation, TransferApprovalsMutationVariables>;
export type TransferApprovalsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TransferApprovalsMutation, TransferApprovalsMutationVariables>, 'mutation'>;

    export const TransferApprovalsComponent = (props: TransferApprovalsComponentProps) => (
      <ApolloReactComponents.Mutation<TransferApprovalsMutation, TransferApprovalsMutationVariables> mutation={TransferApprovalsDocument} {...props} />
    );
    
export function useTransferApprovalsMutation(baseOptions?: Apollo.MutationHookOptions<TransferApprovalsMutation, TransferApprovalsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferApprovalsMutation, TransferApprovalsMutationVariables>(TransferApprovalsDocument, options);
      }
export type TransferApprovalsMutationHookResult = ReturnType<typeof useTransferApprovalsMutation>;
export type TransferApprovalsMutationResult = Apollo.MutationResult<TransferApprovalsMutation>;
export const UpdateApplicationDocument = gql`
    mutation updateApplication($id: ID!, $data: ApplicationInput!) {
  updateApplication(id: $id, data: $data) {
    data {
      ...Application
    }
  }
}
    ${ApplicationFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${ApplicationNatureFragmentDoc}
${UserProfileFragmentDoc}
${ApprovalFragmentDoc}
${EmailMessageFragmentDoc}`;
export type UpdateApplicationMutationFn = Apollo.MutationFunction<UpdateApplicationMutation, UpdateApplicationMutationVariables>;
export type UpdateApplicationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>, 'mutation'>;

    export const UpdateApplicationComponent = (props: UpdateApplicationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateApplicationMutation, UpdateApplicationMutationVariables> mutation={UpdateApplicationDocument} {...props} />
    );
    
export function useUpdateApplicationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApplicationMutation, UpdateApplicationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApplicationMutation, UpdateApplicationMutationVariables>(UpdateApplicationDocument, options);
      }
export type UpdateApplicationMutationHookResult = ReturnType<typeof useUpdateApplicationMutation>;
export type UpdateApplicationMutationResult = Apollo.MutationResult<UpdateApplicationMutation>;
export const UpdateApprovalDocument = gql`
    mutation updateApproval($id: ID!, $data: ApprovalInput!) {
  updateApproval(id: $id, data: $data) {
    data {
      ...Approval
    }
  }
}
    ${ApprovalFragmentDoc}
${EmailMessageFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${UserProfileFragmentDoc}`;
export type UpdateApprovalMutationFn = Apollo.MutationFunction<UpdateApprovalMutation, UpdateApprovalMutationVariables>;
export type UpdateApprovalComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateApprovalMutation, UpdateApprovalMutationVariables>, 'mutation'>;

    export const UpdateApprovalComponent = (props: UpdateApprovalComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateApprovalMutation, UpdateApprovalMutationVariables> mutation={UpdateApprovalDocument} {...props} />
    );
    
export function useUpdateApprovalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApprovalMutation, UpdateApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApprovalMutation, UpdateApprovalMutationVariables>(UpdateApprovalDocument, options);
      }
export type UpdateApprovalMutationHookResult = ReturnType<typeof useUpdateApprovalMutation>;
export type UpdateApprovalMutationResult = Apollo.MutationResult<UpdateApprovalMutation>;
export const UpdateAreaDocument = gql`
    mutation updateArea($id: ID!, $data: AreaInput!) {
  updateArea(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateAreaMutationFn = Apollo.MutationFunction<UpdateAreaMutation, UpdateAreaMutationVariables>;
export type UpdateAreaComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAreaMutation, UpdateAreaMutationVariables>, 'mutation'>;

    export const UpdateAreaComponent = (props: UpdateAreaComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAreaMutation, UpdateAreaMutationVariables> mutation={UpdateAreaDocument} {...props} />
    );
    
export function useUpdateAreaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAreaMutation, UpdateAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAreaMutation, UpdateAreaMutationVariables>(UpdateAreaDocument, options);
      }
export type UpdateAreaMutationHookResult = ReturnType<typeof useUpdateAreaMutation>;
export type UpdateAreaMutationResult = Apollo.MutationResult<UpdateAreaMutation>;
export const UpdateCertificateDocument = gql`
    mutation updateCertificate($id: ID!, $data: CompletionCertificateInput!) {
  updateCompletionCertificate(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateCertificateMutationFn = Apollo.MutationFunction<UpdateCertificateMutation, UpdateCertificateMutationVariables>;
export type UpdateCertificateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCertificateMutation, UpdateCertificateMutationVariables>, 'mutation'>;

    export const UpdateCertificateComponent = (props: UpdateCertificateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCertificateMutation, UpdateCertificateMutationVariables> mutation={UpdateCertificateDocument} {...props} />
    );
    
export function useUpdateCertificateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCertificateMutation, UpdateCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCertificateMutation, UpdateCertificateMutationVariables>(UpdateCertificateDocument, options);
      }
export type UpdateCertificateMutationHookResult = ReturnType<typeof useUpdateCertificateMutation>;
export type UpdateCertificateMutationResult = Apollo.MutationResult<UpdateCertificateMutation>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: ID!, $data: CompanyInput!) {
  updateCompany(id: $id, data: $data) {
    data {
      ...Company
    }
  }
}
    ${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${FileFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export type UpdateCompanyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>, 'mutation'>;

    export const UpdateCompanyComponent = (props: UpdateCompanyComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> mutation={UpdateCompanyDocument} {...props} />
    );
    
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export const UpdateInviteDocument = gql`
    mutation updateInvite($id: ID!, $data: KickoffInviteInput!) {
  updateKickoffInvite(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateInviteMutationFn = Apollo.MutationFunction<UpdateInviteMutation, UpdateInviteMutationVariables>;
export type UpdateInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInviteMutation, UpdateInviteMutationVariables>, 'mutation'>;

    export const UpdateInviteComponent = (props: UpdateInviteComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInviteMutation, UpdateInviteMutationVariables> mutation={UpdateInviteDocument} {...props} />
    );
    
export function useUpdateInviteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInviteMutation, UpdateInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInviteMutation, UpdateInviteMutationVariables>(UpdateInviteDocument, options);
      }
export type UpdateInviteMutationHookResult = ReturnType<typeof useUpdateInviteMutation>;
export type UpdateInviteMutationResult = Apollo.MutationResult<UpdateInviteMutation>;
export const UpdateInvoiceDocument = gql`
    mutation updateInvoice($id: ID!, $data: InvoiceInput!) {
  updateInvoice(id: $id, data: $data) {
    data {
      ...Invoice
    }
  }
}
    ${InvoiceFragmentDoc}
${FileFragmentDoc}`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export type UpdateInvoiceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>, 'mutation'>;

    export const UpdateInvoiceComponent = (props: UpdateInvoiceComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables> mutation={UpdateInvoiceDocument} {...props} />
    );
    
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export const UpdateNoteDocument = gql`
    mutation updateNote($id: ID!, $data: NoteInput!) {
  updateNote(id: $id, data: $data) {
    data {
      ...Note
    }
  }
}
    ${NoteFragmentDoc}
${FileFragmentDoc}`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;
export type UpdateNoteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateNoteMutation, UpdateNoteMutationVariables>, 'mutation'>;

    export const UpdateNoteComponent = (props: UpdateNoteComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateNoteMutation, UpdateNoteMutationVariables> mutation={UpdateNoteDocument} {...props} />
    );
    
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export const UpdateNotificationMessageDocument = gql`
    mutation updateNotificationMessage($id: ID!, $data: NotificationMessageInput!) {
  updateNotificationMessage(id: $id, data: $data) {
    data {
      ...NotificationMessage
    }
  }
}
    ${NotificationMessageFragmentDoc}
${FileFragmentDoc}`;
export type UpdateNotificationMessageMutationFn = Apollo.MutationFunction<UpdateNotificationMessageMutation, UpdateNotificationMessageMutationVariables>;
export type UpdateNotificationMessageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateNotificationMessageMutation, UpdateNotificationMessageMutationVariables>, 'mutation'>;

    export const UpdateNotificationMessageComponent = (props: UpdateNotificationMessageComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateNotificationMessageMutation, UpdateNotificationMessageMutationVariables> mutation={UpdateNotificationMessageDocument} {...props} />
    );
    
export function useUpdateNotificationMessageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMessageMutation, UpdateNotificationMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMessageMutation, UpdateNotificationMessageMutationVariables>(UpdateNotificationMessageDocument, options);
      }
export type UpdateNotificationMessageMutationHookResult = ReturnType<typeof useUpdateNotificationMessageMutation>;
export type UpdateNotificationMessageMutationResult = Apollo.MutationResult<UpdateNotificationMessageMutation>;
export const UpdatePaymentDocument = gql`
    mutation updatePayment($id: ID!, $data: PaymentInput!) {
  updatePayment(id: $id, data: $data) {
    data {
      ...Payment
    }
  }
}
    ${PaymentFragmentDoc}
${FileFragmentDoc}`;
export type UpdatePaymentMutationFn = Apollo.MutationFunction<UpdatePaymentMutation, UpdatePaymentMutationVariables>;
export type UpdatePaymentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>, 'mutation'>;

    export const UpdatePaymentComponent = (props: UpdatePaymentComponentProps) => (
      <ApolloReactComponents.Mutation<UpdatePaymentMutation, UpdatePaymentMutationVariables> mutation={UpdatePaymentDocument} {...props} />
    );
    
export function useUpdatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePaymentMutation, UpdatePaymentMutationVariables>(UpdatePaymentDocument, options);
      }
export type UpdatePaymentMutationHookResult = ReturnType<typeof useUpdatePaymentMutation>;
export type UpdatePaymentMutationResult = Apollo.MutationResult<UpdatePaymentMutation>;
export const UpdateProjectDocument = gql`
    mutation updateProject($id: ID!, $data: ProjectInput!) {
  updateProject(id: $id, data: $data) {
    data {
      ...Project
    }
  }
}
    ${ProjectFragmentDoc}
${ProvinceFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${ApplicationNatureFragmentDoc}`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;
export type UpdateProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectMutation, UpdateProjectMutationVariables>, 'mutation'>;

    export const UpdateProjectComponent = (props: UpdateProjectComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectMutation, UpdateProjectMutationVariables> mutation={UpdateProjectDocument} {...props} />
    );
    
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export const UpdateProvinceDocument = gql`
    mutation updateProvince($id: ID!, $data: ProvinceInput!) {
  updateProvince(id: $id, data: $data) {
    data {
      ...Province
    }
  }
}
    ${ProvinceFragmentDoc}
${FileFragmentDoc}`;
export type UpdateProvinceMutationFn = Apollo.MutationFunction<UpdateProvinceMutation, UpdateProvinceMutationVariables>;
export type UpdateProvinceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProvinceMutation, UpdateProvinceMutationVariables>, 'mutation'>;

    export const UpdateProvinceComponent = (props: UpdateProvinceComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProvinceMutation, UpdateProvinceMutationVariables> mutation={UpdateProvinceDocument} {...props} />
    );
    
export function useUpdateProvinceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProvinceMutation, UpdateProvinceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProvinceMutation, UpdateProvinceMutationVariables>(UpdateProvinceDocument, options);
      }
export type UpdateProvinceMutationHookResult = ReturnType<typeof useUpdateProvinceMutation>;
export type UpdateProvinceMutationResult = Apollo.MutationResult<UpdateProvinceMutation>;
export const UpdateQuotationDocument = gql`
    mutation updateQuotation($id: ID!, $data: QuotationInput!) {
  updateQuotation(id: $id, data: $data) {
    data {
      ...Quotation
    }
  }
}
    ${QuotationFragmentDoc}
${QuoteItemFragmentDoc}`;
export type UpdateQuotationMutationFn = Apollo.MutationFunction<UpdateQuotationMutation, UpdateQuotationMutationVariables>;
export type UpdateQuotationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateQuotationMutation, UpdateQuotationMutationVariables>, 'mutation'>;

    export const UpdateQuotationComponent = (props: UpdateQuotationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateQuotationMutation, UpdateQuotationMutationVariables> mutation={UpdateQuotationDocument} {...props} />
    );
    
export function useUpdateQuotationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuotationMutation, UpdateQuotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuotationMutation, UpdateQuotationMutationVariables>(UpdateQuotationDocument, options);
      }
export type UpdateQuotationMutationHookResult = ReturnType<typeof useUpdateQuotationMutation>;
export type UpdateQuotationMutationResult = Apollo.MutationResult<UpdateQuotationMutation>;
export const UpdateKickoffRecordDocument = gql`
    mutation updateKickoffRecord($id: ID!, $data: KickoffRecordInput!) {
  updateKickoffRecord(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export type UpdateKickoffRecordMutationFn = Apollo.MutationFunction<UpdateKickoffRecordMutation, UpdateKickoffRecordMutationVariables>;
export type UpdateKickoffRecordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateKickoffRecordMutation, UpdateKickoffRecordMutationVariables>, 'mutation'>;

    export const UpdateKickoffRecordComponent = (props: UpdateKickoffRecordComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateKickoffRecordMutation, UpdateKickoffRecordMutationVariables> mutation={UpdateKickoffRecordDocument} {...props} />
    );
    
export function useUpdateKickoffRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKickoffRecordMutation, UpdateKickoffRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKickoffRecordMutation, UpdateKickoffRecordMutationVariables>(UpdateKickoffRecordDocument, options);
      }
export type UpdateKickoffRecordMutationHookResult = ReturnType<typeof useUpdateKickoffRecordMutation>;
export type UpdateKickoffRecordMutationResult = Apollo.MutationResult<UpdateKickoffRecordMutation>;
export const UpdateSettingsDocument = gql`
    mutation updateSettings($data: SettingInput!) {
  updateSetting(data: $data) {
    data {
      id
      ...Settings
    }
  }
}
    ${SettingsFragmentDoc}`;
export type UpdateSettingsMutationFn = Apollo.MutationFunction<UpdateSettingsMutation, UpdateSettingsMutationVariables>;
export type UpdateSettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>, 'mutation'>;

    export const UpdateSettingsComponent = (props: UpdateSettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSettingsMutation, UpdateSettingsMutationVariables> mutation={UpdateSettingsDocument} {...props} />
    );
    
export function useUpdateSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSettingsMutation, UpdateSettingsMutationVariables>(UpdateSettingsDocument, options);
      }
export type UpdateSettingsMutationHookResult = ReturnType<typeof useUpdateSettingsMutation>;
export type UpdateSettingsMutationResult = Apollo.MutationResult<UpdateSettingsMutation>;
export const UpdateTaskDocument = gql`
    mutation updateTask($id: ID!, $data: TaskInput!) {
  updateTask(id: $id, data: $data) {
    data {
      ...Task
    }
  }
}
    ${TaskFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;
export type UpdateTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateTaskMutation, UpdateTaskMutationVariables>, 'mutation'>;

    export const UpdateTaskComponent = (props: UpdateTaskComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateTaskMutation, UpdateTaskMutationVariables> mutation={UpdateTaskDocument} {...props} />
    );
    
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      id
      attributes {
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>;

    export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserMutation, UpdateUserMutationVariables> mutation={UpdateUserDocument} {...props} />
    );
    
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const UpdateUserProfileDocument = gql`
    mutation updateUserProfile($id: ID!, $data: UserProfileInput!) {
  updateUserProfile(id: $id, data: $data) {
    data {
      ...UserProfile
    }
  }
}
    ${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export type UpdateUserProfileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>, 'mutation'>;

    export const UpdateUserProfileComponent = (props: UpdateUserProfileComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables> mutation={UpdateUserProfileDocument} {...props} />
    );
    
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export const UpdateUsersRoleDocument = gql`
    mutation updateUsersRole($id: ID!, $data: UsersPermissionsRoleInput!) {
  updateUsersPermissionsRole(id: $id, data: $data) {
    ok
  }
}
    `;
export type UpdateUsersRoleMutationFn = Apollo.MutationFunction<UpdateUsersRoleMutation, UpdateUsersRoleMutationVariables>;
export type UpdateUsersRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUsersRoleMutation, UpdateUsersRoleMutationVariables>, 'mutation'>;

    export const UpdateUsersRoleComponent = (props: UpdateUsersRoleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUsersRoleMutation, UpdateUsersRoleMutationVariables> mutation={UpdateUsersRoleDocument} {...props} />
    );
    
export function useUpdateUsersRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUsersRoleMutation, UpdateUsersRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUsersRoleMutation, UpdateUsersRoleMutationVariables>(UpdateUsersRoleDocument, options);
      }
export type UpdateUsersRoleMutationHookResult = ReturnType<typeof useUpdateUsersRoleMutation>;
export type UpdateUsersRoleMutationResult = Apollo.MutationResult<UpdateUsersRoleMutation>;
export const AgentCustomersInfoDocument = gql`
    query agentCustomersInfo($id: ID!) {
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        id
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type AgentCustomersInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AgentCustomersInfoQuery, AgentCustomersInfoQueryVariables>, 'query'> & ({ variables: AgentCustomersInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AgentCustomersInfoComponent = (props: AgentCustomersInfoComponentProps) => (
      <ApolloReactComponents.Query<AgentCustomersInfoQuery, AgentCustomersInfoQueryVariables> query={AgentCustomersInfoDocument} {...props} />
    );
    
export function useAgentCustomersInfoQuery(baseOptions: Apollo.QueryHookOptions<AgentCustomersInfoQuery, AgentCustomersInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgentCustomersInfoQuery, AgentCustomersInfoQueryVariables>(AgentCustomersInfoDocument, options);
      }
export function useAgentCustomersInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgentCustomersInfoQuery, AgentCustomersInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgentCustomersInfoQuery, AgentCustomersInfoQueryVariables>(AgentCustomersInfoDocument, options);
        }
export type AgentCustomersInfoQueryHookResult = ReturnType<typeof useAgentCustomersInfoQuery>;
export type AgentCustomersInfoLazyQueryHookResult = ReturnType<typeof useAgentCustomersInfoLazyQuery>;
export type AgentCustomersInfoQueryResult = Apollo.QueryResult<AgentCustomersInfoQuery, AgentCustomersInfoQueryVariables>;
export const AgentsStatisticsDocument = gql`
    query agentsStatistics($filter: UserProfileFiltersInput) {
  userProfiles(filters: $filter, pagination: {limit: -1}) {
    data {
      id
      attributes {
        uuid
        primaryEmail
        fullName
        tasks {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export type AgentsStatisticsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AgentsStatisticsQuery, AgentsStatisticsQueryVariables>, 'query'>;

    export const AgentsStatisticsComponent = (props: AgentsStatisticsComponentProps) => (
      <ApolloReactComponents.Query<AgentsStatisticsQuery, AgentsStatisticsQueryVariables> query={AgentsStatisticsDocument} {...props} />
    );
    
export function useAgentsStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<AgentsStatisticsQuery, AgentsStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgentsStatisticsQuery, AgentsStatisticsQueryVariables>(AgentsStatisticsDocument, options);
      }
export function useAgentsStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgentsStatisticsQuery, AgentsStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgentsStatisticsQuery, AgentsStatisticsQueryVariables>(AgentsStatisticsDocument, options);
        }
export type AgentsStatisticsQueryHookResult = ReturnType<typeof useAgentsStatisticsQuery>;
export type AgentsStatisticsLazyQueryHookResult = ReturnType<typeof useAgentsStatisticsLazyQuery>;
export type AgentsStatisticsQueryResult = Apollo.QueryResult<AgentsStatisticsQuery, AgentsStatisticsQueryVariables>;
export const AllCustomersDocument = gql`
    query allCustomers($filter: UsersPermissionsUserFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  usersPermissionsUsers(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        uuid
        email
        updatedAt
        role {
          data {
            attributes {
              name
            }
          }
        }
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type AllCustomersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllCustomersQuery, AllCustomersQueryVariables>, 'query'>;

    export const AllCustomersComponent = (props: AllCustomersComponentProps) => (
      <ApolloReactComponents.Query<AllCustomersQuery, AllCustomersQueryVariables> query={AllCustomersDocument} {...props} />
    );
    
export function useAllCustomersQuery(baseOptions?: Apollo.QueryHookOptions<AllCustomersQuery, AllCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCustomersQuery, AllCustomersQueryVariables>(AllCustomersDocument, options);
      }
export function useAllCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCustomersQuery, AllCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCustomersQuery, AllCustomersQueryVariables>(AllCustomersDocument, options);
        }
export type AllCustomersQueryHookResult = ReturnType<typeof useAllCustomersQuery>;
export type AllCustomersLazyQueryHookResult = ReturnType<typeof useAllCustomersLazyQuery>;
export type AllCustomersQueryResult = Apollo.QueryResult<AllCustomersQuery, AllCustomersQueryVariables>;
export const ApplicationDocument = gql`
    query application($id: ID!) {
  application(id: $id) {
    data {
      ...Application
    }
  }
}
    ${ApplicationFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${ApplicationNatureFragmentDoc}
${UserProfileFragmentDoc}
${ApprovalFragmentDoc}
${EmailMessageFragmentDoc}`;
export type ApplicationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationQuery, ApplicationQueryVariables>, 'query'> & ({ variables: ApplicationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ApplicationComponent = (props: ApplicationComponentProps) => (
      <ApolloReactComponents.Query<ApplicationQuery, ApplicationQueryVariables> query={ApplicationDocument} {...props} />
    );
    
export function useApplicationQuery(baseOptions: Apollo.QueryHookOptions<ApplicationQuery, ApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, options);
      }
export function useApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationQuery, ApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationQuery, ApplicationQueryVariables>(ApplicationDocument, options);
        }
export type ApplicationQueryHookResult = ReturnType<typeof useApplicationQuery>;
export type ApplicationLazyQueryHookResult = ReturnType<typeof useApplicationLazyQuery>;
export type ApplicationQueryResult = Apollo.QueryResult<ApplicationQuery, ApplicationQueryVariables>;
export const ApplicationCountDocument = gql`
    query applicationCount($filters: ApplicationFiltersInput) {
  applications(filters: $filters) {
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ApplicationCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationCountQuery, ApplicationCountQueryVariables>, 'query'>;

    export const ApplicationCountComponent = (props: ApplicationCountComponentProps) => (
      <ApolloReactComponents.Query<ApplicationCountQuery, ApplicationCountQueryVariables> query={ApplicationCountDocument} {...props} />
    );
    
export function useApplicationCountQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationCountQuery, ApplicationCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationCountQuery, ApplicationCountQueryVariables>(ApplicationCountDocument, options);
      }
export function useApplicationCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationCountQuery, ApplicationCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationCountQuery, ApplicationCountQueryVariables>(ApplicationCountDocument, options);
        }
export type ApplicationCountQueryHookResult = ReturnType<typeof useApplicationCountQuery>;
export type ApplicationCountLazyQueryHookResult = ReturnType<typeof useApplicationCountLazyQuery>;
export type ApplicationCountQueryResult = Apollo.QueryResult<ApplicationCountQuery, ApplicationCountQueryVariables>;
export const ApplicationMethodsDocument = gql`
    query applicationMethods {
  applicationMethods {
    data {
      ...ApplicationMethod
    }
  }
}
    ${ApplicationMethodFragmentDoc}`;
export type ApplicationMethodsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationMethodsQuery, ApplicationMethodsQueryVariables>, 'query'>;

    export const ApplicationMethodsComponent = (props: ApplicationMethodsComponentProps) => (
      <ApolloReactComponents.Query<ApplicationMethodsQuery, ApplicationMethodsQueryVariables> query={ApplicationMethodsDocument} {...props} />
    );
    
export function useApplicationMethodsQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationMethodsQuery, ApplicationMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationMethodsQuery, ApplicationMethodsQueryVariables>(ApplicationMethodsDocument, options);
      }
export function useApplicationMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationMethodsQuery, ApplicationMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationMethodsQuery, ApplicationMethodsQueryVariables>(ApplicationMethodsDocument, options);
        }
export type ApplicationMethodsQueryHookResult = ReturnType<typeof useApplicationMethodsQuery>;
export type ApplicationMethodsLazyQueryHookResult = ReturnType<typeof useApplicationMethodsLazyQuery>;
export type ApplicationMethodsQueryResult = Apollo.QueryResult<ApplicationMethodsQuery, ApplicationMethodsQueryVariables>;
export const ApplicationTitleUuidDocument = gql`
    query applicationTitleUuid($id: ID!) {
  application(id: $id) {
    data {
      id
      attributes {
        uuid
        title
      }
    }
  }
}
    `;
export type ApplicationTitleUuidComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationTitleUuidQuery, ApplicationTitleUuidQueryVariables>, 'query'> & ({ variables: ApplicationTitleUuidQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ApplicationTitleUuidComponent = (props: ApplicationTitleUuidComponentProps) => (
      <ApolloReactComponents.Query<ApplicationTitleUuidQuery, ApplicationTitleUuidQueryVariables> query={ApplicationTitleUuidDocument} {...props} />
    );
    
export function useApplicationTitleUuidQuery(baseOptions: Apollo.QueryHookOptions<ApplicationTitleUuidQuery, ApplicationTitleUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationTitleUuidQuery, ApplicationTitleUuidQueryVariables>(ApplicationTitleUuidDocument, options);
      }
export function useApplicationTitleUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationTitleUuidQuery, ApplicationTitleUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationTitleUuidQuery, ApplicationTitleUuidQueryVariables>(ApplicationTitleUuidDocument, options);
        }
export type ApplicationTitleUuidQueryHookResult = ReturnType<typeof useApplicationTitleUuidQuery>;
export type ApplicationTitleUuidLazyQueryHookResult = ReturnType<typeof useApplicationTitleUuidLazyQuery>;
export type ApplicationTitleUuidQueryResult = Apollo.QueryResult<ApplicationTitleUuidQuery, ApplicationTitleUuidQueryVariables>;
export const ApplicationTypesDocument = gql`
    query applicationTypes($filter: ApplicationTypeFiltersInput, $sort: [String] = ["createdAt:ASC"]) {
  applicationTypes(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...ApplicationType
    }
  }
}
    ${ApplicationTypeFragmentDoc}
${DocumentTypeFragmentDoc}`;
export type ApplicationTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationTypesQuery, ApplicationTypesQueryVariables>, 'query'>;

    export const ApplicationTypesComponent = (props: ApplicationTypesComponentProps) => (
      <ApolloReactComponents.Query<ApplicationTypesQuery, ApplicationTypesQueryVariables> query={ApplicationTypesDocument} {...props} />
    );
    
export function useApplicationTypesQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationTypesQuery, ApplicationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationTypesQuery, ApplicationTypesQueryVariables>(ApplicationTypesDocument, options);
      }
export function useApplicationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationTypesQuery, ApplicationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationTypesQuery, ApplicationTypesQueryVariables>(ApplicationTypesDocument, options);
        }
export type ApplicationTypesQueryHookResult = ReturnType<typeof useApplicationTypesQuery>;
export type ApplicationTypesLazyQueryHookResult = ReturnType<typeof useApplicationTypesLazyQuery>;
export type ApplicationTypesQueryResult = Apollo.QueryResult<ApplicationTypesQuery, ApplicationTypesQueryVariables>;
export const ApplicationsDocument = gql`
    query applications($filter: ApplicationFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  applications(filters: $filter, sort: $sort, pagination: $pagination) {
    meta {
      ...Meta
    }
    data {
      ...Application
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}
${ApplicationFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${ApplicationNatureFragmentDoc}
${UserProfileFragmentDoc}
${ApprovalFragmentDoc}
${EmailMessageFragmentDoc}`;
export type ApplicationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsQuery, ApplicationsQueryVariables>, 'query'>;

    export const ApplicationsComponent = (props: ApplicationsComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsQuery, ApplicationsQueryVariables> query={ApplicationsDocument} {...props} />
    );
    
export function useApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, options);
      }
export function useApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsQuery, ApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsQuery, ApplicationsQueryVariables>(ApplicationsDocument, options);
        }
export type ApplicationsQueryHookResult = ReturnType<typeof useApplicationsQuery>;
export type ApplicationsLazyQueryHookResult = ReturnType<typeof useApplicationsLazyQuery>;
export type ApplicationsQueryResult = Apollo.QueryResult<ApplicationsQuery, ApplicationsQueryVariables>;
export const ApplicationsForAgentCustomerDocument = gql`
    query applicationsForAgentCustomer($filter: ApplicationFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  applications(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        company {
          data {
            id
          }
        }
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ApplicationsForAgentCustomerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsForAgentCustomerQuery, ApplicationsForAgentCustomerQueryVariables>, 'query'>;

    export const ApplicationsForAgentCustomerComponent = (props: ApplicationsForAgentCustomerComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsForAgentCustomerQuery, ApplicationsForAgentCustomerQueryVariables> query={ApplicationsForAgentCustomerDocument} {...props} />
    );
    
export function useApplicationsForAgentCustomerQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsForAgentCustomerQuery, ApplicationsForAgentCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsForAgentCustomerQuery, ApplicationsForAgentCustomerQueryVariables>(ApplicationsForAgentCustomerDocument, options);
      }
export function useApplicationsForAgentCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsForAgentCustomerQuery, ApplicationsForAgentCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsForAgentCustomerQuery, ApplicationsForAgentCustomerQueryVariables>(ApplicationsForAgentCustomerDocument, options);
        }
export type ApplicationsForAgentCustomerQueryHookResult = ReturnType<typeof useApplicationsForAgentCustomerQuery>;
export type ApplicationsForAgentCustomerLazyQueryHookResult = ReturnType<typeof useApplicationsForAgentCustomerLazyQuery>;
export type ApplicationsForAgentCustomerQueryResult = Apollo.QueryResult<ApplicationsForAgentCustomerQuery, ApplicationsForAgentCustomerQueryVariables>;
export const ApplicationsNatureDocument = gql`
    query applicationsNature($filter: ApplicationNatureFiltersInput, $sort: [String] = ["createdAt:ASC"]) {
  applicationNatures(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...ApplicationNature
    }
  }
}
    ${ApplicationNatureFragmentDoc}
${DocumentTypeFragmentDoc}`;
export type ApplicationsNatureComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsNatureQuery, ApplicationsNatureQueryVariables>, 'query'>;

    export const ApplicationsNatureComponent = (props: ApplicationsNatureComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsNatureQuery, ApplicationsNatureQueryVariables> query={ApplicationsNatureDocument} {...props} />
    );
    
export function useApplicationsNatureQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsNatureQuery, ApplicationsNatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsNatureQuery, ApplicationsNatureQueryVariables>(ApplicationsNatureDocument, options);
      }
export function useApplicationsNatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsNatureQuery, ApplicationsNatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsNatureQuery, ApplicationsNatureQueryVariables>(ApplicationsNatureDocument, options);
        }
export type ApplicationsNatureQueryHookResult = ReturnType<typeof useApplicationsNatureQuery>;
export type ApplicationsNatureLazyQueryHookResult = ReturnType<typeof useApplicationsNatureLazyQuery>;
export type ApplicationsNatureQueryResult = Apollo.QueryResult<ApplicationsNatureQuery, ApplicationsNatureQueryVariables>;
export const ApplicationsOptionsDocument = gql`
    query applicationsOptions($filter: ApplicationFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  applications(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      id
      attributes {
        title
      }
    }
  }
}
    `;
export type ApplicationsOptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsOptionsQuery, ApplicationsOptionsQueryVariables>, 'query'>;

    export const ApplicationsOptionsComponent = (props: ApplicationsOptionsComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsOptionsQuery, ApplicationsOptionsQueryVariables> query={ApplicationsOptionsDocument} {...props} />
    );
    
export function useApplicationsOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsOptionsQuery, ApplicationsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsOptionsQuery, ApplicationsOptionsQueryVariables>(ApplicationsOptionsDocument, options);
      }
export function useApplicationsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsOptionsQuery, ApplicationsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsOptionsQuery, ApplicationsOptionsQueryVariables>(ApplicationsOptionsDocument, options);
        }
export type ApplicationsOptionsQueryHookResult = ReturnType<typeof useApplicationsOptionsQuery>;
export type ApplicationsOptionsLazyQueryHookResult = ReturnType<typeof useApplicationsOptionsLazyQuery>;
export type ApplicationsOptionsQueryResult = Apollo.QueryResult<ApplicationsOptionsQuery, ApplicationsOptionsQueryVariables>;
export const ApplicationsPageDocument = gql`
    query applicationsPage($filter: ApplicationFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  applications(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        uuid
        status
        title
        createdAt
        submittedDate
        approvedDate
        progress
        approvals {
          data {
            id
            attributes {
              name
            }
          }
        }
        project {
          data {
            id
            attributes {
              owner {
                data {
                  id
                  attributes {
                    company {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        applicationType {
          data {
            id
            attributes {
              title
              slug
            }
          }
        }
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ApplicationsPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsPageQuery, ApplicationsPageQueryVariables>, 'query'>;

    export const ApplicationsPageComponent = (props: ApplicationsPageComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsPageQuery, ApplicationsPageQueryVariables> query={ApplicationsPageDocument} {...props} />
    );
    
export function useApplicationsPageQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsPageQuery, ApplicationsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsPageQuery, ApplicationsPageQueryVariables>(ApplicationsPageDocument, options);
      }
export function useApplicationsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsPageQuery, ApplicationsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsPageQuery, ApplicationsPageQueryVariables>(ApplicationsPageDocument, options);
        }
export type ApplicationsPageQueryHookResult = ReturnType<typeof useApplicationsPageQuery>;
export type ApplicationsPageLazyQueryHookResult = ReturnType<typeof useApplicationsPageLazyQuery>;
export type ApplicationsPageQueryResult = Apollo.QueryResult<ApplicationsPageQuery, ApplicationsPageQueryVariables>;
export const ApplicationsServiceProviderStatisticsDocument = gql`
    query applicationsServiceProviderStatistics($filter: ApplicationFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  applications(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      id
      attributes {
        status
        createdAt
        isGovernmentWork
        approvals {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ApplicationsServiceProviderStatisticsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsServiceProviderStatisticsQuery, ApplicationsServiceProviderStatisticsQueryVariables>, 'query'>;

    export const ApplicationsServiceProviderStatisticsComponent = (props: ApplicationsServiceProviderStatisticsComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsServiceProviderStatisticsQuery, ApplicationsServiceProviderStatisticsQueryVariables> query={ApplicationsServiceProviderStatisticsDocument} {...props} />
    );
    
export function useApplicationsServiceProviderStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsServiceProviderStatisticsQuery, ApplicationsServiceProviderStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsServiceProviderStatisticsQuery, ApplicationsServiceProviderStatisticsQueryVariables>(ApplicationsServiceProviderStatisticsDocument, options);
      }
export function useApplicationsServiceProviderStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsServiceProviderStatisticsQuery, ApplicationsServiceProviderStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsServiceProviderStatisticsQuery, ApplicationsServiceProviderStatisticsQueryVariables>(ApplicationsServiceProviderStatisticsDocument, options);
        }
export type ApplicationsServiceProviderStatisticsQueryHookResult = ReturnType<typeof useApplicationsServiceProviderStatisticsQuery>;
export type ApplicationsServiceProviderStatisticsLazyQueryHookResult = ReturnType<typeof useApplicationsServiceProviderStatisticsLazyQuery>;
export type ApplicationsServiceProviderStatisticsQueryResult = Apollo.QueryResult<ApplicationsServiceProviderStatisticsQuery, ApplicationsServiceProviderStatisticsQueryVariables>;
export const ApplicationsStatusStatisticsDocument = gql`
    query applicationsStatusStatistics($filter: ApplicationFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  applications(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      id
      attributes {
        status
        createdAt
        approvedDate
        isGovernmentWork
        project {
          data {
            id
            attributes {
              applicant {
                data {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ApplicationsStatusStatisticsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsStatusStatisticsQuery, ApplicationsStatusStatisticsQueryVariables>, 'query'>;

    export const ApplicationsStatusStatisticsComponent = (props: ApplicationsStatusStatisticsComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsStatusStatisticsQuery, ApplicationsStatusStatisticsQueryVariables> query={ApplicationsStatusStatisticsDocument} {...props} />
    );
    
export function useApplicationsStatusStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsStatusStatisticsQuery, ApplicationsStatusStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsStatusStatisticsQuery, ApplicationsStatusStatisticsQueryVariables>(ApplicationsStatusStatisticsDocument, options);
      }
export function useApplicationsStatusStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsStatusStatisticsQuery, ApplicationsStatusStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsStatusStatisticsQuery, ApplicationsStatusStatisticsQueryVariables>(ApplicationsStatusStatisticsDocument, options);
        }
export type ApplicationsStatusStatisticsQueryHookResult = ReturnType<typeof useApplicationsStatusStatisticsQuery>;
export type ApplicationsStatusStatisticsLazyQueryHookResult = ReturnType<typeof useApplicationsStatusStatisticsLazyQuery>;
export type ApplicationsStatusStatisticsQueryResult = Apollo.QueryResult<ApplicationsStatusStatisticsQuery, ApplicationsStatusStatisticsQueryVariables>;
export const ApplicationsTableDocument = gql`
    query applicationsTable($filter: ApplicationFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  applications(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...ApplicationTable
    }
    meta {
      ...Meta
    }
  }
}
    ${ApplicationTableFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ApplicationsTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsTableQuery, ApplicationsTableQueryVariables>, 'query'>;

    export const ApplicationsTableComponent = (props: ApplicationsTableComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsTableQuery, ApplicationsTableQueryVariables> query={ApplicationsTableDocument} {...props} />
    );
    
export function useApplicationsTableQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsTableQuery, ApplicationsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsTableQuery, ApplicationsTableQueryVariables>(ApplicationsTableDocument, options);
      }
export function useApplicationsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsTableQuery, ApplicationsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsTableQuery, ApplicationsTableQueryVariables>(ApplicationsTableDocument, options);
        }
export type ApplicationsTableQueryHookResult = ReturnType<typeof useApplicationsTableQuery>;
export type ApplicationsTableLazyQueryHookResult = ReturnType<typeof useApplicationsTableLazyQuery>;
export type ApplicationsTableQueryResult = Apollo.QueryResult<ApplicationsTableQuery, ApplicationsTableQueryVariables>;
export const ApplicationsTotalDocument = gql`
    query applicationsTotal($filter: ApplicationFiltersInput) {
  applications(filters: $filter) {
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ApplicationsTotalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsTotalQuery, ApplicationsTotalQueryVariables>, 'query'>;

    export const ApplicationsTotalComponent = (props: ApplicationsTotalComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsTotalQuery, ApplicationsTotalQueryVariables> query={ApplicationsTotalDocument} {...props} />
    );
    
export function useApplicationsTotalQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsTotalQuery, ApplicationsTotalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsTotalQuery, ApplicationsTotalQueryVariables>(ApplicationsTotalDocument, options);
      }
export function useApplicationsTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsTotalQuery, ApplicationsTotalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsTotalQuery, ApplicationsTotalQueryVariables>(ApplicationsTotalDocument, options);
        }
export type ApplicationsTotalQueryHookResult = ReturnType<typeof useApplicationsTotalQuery>;
export type ApplicationsTotalLazyQueryHookResult = ReturnType<typeof useApplicationsTotalLazyQuery>;
export type ApplicationsTotalQueryResult = Apollo.QueryResult<ApplicationsTotalQuery, ApplicationsTotalQueryVariables>;
export const ApplicationsTotalStatisticsDocument = gql`
    query applicationsTotalStatistics($filter: ApplicationFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  applications(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      id
      attributes {
        status
        createdAt
        project {
          data {
            attributes {
              start
              end
            }
          }
        }
      }
    }
  }
}
    `;
export type ApplicationsTotalStatisticsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApplicationsTotalStatisticsQuery, ApplicationsTotalStatisticsQueryVariables>, 'query'>;

    export const ApplicationsTotalStatisticsComponent = (props: ApplicationsTotalStatisticsComponentProps) => (
      <ApolloReactComponents.Query<ApplicationsTotalStatisticsQuery, ApplicationsTotalStatisticsQueryVariables> query={ApplicationsTotalStatisticsDocument} {...props} />
    );
    
export function useApplicationsTotalStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<ApplicationsTotalStatisticsQuery, ApplicationsTotalStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicationsTotalStatisticsQuery, ApplicationsTotalStatisticsQueryVariables>(ApplicationsTotalStatisticsDocument, options);
      }
export function useApplicationsTotalStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicationsTotalStatisticsQuery, ApplicationsTotalStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicationsTotalStatisticsQuery, ApplicationsTotalStatisticsQueryVariables>(ApplicationsTotalStatisticsDocument, options);
        }
export type ApplicationsTotalStatisticsQueryHookResult = ReturnType<typeof useApplicationsTotalStatisticsQuery>;
export type ApplicationsTotalStatisticsLazyQueryHookResult = ReturnType<typeof useApplicationsTotalStatisticsLazyQuery>;
export type ApplicationsTotalStatisticsQueryResult = Apollo.QueryResult<ApplicationsTotalStatisticsQuery, ApplicationsTotalStatisticsQueryVariables>;
export const ApprovalDocument = gql`
    query approval($id: ID!) {
  approval(id: $id) {
    data {
      ...Approval
    }
  }
}
    ${ApprovalFragmentDoc}
${EmailMessageFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${UserProfileFragmentDoc}`;
export type ApprovalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApprovalQuery, ApprovalQueryVariables>, 'query'> & ({ variables: ApprovalQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ApprovalComponent = (props: ApprovalComponentProps) => (
      <ApolloReactComponents.Query<ApprovalQuery, ApprovalQueryVariables> query={ApprovalDocument} {...props} />
    );
    
export function useApprovalQuery(baseOptions: Apollo.QueryHookOptions<ApprovalQuery, ApprovalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalQuery, ApprovalQueryVariables>(ApprovalDocument, options);
      }
export function useApprovalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalQuery, ApprovalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalQuery, ApprovalQueryVariables>(ApprovalDocument, options);
        }
export type ApprovalQueryHookResult = ReturnType<typeof useApprovalQuery>;
export type ApprovalLazyQueryHookResult = ReturnType<typeof useApprovalLazyQuery>;
export type ApprovalQueryResult = Apollo.QueryResult<ApprovalQuery, ApprovalQueryVariables>;
export const ApprovalsDocument = gql`
    query approvals($filter: ApprovalFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  approvals(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...Approval
    }
    meta {
      ...Meta
    }
  }
}
    ${ApprovalFragmentDoc}
${EmailMessageFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${UserProfileFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ApprovalsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ApprovalsQuery, ApprovalsQueryVariables>, 'query'>;

    export const ApprovalsComponent = (props: ApprovalsComponentProps) => (
      <ApolloReactComponents.Query<ApprovalsQuery, ApprovalsQueryVariables> query={ApprovalsDocument} {...props} />
    );
    
export function useApprovalsQuery(baseOptions?: Apollo.QueryHookOptions<ApprovalsQuery, ApprovalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalsQuery, ApprovalsQueryVariables>(ApprovalsDocument, options);
      }
export function useApprovalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalsQuery, ApprovalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalsQuery, ApprovalsQueryVariables>(ApprovalsDocument, options);
        }
export type ApprovalsQueryHookResult = ReturnType<typeof useApprovalsQuery>;
export type ApprovalsLazyQueryHookResult = ReturnType<typeof useApprovalsLazyQuery>;
export type ApprovalsQueryResult = Apollo.QueryResult<ApprovalsQuery, ApprovalsQueryVariables>;
export const AreaDocument = gql`
    query area($id: ID!) {
  area(id: $id) {
    data {
      ...Area
    }
  }
}
    ${AreaFragmentDoc}
${FileFragmentDoc}`;
export type AreaComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AreaQuery, AreaQueryVariables>, 'query'> & ({ variables: AreaQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AreaComponent = (props: AreaComponentProps) => (
      <ApolloReactComponents.Query<AreaQuery, AreaQueryVariables> query={AreaDocument} {...props} />
    );
    
export function useAreaQuery(baseOptions: Apollo.QueryHookOptions<AreaQuery, AreaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AreaQuery, AreaQueryVariables>(AreaDocument, options);
      }
export function useAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreaQuery, AreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AreaQuery, AreaQueryVariables>(AreaDocument, options);
        }
export type AreaQueryHookResult = ReturnType<typeof useAreaQuery>;
export type AreaLazyQueryHookResult = ReturnType<typeof useAreaLazyQuery>;
export type AreaQueryResult = Apollo.QueryResult<AreaQuery, AreaQueryVariables>;
export const AreasDocument = gql`
    query areas($filter: AreaFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  areas(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...Area
    }
    meta {
      ...Meta
    }
  }
}
    ${AreaFragmentDoc}
${FileFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type AreasComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AreasQuery, AreasQueryVariables>, 'query'>;

    export const AreasComponent = (props: AreasComponentProps) => (
      <ApolloReactComponents.Query<AreasQuery, AreasQueryVariables> query={AreasDocument} {...props} />
    );
    
export function useAreasQuery(baseOptions?: Apollo.QueryHookOptions<AreasQuery, AreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AreasQuery, AreasQueryVariables>(AreasDocument, options);
      }
export function useAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreasQuery, AreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AreasQuery, AreasQueryVariables>(AreasDocument, options);
        }
export type AreasQueryHookResult = ReturnType<typeof useAreasQuery>;
export type AreasLazyQueryHookResult = ReturnType<typeof useAreasLazyQuery>;
export type AreasQueryResult = Apollo.QueryResult<AreasQuery, AreasQueryVariables>;
export const AreasWithCompanyDocument = gql`
    query areasWithCompany($filter: AreaFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  areas(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...AreaWithCompany
    }
  }
}
    ${AreaWithCompanyFragmentDoc}
${FileFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}`;
export type AreasWithCompanyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AreasWithCompanyQuery, AreasWithCompanyQueryVariables>, 'query'>;

    export const AreasWithCompanyComponent = (props: AreasWithCompanyComponentProps) => (
      <ApolloReactComponents.Query<AreasWithCompanyQuery, AreasWithCompanyQueryVariables> query={AreasWithCompanyDocument} {...props} />
    );
    
export function useAreasWithCompanyQuery(baseOptions?: Apollo.QueryHookOptions<AreasWithCompanyQuery, AreasWithCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AreasWithCompanyQuery, AreasWithCompanyQueryVariables>(AreasWithCompanyDocument, options);
      }
export function useAreasWithCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AreasWithCompanyQuery, AreasWithCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AreasWithCompanyQuery, AreasWithCompanyQueryVariables>(AreasWithCompanyDocument, options);
        }
export type AreasWithCompanyQueryHookResult = ReturnType<typeof useAreasWithCompanyQuery>;
export type AreasWithCompanyLazyQueryHookResult = ReturnType<typeof useAreasWithCompanyLazyQuery>;
export type AreasWithCompanyQueryResult = Apollo.QueryResult<AreasWithCompanyQuery, AreasWithCompanyQueryVariables>;
export const ClientApplicationsDocument = gql`
    query clientApplications($filter: ApplicationFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  applications(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        project {
          data {
            id
            attributes {
              owner {
                data {
                  id
                  attributes {
                    fullName
                    primaryEmail
                  }
                }
              }
            }
          }
        }
        uuid
        status
        title
        createdAt
        submittedDate
        applicationType {
          data {
            id
            attributes {
              title
            }
          }
        }
        company {
          data {
            id
            attributes {
              name
            }
          }
        }
        progress
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ClientApplicationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ClientApplicationsQuery, ClientApplicationsQueryVariables>, 'query'>;

    export const ClientApplicationsComponent = (props: ClientApplicationsComponentProps) => (
      <ApolloReactComponents.Query<ClientApplicationsQuery, ClientApplicationsQueryVariables> query={ClientApplicationsDocument} {...props} />
    );
    
export function useClientApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<ClientApplicationsQuery, ClientApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientApplicationsQuery, ClientApplicationsQueryVariables>(ClientApplicationsDocument, options);
      }
export function useClientApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientApplicationsQuery, ClientApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientApplicationsQuery, ClientApplicationsQueryVariables>(ClientApplicationsDocument, options);
        }
export type ClientApplicationsQueryHookResult = ReturnType<typeof useClientApplicationsQuery>;
export type ClientApplicationsLazyQueryHookResult = ReturnType<typeof useClientApplicationsLazyQuery>;
export type ClientApplicationsQueryResult = Apollo.QueryResult<ClientApplicationsQuery, ClientApplicationsQueryVariables>;
export const CompaniesDocument = gql`
    query companies($filter: CompanyFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  companies(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...Company
    }
    meta {
      ...Meta
    }
  }
}
    ${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${FileFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type CompaniesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompaniesQuery, CompaniesQueryVariables>, 'query'>;

    export const CompaniesComponent = (props: CompaniesComponentProps) => (
      <ApolloReactComponents.Query<CompaniesQuery, CompaniesQueryVariables> query={CompaniesDocument} {...props} />
    );
    
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyNamesDocument = gql`
    query companyNames($filter: CompanyFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  companies(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...CompanyInfo
    }
    meta {
      ...Meta
    }
  }
}
    ${CompanyInfoFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type CompanyNamesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompanyNamesQuery, CompanyNamesQueryVariables>, 'query'>;

    export const CompanyNamesComponent = (props: CompanyNamesComponentProps) => (
      <ApolloReactComponents.Query<CompanyNamesQuery, CompanyNamesQueryVariables> query={CompanyNamesDocument} {...props} />
    );
    
export function useCompanyNamesQuery(baseOptions?: Apollo.QueryHookOptions<CompanyNamesQuery, CompanyNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyNamesQuery, CompanyNamesQueryVariables>(CompanyNamesDocument, options);
      }
export function useCompanyNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyNamesQuery, CompanyNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyNamesQuery, CompanyNamesQueryVariables>(CompanyNamesDocument, options);
        }
export type CompanyNamesQueryHookResult = ReturnType<typeof useCompanyNamesQuery>;
export type CompanyNamesLazyQueryHookResult = ReturnType<typeof useCompanyNamesLazyQuery>;
export type CompanyNamesQueryResult = Apollo.QueryResult<CompanyNamesQuery, CompanyNamesQueryVariables>;
export const CompaniesWithEmployeesDocument = gql`
    query companiesWithEmployees($filter: CompanyFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  companies(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      id
      attributes {
        name
        companyType
        userProfiles {
          data {
            ...UserProfile
          }
        }
      }
    }
  }
}
    ${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type CompaniesWithEmployeesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompaniesWithEmployeesQuery, CompaniesWithEmployeesQueryVariables>, 'query'>;

    export const CompaniesWithEmployeesComponent = (props: CompaniesWithEmployeesComponentProps) => (
      <ApolloReactComponents.Query<CompaniesWithEmployeesQuery, CompaniesWithEmployeesQueryVariables> query={CompaniesWithEmployeesDocument} {...props} />
    );
    
export function useCompaniesWithEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesWithEmployeesQuery, CompaniesWithEmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesWithEmployeesQuery, CompaniesWithEmployeesQueryVariables>(CompaniesWithEmployeesDocument, options);
      }
export function useCompaniesWithEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesWithEmployeesQuery, CompaniesWithEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesWithEmployeesQuery, CompaniesWithEmployeesQueryVariables>(CompaniesWithEmployeesDocument, options);
        }
export type CompaniesWithEmployeesQueryHookResult = ReturnType<typeof useCompaniesWithEmployeesQuery>;
export type CompaniesWithEmployeesLazyQueryHookResult = ReturnType<typeof useCompaniesWithEmployeesLazyQuery>;
export type CompaniesWithEmployeesQueryResult = Apollo.QueryResult<CompaniesWithEmployeesQuery, CompaniesWithEmployeesQueryVariables>;
export const CompanyDocument = gql`
    query company($id: ID!) {
  company(id: $id) {
    data {
      ...Company
    }
  }
}
    ${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${FileFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type CompanyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompanyQuery, CompanyQueryVariables>, 'query'> & ({ variables: CompanyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompanyComponent = (props: CompanyComponentProps) => (
      <ApolloReactComponents.Query<CompanyQuery, CompanyQueryVariables> query={CompanyDocument} {...props} />
    );
    
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const CompletionCertificateDocument = gql`
    query completionCertificate($id: ID!) {
  completionCertificate(id: $id) {
    data {
      ...CompletionCertificate
    }
  }
}
    ${CompletionCertificateFragmentDoc}
${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type CompletionCertificateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompletionCertificateQuery, CompletionCertificateQueryVariables>, 'query'> & ({ variables: CompletionCertificateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CompletionCertificateComponent = (props: CompletionCertificateComponentProps) => (
      <ApolloReactComponents.Query<CompletionCertificateQuery, CompletionCertificateQueryVariables> query={CompletionCertificateDocument} {...props} />
    );
    
export function useCompletionCertificateQuery(baseOptions: Apollo.QueryHookOptions<CompletionCertificateQuery, CompletionCertificateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompletionCertificateQuery, CompletionCertificateQueryVariables>(CompletionCertificateDocument, options);
      }
export function useCompletionCertificateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletionCertificateQuery, CompletionCertificateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompletionCertificateQuery, CompletionCertificateQueryVariables>(CompletionCertificateDocument, options);
        }
export type CompletionCertificateQueryHookResult = ReturnType<typeof useCompletionCertificateQuery>;
export type CompletionCertificateLazyQueryHookResult = ReturnType<typeof useCompletionCertificateLazyQuery>;
export type CompletionCertificateQueryResult = Apollo.QueryResult<CompletionCertificateQuery, CompletionCertificateQueryVariables>;
export const CompletionCertificatesDocument = gql`
    query completionCertificates($filter: CompletionCertificateFiltersInput, $pagination: PaginationArg, $sort: [String] = ["createdAt:DESC"]) {
  completionCertificates(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...CompletionCertificate
    }
    meta {
      ...Meta
    }
  }
}
    ${CompletionCertificateFragmentDoc}
${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type CompletionCertificatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CompletionCertificatesQuery, CompletionCertificatesQueryVariables>, 'query'>;

    export const CompletionCertificatesComponent = (props: CompletionCertificatesComponentProps) => (
      <ApolloReactComponents.Query<CompletionCertificatesQuery, CompletionCertificatesQueryVariables> query={CompletionCertificatesDocument} {...props} />
    );
    
export function useCompletionCertificatesQuery(baseOptions?: Apollo.QueryHookOptions<CompletionCertificatesQuery, CompletionCertificatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompletionCertificatesQuery, CompletionCertificatesQueryVariables>(CompletionCertificatesDocument, options);
      }
export function useCompletionCertificatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletionCertificatesQuery, CompletionCertificatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompletionCertificatesQuery, CompletionCertificatesQueryVariables>(CompletionCertificatesDocument, options);
        }
export type CompletionCertificatesQueryHookResult = ReturnType<typeof useCompletionCertificatesQuery>;
export type CompletionCertificatesLazyQueryHookResult = ReturnType<typeof useCompletionCertificatesLazyQuery>;
export type CompletionCertificatesQueryResult = Apollo.QueryResult<CompletionCertificatesQuery, CompletionCertificatesQueryVariables>;
export const DefaultAgentDocument = gql`
    query defaultAgent($filter: UsersPermissionsUserFiltersInput) {
  usersPermissionsUsers(filters: $filter, pagination: {limit: -1}) {
    data {
      id
      attributes {
        uuid
        email
      }
    }
  }
}
    `;
export type DefaultAgentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DefaultAgentQuery, DefaultAgentQueryVariables>, 'query'>;

    export const DefaultAgentComponent = (props: DefaultAgentComponentProps) => (
      <ApolloReactComponents.Query<DefaultAgentQuery, DefaultAgentQueryVariables> query={DefaultAgentDocument} {...props} />
    );
    
export function useDefaultAgentQuery(baseOptions?: Apollo.QueryHookOptions<DefaultAgentQuery, DefaultAgentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DefaultAgentQuery, DefaultAgentQueryVariables>(DefaultAgentDocument, options);
      }
export function useDefaultAgentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DefaultAgentQuery, DefaultAgentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DefaultAgentQuery, DefaultAgentQueryVariables>(DefaultAgentDocument, options);
        }
export type DefaultAgentQueryHookResult = ReturnType<typeof useDefaultAgentQuery>;
export type DefaultAgentLazyQueryHookResult = ReturnType<typeof useDefaultAgentLazyQuery>;
export type DefaultAgentQueryResult = Apollo.QueryResult<DefaultAgentQuery, DefaultAgentQueryVariables>;
export const DiscussionTopicsDocument = gql`
    query discussionTopics($filter: DiscussionTopicFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  discussionTopics(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...DiscussionTopic
    }
  }
}
    ${DiscussionTopicFragmentDoc}`;
export type DiscussionTopicsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DiscussionTopicsQuery, DiscussionTopicsQueryVariables>, 'query'>;

    export const DiscussionTopicsComponent = (props: DiscussionTopicsComponentProps) => (
      <ApolloReactComponents.Query<DiscussionTopicsQuery, DiscussionTopicsQueryVariables> query={DiscussionTopicsDocument} {...props} />
    );
    
export function useDiscussionTopicsQuery(baseOptions?: Apollo.QueryHookOptions<DiscussionTopicsQuery, DiscussionTopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscussionTopicsQuery, DiscussionTopicsQueryVariables>(DiscussionTopicsDocument, options);
      }
export function useDiscussionTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscussionTopicsQuery, DiscussionTopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscussionTopicsQuery, DiscussionTopicsQueryVariables>(DiscussionTopicsDocument, options);
        }
export type DiscussionTopicsQueryHookResult = ReturnType<typeof useDiscussionTopicsQuery>;
export type DiscussionTopicsLazyQueryHookResult = ReturnType<typeof useDiscussionTopicsLazyQuery>;
export type DiscussionTopicsQueryResult = Apollo.QueryResult<DiscussionTopicsQuery, DiscussionTopicsQueryVariables>;
export const DocumentTypesDocument = gql`
    query documentTypes($filter: DocumentTypeFiltersInput, $sort: [String] = ["createdAt:ASC"]) {
  documentTypes(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...DocumentType
    }
  }
}
    ${DocumentTypeFragmentDoc}`;
export type DocumentTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DocumentTypesQuery, DocumentTypesQueryVariables>, 'query'>;

    export const DocumentTypesComponent = (props: DocumentTypesComponentProps) => (
      <ApolloReactComponents.Query<DocumentTypesQuery, DocumentTypesQueryVariables> query={DocumentTypesDocument} {...props} />
    );
    
export function useDocumentTypesQuery(baseOptions?: Apollo.QueryHookOptions<DocumentTypesQuery, DocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentTypesQuery, DocumentTypesQueryVariables>(DocumentTypesDocument, options);
      }
export function useDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentTypesQuery, DocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentTypesQuery, DocumentTypesQueryVariables>(DocumentTypesDocument, options);
        }
export type DocumentTypesQueryHookResult = ReturnType<typeof useDocumentTypesQuery>;
export type DocumentTypesLazyQueryHookResult = ReturnType<typeof useDocumentTypesLazyQuery>;
export type DocumentTypesQueryResult = Apollo.QueryResult<DocumentTypesQuery, DocumentTypesQueryVariables>;
export const EmailMessageDocument = gql`
    query emailMessage($id: ID!) {
  emailMessage(id: $id) {
    data {
      ...EmailMessage
    }
  }
}
    ${EmailMessageFragmentDoc}
${FileFragmentDoc}`;
export type EmailMessageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EmailMessageQuery, EmailMessageQueryVariables>, 'query'> & ({ variables: EmailMessageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EmailMessageComponent = (props: EmailMessageComponentProps) => (
      <ApolloReactComponents.Query<EmailMessageQuery, EmailMessageQueryVariables> query={EmailMessageDocument} {...props} />
    );
    
export function useEmailMessageQuery(baseOptions: Apollo.QueryHookOptions<EmailMessageQuery, EmailMessageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailMessageQuery, EmailMessageQueryVariables>(EmailMessageDocument, options);
      }
export function useEmailMessageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailMessageQuery, EmailMessageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailMessageQuery, EmailMessageQueryVariables>(EmailMessageDocument, options);
        }
export type EmailMessageQueryHookResult = ReturnType<typeof useEmailMessageQuery>;
export type EmailMessageLazyQueryHookResult = ReturnType<typeof useEmailMessageLazyQuery>;
export type EmailMessageQueryResult = Apollo.QueryResult<EmailMessageQuery, EmailMessageQueryVariables>;
export const GetProjectIdByUidDocument = gql`
    query getProjectIdByUid($filter: ProjectFiltersInput) {
  projects(filters: $filter) {
    data {
      id
    }
  }
}
    `;
export type GetProjectIdByUidComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectIdByUidQuery, GetProjectIdByUidQueryVariables>, 'query'>;

    export const GetProjectIdByUidComponent = (props: GetProjectIdByUidComponentProps) => (
      <ApolloReactComponents.Query<GetProjectIdByUidQuery, GetProjectIdByUidQueryVariables> query={GetProjectIdByUidDocument} {...props} />
    );
    
export function useGetProjectIdByUidQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectIdByUidQuery, GetProjectIdByUidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectIdByUidQuery, GetProjectIdByUidQueryVariables>(GetProjectIdByUidDocument, options);
      }
export function useGetProjectIdByUidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectIdByUidQuery, GetProjectIdByUidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectIdByUidQuery, GetProjectIdByUidQueryVariables>(GetProjectIdByUidDocument, options);
        }
export type GetProjectIdByUidQueryHookResult = ReturnType<typeof useGetProjectIdByUidQuery>;
export type GetProjectIdByUidLazyQueryHookResult = ReturnType<typeof useGetProjectIdByUidLazyQuery>;
export type GetProjectIdByUidQueryResult = Apollo.QueryResult<GetProjectIdByUidQuery, GetProjectIdByUidQueryVariables>;
export const InviteRequiredDocTypesDocument = gql`
    query inviteRequiredDocTypes($filter: InviteRequiredDocumentTypeFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  inviteRequiredDocumentTypes(
    filters: $filter
    sort: $sort
    pagination: {limit: -1}
  ) {
    data {
      ...InviteRequiredDocumentType
    }
  }
}
    ${InviteRequiredDocumentTypeFragmentDoc}`;
export type InviteRequiredDocTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InviteRequiredDocTypesQuery, InviteRequiredDocTypesQueryVariables>, 'query'>;

    export const InviteRequiredDocTypesComponent = (props: InviteRequiredDocTypesComponentProps) => (
      <ApolloReactComponents.Query<InviteRequiredDocTypesQuery, InviteRequiredDocTypesQueryVariables> query={InviteRequiredDocTypesDocument} {...props} />
    );
    
export function useInviteRequiredDocTypesQuery(baseOptions?: Apollo.QueryHookOptions<InviteRequiredDocTypesQuery, InviteRequiredDocTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InviteRequiredDocTypesQuery, InviteRequiredDocTypesQueryVariables>(InviteRequiredDocTypesDocument, options);
      }
export function useInviteRequiredDocTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InviteRequiredDocTypesQuery, InviteRequiredDocTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InviteRequiredDocTypesQuery, InviteRequiredDocTypesQueryVariables>(InviteRequiredDocTypesDocument, options);
        }
export type InviteRequiredDocTypesQueryHookResult = ReturnType<typeof useInviteRequiredDocTypesQuery>;
export type InviteRequiredDocTypesLazyQueryHookResult = ReturnType<typeof useInviteRequiredDocTypesLazyQuery>;
export type InviteRequiredDocTypesQueryResult = Apollo.QueryResult<InviteRequiredDocTypesQuery, InviteRequiredDocTypesQueryVariables>;
export const InvoiceDocument = gql`
    query invoice($id: ID!) {
  invoice(id: $id) {
    data {
      ...Invoice
    }
  }
}
    ${InvoiceFragmentDoc}
${FileFragmentDoc}`;
export type InvoiceComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InvoiceQuery, InvoiceQueryVariables>, 'query'> & ({ variables: InvoiceQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InvoiceComponent = (props: InvoiceComponentProps) => (
      <ApolloReactComponents.Query<InvoiceQuery, InvoiceQueryVariables> query={InvoiceDocument} {...props} />
    );
    
export function useInvoiceQuery(baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
      }
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
        }
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const KickoffInviteDocument = gql`
    query kickoffInvite($id: ID!) {
  kickoffInvite(id: $id) {
    data {
      ...KickoffInvite
    }
  }
}
    ${KickoffInviteFragmentDoc}
${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type KickoffInviteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KickoffInviteQuery, KickoffInviteQueryVariables>, 'query'> & ({ variables: KickoffInviteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KickoffInviteComponent = (props: KickoffInviteComponentProps) => (
      <ApolloReactComponents.Query<KickoffInviteQuery, KickoffInviteQueryVariables> query={KickoffInviteDocument} {...props} />
    );
    
export function useKickoffInviteQuery(baseOptions: Apollo.QueryHookOptions<KickoffInviteQuery, KickoffInviteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KickoffInviteQuery, KickoffInviteQueryVariables>(KickoffInviteDocument, options);
      }
export function useKickoffInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KickoffInviteQuery, KickoffInviteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KickoffInviteQuery, KickoffInviteQueryVariables>(KickoffInviteDocument, options);
        }
export type KickoffInviteQueryHookResult = ReturnType<typeof useKickoffInviteQuery>;
export type KickoffInviteLazyQueryHookResult = ReturnType<typeof useKickoffInviteLazyQuery>;
export type KickoffInviteQueryResult = Apollo.QueryResult<KickoffInviteQuery, KickoffInviteQueryVariables>;
export const KickoffInvitesDocument = gql`
    query kickoffInvites($filter: KickoffInviteFiltersInput, $pagination: PaginationArg, $sort: [String] = ["createdAt:DESC"]) {
  kickoffInvites(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...KickoffInvite
    }
    meta {
      ...Meta
    }
  }
}
    ${KickoffInviteFragmentDoc}
${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type KickoffInvitesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KickoffInvitesQuery, KickoffInvitesQueryVariables>, 'query'>;

    export const KickoffInvitesComponent = (props: KickoffInvitesComponentProps) => (
      <ApolloReactComponents.Query<KickoffInvitesQuery, KickoffInvitesQueryVariables> query={KickoffInvitesDocument} {...props} />
    );
    
export function useKickoffInvitesQuery(baseOptions?: Apollo.QueryHookOptions<KickoffInvitesQuery, KickoffInvitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KickoffInvitesQuery, KickoffInvitesQueryVariables>(KickoffInvitesDocument, options);
      }
export function useKickoffInvitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KickoffInvitesQuery, KickoffInvitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KickoffInvitesQuery, KickoffInvitesQueryVariables>(KickoffInvitesDocument, options);
        }
export type KickoffInvitesQueryHookResult = ReturnType<typeof useKickoffInvitesQuery>;
export type KickoffInvitesLazyQueryHookResult = ReturnType<typeof useKickoffInvitesLazyQuery>;
export type KickoffInvitesQueryResult = Apollo.QueryResult<KickoffInvitesQuery, KickoffInvitesQueryVariables>;
export const KickoffRecordDocument = gql`
    query kickoffRecord($id: ID!) {
  kickoffRecord(id: $id) {
    data {
      ...KickoffRecord
    }
  }
}
    ${KickoffRecordFragmentDoc}
${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type KickoffRecordComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KickoffRecordQuery, KickoffRecordQueryVariables>, 'query'> & ({ variables: KickoffRecordQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const KickoffRecordComponent = (props: KickoffRecordComponentProps) => (
      <ApolloReactComponents.Query<KickoffRecordQuery, KickoffRecordQueryVariables> query={KickoffRecordDocument} {...props} />
    );
    
export function useKickoffRecordQuery(baseOptions: Apollo.QueryHookOptions<KickoffRecordQuery, KickoffRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KickoffRecordQuery, KickoffRecordQueryVariables>(KickoffRecordDocument, options);
      }
export function useKickoffRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KickoffRecordQuery, KickoffRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KickoffRecordQuery, KickoffRecordQueryVariables>(KickoffRecordDocument, options);
        }
export type KickoffRecordQueryHookResult = ReturnType<typeof useKickoffRecordQuery>;
export type KickoffRecordLazyQueryHookResult = ReturnType<typeof useKickoffRecordLazyQuery>;
export type KickoffRecordQueryResult = Apollo.QueryResult<KickoffRecordQuery, KickoffRecordQueryVariables>;
export const KickoffRecordsDocument = gql`
    query kickoffRecords($filter: KickoffRecordFiltersInput, $pagination: PaginationArg, $sort: [String] = ["createdAt:DESC"]) {
  kickoffRecords(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...KickoffRecord
    }
    meta {
      ...Meta
    }
  }
}
    ${KickoffRecordFragmentDoc}
${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type KickoffRecordsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<KickoffRecordsQuery, KickoffRecordsQueryVariables>, 'query'>;

    export const KickoffRecordsComponent = (props: KickoffRecordsComponentProps) => (
      <ApolloReactComponents.Query<KickoffRecordsQuery, KickoffRecordsQueryVariables> query={KickoffRecordsDocument} {...props} />
    );
    
export function useKickoffRecordsQuery(baseOptions?: Apollo.QueryHookOptions<KickoffRecordsQuery, KickoffRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KickoffRecordsQuery, KickoffRecordsQueryVariables>(KickoffRecordsDocument, options);
      }
export function useKickoffRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KickoffRecordsQuery, KickoffRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KickoffRecordsQuery, KickoffRecordsQueryVariables>(KickoffRecordsDocument, options);
        }
export type KickoffRecordsQueryHookResult = ReturnType<typeof useKickoffRecordsQuery>;
export type KickoffRecordsLazyQueryHookResult = ReturnType<typeof useKickoffRecordsLazyQuery>;
export type KickoffRecordsQueryResult = Apollo.QueryResult<KickoffRecordsQuery, KickoffRecordsQueryVariables>;
export const LetterTemplateDocument = gql`
    query letterTemplate($id: ID!) {
  letterTemplate(id: $id) {
    data {
      ...LetterTemplate
    }
  }
}
    ${LetterTemplateFragmentDoc}
${FileFragmentDoc}`;
export type LetterTemplateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LetterTemplateQuery, LetterTemplateQueryVariables>, 'query'> & ({ variables: LetterTemplateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LetterTemplateComponent = (props: LetterTemplateComponentProps) => (
      <ApolloReactComponents.Query<LetterTemplateQuery, LetterTemplateQueryVariables> query={LetterTemplateDocument} {...props} />
    );
    
export function useLetterTemplateQuery(baseOptions: Apollo.QueryHookOptions<LetterTemplateQuery, LetterTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LetterTemplateQuery, LetterTemplateQueryVariables>(LetterTemplateDocument, options);
      }
export function useLetterTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LetterTemplateQuery, LetterTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LetterTemplateQuery, LetterTemplateQueryVariables>(LetterTemplateDocument, options);
        }
export type LetterTemplateQueryHookResult = ReturnType<typeof useLetterTemplateQuery>;
export type LetterTemplateLazyQueryHookResult = ReturnType<typeof useLetterTemplateLazyQuery>;
export type LetterTemplateQueryResult = Apollo.QueryResult<LetterTemplateQuery, LetterTemplateQueryVariables>;
export const MapApplicationDocument = gql`
    query mapApplication($id: ID!) {
  application(id: $id) {
    data {
      id
      attributes {
        project {
          data {
            attributes {
              geoJson {
                data {
                  ...File
                }
              }
            }
          }
        }
        title
        description
        uuid
      }
    }
  }
}
    ${FileFragmentDoc}`;
export type MapApplicationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MapApplicationQuery, MapApplicationQueryVariables>, 'query'> & ({ variables: MapApplicationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MapApplicationComponent = (props: MapApplicationComponentProps) => (
      <ApolloReactComponents.Query<MapApplicationQuery, MapApplicationQueryVariables> query={MapApplicationDocument} {...props} />
    );
    
export function useMapApplicationQuery(baseOptions: Apollo.QueryHookOptions<MapApplicationQuery, MapApplicationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MapApplicationQuery, MapApplicationQueryVariables>(MapApplicationDocument, options);
      }
export function useMapApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MapApplicationQuery, MapApplicationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MapApplicationQuery, MapApplicationQueryVariables>(MapApplicationDocument, options);
        }
export type MapApplicationQueryHookResult = ReturnType<typeof useMapApplicationQuery>;
export type MapApplicationLazyQueryHookResult = ReturnType<typeof useMapApplicationLazyQuery>;
export type MapApplicationQueryResult = Apollo.QueryResult<MapApplicationQuery, MapApplicationQueryVariables>;
export const MapApplicationsDocument = gql`
    query mapApplications($filter: ApplicationFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  applications(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        serviceProviders {
          data {
            id
          }
        }
        title
      }
    }
  }
}
    `;
export type MapApplicationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MapApplicationsQuery, MapApplicationsQueryVariables>, 'query'>;

    export const MapApplicationsComponent = (props: MapApplicationsComponentProps) => (
      <ApolloReactComponents.Query<MapApplicationsQuery, MapApplicationsQueryVariables> query={MapApplicationsDocument} {...props} />
    );
    
export function useMapApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<MapApplicationsQuery, MapApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MapApplicationsQuery, MapApplicationsQueryVariables>(MapApplicationsDocument, options);
      }
export function useMapApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MapApplicationsQuery, MapApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MapApplicationsQuery, MapApplicationsQueryVariables>(MapApplicationsDocument, options);
        }
export type MapApplicationsQueryHookResult = ReturnType<typeof useMapApplicationsQuery>;
export type MapApplicationsLazyQueryHookResult = ReturnType<typeof useMapApplicationsLazyQuery>;
export type MapApplicationsQueryResult = Apollo.QueryResult<MapApplicationsQuery, MapApplicationsQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    uuid
    email
    username
    role {
      data {
        id
        attributes {
          name
        }
      }
    }
    userProfile {
      data {
        ...UserProfile
      }
    }
    organization {
      data {
        id
        attributes {
          uuid
          slug
          companies {
            data {
              id
            }
          }
        }
      }
    }
  }
}
    ${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type MeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MeQuery, MeQueryVariables>, 'query'>;

    export const MeComponent = (props: MeComponentProps) => (
      <ApolloReactComponents.Query<MeQuery, MeQueryVariables> query={MeDocument} {...props} />
    );
    
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const NoteDocument = gql`
    query note($id: ID!) {
  note(id: $id) {
    data {
      ...Note
    }
  }
}
    ${NoteFragmentDoc}
${FileFragmentDoc}`;
export type NoteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NoteQuery, NoteQueryVariables>, 'query'> & ({ variables: NoteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NoteComponent = (props: NoteComponentProps) => (
      <ApolloReactComponents.Query<NoteQuery, NoteQueryVariables> query={NoteDocument} {...props} />
    );
    
export function useNoteQuery(baseOptions: Apollo.QueryHookOptions<NoteQuery, NoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NoteQuery, NoteQueryVariables>(NoteDocument, options);
      }
export function useNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteQuery, NoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NoteQuery, NoteQueryVariables>(NoteDocument, options);
        }
export type NoteQueryHookResult = ReturnType<typeof useNoteQuery>;
export type NoteLazyQueryHookResult = ReturnType<typeof useNoteLazyQuery>;
export type NoteQueryResult = Apollo.QueryResult<NoteQuery, NoteQueryVariables>;
export const NotesDocument = gql`
    query notes($filter: NoteFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  notes(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...Note
    }
  }
}
    ${NoteFragmentDoc}
${FileFragmentDoc}`;
export type NotesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NotesQuery, NotesQueryVariables>, 'query'>;

    export const NotesComponent = (props: NotesComponentProps) => (
      <ApolloReactComponents.Query<NotesQuery, NotesQueryVariables> query={NotesDocument} {...props} />
    );
    
export function useNotesQuery(baseOptions?: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
      }
export function useNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
        }
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesQueryResult = Apollo.QueryResult<NotesQuery, NotesQueryVariables>;
export const NotificationsMessagesDocument = gql`
    query notificationsMessages($filter: NotificationMessageFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  notificationMessages(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...NotificationMessage
    }
    meta {
      ...Meta
    }
  }
}
    ${NotificationMessageFragmentDoc}
${FileFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type NotificationsMessagesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NotificationsMessagesQuery, NotificationsMessagesQueryVariables>, 'query'>;

    export const NotificationsMessagesComponent = (props: NotificationsMessagesComponentProps) => (
      <ApolloReactComponents.Query<NotificationsMessagesQuery, NotificationsMessagesQueryVariables> query={NotificationsMessagesDocument} {...props} />
    );
    
export function useNotificationsMessagesQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsMessagesQuery, NotificationsMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsMessagesQuery, NotificationsMessagesQueryVariables>(NotificationsMessagesDocument, options);
      }
export function useNotificationsMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsMessagesQuery, NotificationsMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsMessagesQuery, NotificationsMessagesQueryVariables>(NotificationsMessagesDocument, options);
        }
export type NotificationsMessagesQueryHookResult = ReturnType<typeof useNotificationsMessagesQuery>;
export type NotificationsMessagesLazyQueryHookResult = ReturnType<typeof useNotificationsMessagesLazyQuery>;
export type NotificationsMessagesQueryResult = Apollo.QueryResult<NotificationsMessagesQuery, NotificationsMessagesQueryVariables>;
export const OrganizationDocument = gql`
    query organization($id: ID!) {
  organization(id: $id) {
    data {
      ...Organization
    }
  }
}
    ${OrganizationFragmentDoc}
${FileFragmentDoc}`;
export type OrganizationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationQuery, OrganizationQueryVariables>, 'query'> & ({ variables: OrganizationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationComponent = (props: OrganizationComponentProps) => (
      <ApolloReactComponents.Query<OrganizationQuery, OrganizationQueryVariables> query={OrganizationDocument} {...props} />
    );
    
export function useOrganizationQuery(baseOptions: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
      }
export function useOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, options);
        }
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const OrganizationsDocument = gql`
    query organizations($filter: OrganizationFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  organizations(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...Organization
    }
    meta {
      ...Meta
    }
  }
}
    ${OrganizationFragmentDoc}
${FileFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type OrganizationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationsQuery, OrganizationsQueryVariables>, 'query'>;

    export const OrganizationsComponent = (props: OrganizationsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationsQuery, OrganizationsQueryVariables> query={OrganizationsDocument} {...props} />
    );
    
export function useOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
      }
export function useOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, options);
        }
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;
export type OrganizationsQueryResult = Apollo.QueryResult<OrganizationsQuery, OrganizationsQueryVariables>;
export const OrganizationsIdsDocument = gql`
    query organizationsIds($filter: OrganizationFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  organizations(filters: $filter, sort: $sort) {
    data {
      id
      attributes {
        slug
      }
    }
  }
}
    `;
export type OrganizationsIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationsIdsQuery, OrganizationsIdsQueryVariables>, 'query'>;

    export const OrganizationsIdsComponent = (props: OrganizationsIdsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationsIdsQuery, OrganizationsIdsQueryVariables> query={OrganizationsIdsDocument} {...props} />
    );
    
export function useOrganizationsIdsQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationsIdsQuery, OrganizationsIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationsIdsQuery, OrganizationsIdsQueryVariables>(OrganizationsIdsDocument, options);
      }
export function useOrganizationsIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsIdsQuery, OrganizationsIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationsIdsQuery, OrganizationsIdsQueryVariables>(OrganizationsIdsDocument, options);
        }
export type OrganizationsIdsQueryHookResult = ReturnType<typeof useOrganizationsIdsQuery>;
export type OrganizationsIdsLazyQueryHookResult = ReturnType<typeof useOrganizationsIdsLazyQuery>;
export type OrganizationsIdsQueryResult = Apollo.QueryResult<OrganizationsIdsQuery, OrganizationsIdsQueryVariables>;
export const PaymentDocument = gql`
    query payment($id: ID!) {
  payment(id: $id) {
    data {
      ...Payment
    }
  }
}
    ${PaymentFragmentDoc}
${FileFragmentDoc}`;
export type PaymentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PaymentQuery, PaymentQueryVariables>, 'query'> & ({ variables: PaymentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PaymentComponent = (props: PaymentComponentProps) => (
      <ApolloReactComponents.Query<PaymentQuery, PaymentQueryVariables> query={PaymentDocument} {...props} />
    );
    
export function usePaymentQuery(baseOptions: Apollo.QueryHookOptions<PaymentQuery, PaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentQuery, PaymentQueryVariables>(PaymentDocument, options);
      }
export function usePaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentQuery, PaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentQuery, PaymentQueryVariables>(PaymentDocument, options);
        }
export type PaymentQueryHookResult = ReturnType<typeof usePaymentQuery>;
export type PaymentLazyQueryHookResult = ReturnType<typeof usePaymentLazyQuery>;
export type PaymentQueryResult = Apollo.QueryResult<PaymentQuery, PaymentQueryVariables>;
export const PaymentsDocument = gql`
    query payments($filter: PaymentFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  payments(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...Payment
    }
  }
}
    ${PaymentFragmentDoc}
${FileFragmentDoc}`;
export type PaymentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PaymentsQuery, PaymentsQueryVariables>, 'query'>;

    export const PaymentsComponent = (props: PaymentsComponentProps) => (
      <ApolloReactComponents.Query<PaymentsQuery, PaymentsQueryVariables> query={PaymentsDocument} {...props} />
    );
    
export function usePaymentsQuery(baseOptions?: Apollo.QueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, options);
      }
export function usePaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentsQuery, PaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentsQuery, PaymentsQueryVariables>(PaymentsDocument, options);
        }
export type PaymentsQueryHookResult = ReturnType<typeof usePaymentsQuery>;
export type PaymentsLazyQueryHookResult = ReturnType<typeof usePaymentsLazyQuery>;
export type PaymentsQueryResult = Apollo.QueryResult<PaymentsQuery, PaymentsQueryVariables>;
export const ProfessionalCustomersInfoDocument = gql`
    query professionalCustomersInfo($id: ID!) {
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        uuid
        email
      }
    }
  }
}
    `;
export type ProfessionalCustomersInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfessionalCustomersInfoQuery, ProfessionalCustomersInfoQueryVariables>, 'query'> & ({ variables: ProfessionalCustomersInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProfessionalCustomersInfoComponent = (props: ProfessionalCustomersInfoComponentProps) => (
      <ApolloReactComponents.Query<ProfessionalCustomersInfoQuery, ProfessionalCustomersInfoQueryVariables> query={ProfessionalCustomersInfoDocument} {...props} />
    );
    
export function useProfessionalCustomersInfoQuery(baseOptions: Apollo.QueryHookOptions<ProfessionalCustomersInfoQuery, ProfessionalCustomersInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfessionalCustomersInfoQuery, ProfessionalCustomersInfoQueryVariables>(ProfessionalCustomersInfoDocument, options);
      }
export function useProfessionalCustomersInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfessionalCustomersInfoQuery, ProfessionalCustomersInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfessionalCustomersInfoQuery, ProfessionalCustomersInfoQueryVariables>(ProfessionalCustomersInfoDocument, options);
        }
export type ProfessionalCustomersInfoQueryHookResult = ReturnType<typeof useProfessionalCustomersInfoQuery>;
export type ProfessionalCustomersInfoLazyQueryHookResult = ReturnType<typeof useProfessionalCustomersInfoLazyQuery>;
export type ProfessionalCustomersInfoQueryResult = Apollo.QueryResult<ProfessionalCustomersInfoQuery, ProfessionalCustomersInfoQueryVariables>;
export const ProjectDocument = gql`
    query project($id: ID!) {
  project(id: $id) {
    data {
      ...Project
    }
  }
}
    ${ProjectFragmentDoc}
${ProvinceFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${ApplicationNatureFragmentDoc}`;
export type ProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectQuery, ProjectQueryVariables>, 'query'> & ({ variables: ProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectComponent = (props: ProjectComponentProps) => (
      <ApolloReactComponents.Query<ProjectQuery, ProjectQueryVariables> query={ProjectDocument} {...props} />
    );
    
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
      }
export function useProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>;
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>;
export const ProjectApplicationsDocument = gql`
    query projectApplications($id: ID!, $filters: ApplicationFiltersInput, $appPagination: PaginationArg) {
  project(id: $id) {
    data {
      id
      attributes {
        title
        applications(pagination: $appPagination, filters: $filters) {
          data {
            id
            attributes {
              uuid
              duplicationIndex
              status
              title
              createdAt
              submittedDate
              progress
              applicationType {
                data {
                  id
                  attributes {
                    title
                  }
                }
              }
              agentsInfo {
                id
                name
                assignStatus
                agentProfile {
                  data {
                    id
                    attributes {
                      fullName
                      primaryEmail
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ProjectApplicationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectApplicationsQuery, ProjectApplicationsQueryVariables>, 'query'> & ({ variables: ProjectApplicationsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectApplicationsComponent = (props: ProjectApplicationsComponentProps) => (
      <ApolloReactComponents.Query<ProjectApplicationsQuery, ProjectApplicationsQueryVariables> query={ProjectApplicationsDocument} {...props} />
    );
    
export function useProjectApplicationsQuery(baseOptions: Apollo.QueryHookOptions<ProjectApplicationsQuery, ProjectApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectApplicationsQuery, ProjectApplicationsQueryVariables>(ProjectApplicationsDocument, options);
      }
export function useProjectApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectApplicationsQuery, ProjectApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectApplicationsQuery, ProjectApplicationsQueryVariables>(ProjectApplicationsDocument, options);
        }
export type ProjectApplicationsQueryHookResult = ReturnType<typeof useProjectApplicationsQuery>;
export type ProjectApplicationsLazyQueryHookResult = ReturnType<typeof useProjectApplicationsLazyQuery>;
export type ProjectApplicationsQueryResult = Apollo.QueryResult<ProjectApplicationsQuery, ProjectApplicationsQueryVariables>;
export const ProjectsDocument = gql`
    query projects($filter: ProjectFiltersInput, $pagination: PaginationArg, $sort: [String] = ["createdAt:DESC"]) {
  projects(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...Project
    }
    meta {
      ...Meta
    }
  }
}
    ${ProjectFragmentDoc}
${ProvinceFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${ApplicationNatureFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectsQuery, ProjectsQueryVariables>, 'query'>;

    export const ProjectsComponent = (props: ProjectsComponentProps) => (
      <ApolloReactComponents.Query<ProjectsQuery, ProjectsQueryVariables> query={ProjectsDocument} {...props} />
    );
    
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const ProjectsCoordinatesDocument = gql`
    query projectsCoordinates($pagination: PaginationArg, $filter: ProjectFiltersInput) {
  projects(pagination: $pagination, filters: $filter) {
    data {
      id
      attributes {
        projectCenter
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ProjectsCoordinatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectsCoordinatesQuery, ProjectsCoordinatesQueryVariables>, 'query'>;

    export const ProjectsCoordinatesComponent = (props: ProjectsCoordinatesComponentProps) => (
      <ApolloReactComponents.Query<ProjectsCoordinatesQuery, ProjectsCoordinatesQueryVariables> query={ProjectsCoordinatesDocument} {...props} />
    );
    
export function useProjectsCoordinatesQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsCoordinatesQuery, ProjectsCoordinatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsCoordinatesQuery, ProjectsCoordinatesQueryVariables>(ProjectsCoordinatesDocument, options);
      }
export function useProjectsCoordinatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsCoordinatesQuery, ProjectsCoordinatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsCoordinatesQuery, ProjectsCoordinatesQueryVariables>(ProjectsCoordinatesDocument, options);
        }
export type ProjectsCoordinatesQueryHookResult = ReturnType<typeof useProjectsCoordinatesQuery>;
export type ProjectsCoordinatesLazyQueryHookResult = ReturnType<typeof useProjectsCoordinatesLazyQuery>;
export type ProjectsCoordinatesQueryResult = Apollo.QueryResult<ProjectsCoordinatesQuery, ProjectsCoordinatesQueryVariables>;
export const ProjectsOptionsDocument = gql`
    query projectsOptions($filter: ProjectFiltersInput, $pagination: PaginationArg, $sort: [String] = ["createdAt:DESC"]) {
  projects(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        title
      }
    }
  }
}
    `;
export type ProjectsOptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectsOptionsQuery, ProjectsOptionsQueryVariables>, 'query'>;

    export const ProjectsOptionsComponent = (props: ProjectsOptionsComponentProps) => (
      <ApolloReactComponents.Query<ProjectsOptionsQuery, ProjectsOptionsQueryVariables> query={ProjectsOptionsDocument} {...props} />
    );
    
export function useProjectsOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsOptionsQuery, ProjectsOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsOptionsQuery, ProjectsOptionsQueryVariables>(ProjectsOptionsDocument, options);
      }
export function useProjectsOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsOptionsQuery, ProjectsOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsOptionsQuery, ProjectsOptionsQueryVariables>(ProjectsOptionsDocument, options);
        }
export type ProjectsOptionsQueryHookResult = ReturnType<typeof useProjectsOptionsQuery>;
export type ProjectsOptionsLazyQueryHookResult = ReturnType<typeof useProjectsOptionsLazyQuery>;
export type ProjectsOptionsQueryResult = Apollo.QueryResult<ProjectsOptionsQuery, ProjectsOptionsQueryVariables>;
export const ProjectsStatusStatisticsDocument = gql`
    query projectsStatusStatistics($filter: ProjectFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  projects(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      id
      attributes {
        applicant {
          data {
            id
          }
        }
        status
        createdAt
      }
    }
  }
}
    `;
export type ProjectsStatusStatisticsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectsStatusStatisticsQuery, ProjectsStatusStatisticsQueryVariables>, 'query'>;

    export const ProjectsStatusStatisticsComponent = (props: ProjectsStatusStatisticsComponentProps) => (
      <ApolloReactComponents.Query<ProjectsStatusStatisticsQuery, ProjectsStatusStatisticsQueryVariables> query={ProjectsStatusStatisticsDocument} {...props} />
    );
    
export function useProjectsStatusStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsStatusStatisticsQuery, ProjectsStatusStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsStatusStatisticsQuery, ProjectsStatusStatisticsQueryVariables>(ProjectsStatusStatisticsDocument, options);
      }
export function useProjectsStatusStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsStatusStatisticsQuery, ProjectsStatusStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsStatusStatisticsQuery, ProjectsStatusStatisticsQueryVariables>(ProjectsStatusStatisticsDocument, options);
        }
export type ProjectsStatusStatisticsQueryHookResult = ReturnType<typeof useProjectsStatusStatisticsQuery>;
export type ProjectsStatusStatisticsLazyQueryHookResult = ReturnType<typeof useProjectsStatusStatisticsLazyQuery>;
export type ProjectsStatusStatisticsQueryResult = Apollo.QueryResult<ProjectsStatusStatisticsQuery, ProjectsStatusStatisticsQueryVariables>;
export const ProjectsTableDocument = gql`
    query projectsTable($filter: ProjectFiltersInput, $pagination: PaginationArg, $sort: [String] = ["createdAt:DESC"]) {
  projects(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      ...ProjectTable
    }
    meta {
      ...Meta
    }
  }
}
    ${ProjectTableFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ProjectsTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectsTableQuery, ProjectsTableQueryVariables>, 'query'>;

    export const ProjectsTableComponent = (props: ProjectsTableComponentProps) => (
      <ApolloReactComponents.Query<ProjectsTableQuery, ProjectsTableQueryVariables> query={ProjectsTableDocument} {...props} />
    );
    
export function useProjectsTableQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsTableQuery, ProjectsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsTableQuery, ProjectsTableQueryVariables>(ProjectsTableDocument, options);
      }
export function useProjectsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsTableQuery, ProjectsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsTableQuery, ProjectsTableQueryVariables>(ProjectsTableDocument, options);
        }
export type ProjectsTableQueryHookResult = ReturnType<typeof useProjectsTableQuery>;
export type ProjectsTableLazyQueryHookResult = ReturnType<typeof useProjectsTableLazyQuery>;
export type ProjectsTableQueryResult = Apollo.QueryResult<ProjectsTableQuery, ProjectsTableQueryVariables>;
export const ProjectsTotalDocument = gql`
    query projectsTotal {
  projects {
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ProjectsTotalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectsTotalQuery, ProjectsTotalQueryVariables>, 'query'>;

    export const ProjectsTotalComponent = (props: ProjectsTotalComponentProps) => (
      <ApolloReactComponents.Query<ProjectsTotalQuery, ProjectsTotalQueryVariables> query={ProjectsTotalDocument} {...props} />
    );
    
export function useProjectsTotalQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsTotalQuery, ProjectsTotalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsTotalQuery, ProjectsTotalQueryVariables>(ProjectsTotalDocument, options);
      }
export function useProjectsTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsTotalQuery, ProjectsTotalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsTotalQuery, ProjectsTotalQueryVariables>(ProjectsTotalDocument, options);
        }
export type ProjectsTotalQueryHookResult = ReturnType<typeof useProjectsTotalQuery>;
export type ProjectsTotalLazyQueryHookResult = ReturnType<typeof useProjectsTotalLazyQuery>;
export type ProjectsTotalQueryResult = Apollo.QueryResult<ProjectsTotalQuery, ProjectsTotalQueryVariables>;
export const ProjectsTotalStatisticsDocument = gql`
    query projectsTotalStatistics($filter: ProjectFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  projects(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      id
      attributes {
        projectCenter
        applications(pagination: {limit: -1}) {
          data {
            id
            attributes {
              approvals(pagination: {limit: -1}) {
                data {
                  id
                  attributes {
                    name
                    company {
                      data {
                        id
                        attributes {
                          name
                          organization {
                            data {
                              id
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ProjectsTotalStatisticsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectsTotalStatisticsQuery, ProjectsTotalStatisticsQueryVariables>, 'query'>;

    export const ProjectsTotalStatisticsComponent = (props: ProjectsTotalStatisticsComponentProps) => (
      <ApolloReactComponents.Query<ProjectsTotalStatisticsQuery, ProjectsTotalStatisticsQueryVariables> query={ProjectsTotalStatisticsDocument} {...props} />
    );
    
export function useProjectsTotalStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsTotalStatisticsQuery, ProjectsTotalStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsTotalStatisticsQuery, ProjectsTotalStatisticsQueryVariables>(ProjectsTotalStatisticsDocument, options);
      }
export function useProjectsTotalStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsTotalStatisticsQuery, ProjectsTotalStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsTotalStatisticsQuery, ProjectsTotalStatisticsQueryVariables>(ProjectsTotalStatisticsDocument, options);
        }
export type ProjectsTotalStatisticsQueryHookResult = ReturnType<typeof useProjectsTotalStatisticsQuery>;
export type ProjectsTotalStatisticsLazyQueryHookResult = ReturnType<typeof useProjectsTotalStatisticsLazyQuery>;
export type ProjectsTotalStatisticsQueryResult = Apollo.QueryResult<ProjectsTotalStatisticsQuery, ProjectsTotalStatisticsQueryVariables>;
export const ProvincesDocument = gql`
    query provinces($filter: ProvinceFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  provinces(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...Province
    }
  }
}
    ${ProvinceFragmentDoc}
${FileFragmentDoc}`;
export type ProvincesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProvincesQuery, ProvincesQueryVariables>, 'query'>;

    export const ProvincesComponent = (props: ProvincesComponentProps) => (
      <ApolloReactComponents.Query<ProvincesQuery, ProvincesQueryVariables> query={ProvincesDocument} {...props} />
    );
    
export function useProvincesQuery(baseOptions?: Apollo.QueryHookOptions<ProvincesQuery, ProvincesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProvincesQuery, ProvincesQueryVariables>(ProvincesDocument, options);
      }
export function useProvincesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProvincesQuery, ProvincesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProvincesQuery, ProvincesQueryVariables>(ProvincesDocument, options);
        }
export type ProvincesQueryHookResult = ReturnType<typeof useProvincesQuery>;
export type ProvincesLazyQueryHookResult = ReturnType<typeof useProvincesLazyQuery>;
export type ProvincesQueryResult = Apollo.QueryResult<ProvincesQuery, ProvincesQueryVariables>;
export const QuotationDocument = gql`
    query quotation($id: ID!) {
  quotation(id: $id) {
    data {
      ...Quotation
    }
  }
}
    ${QuotationFragmentDoc}
${QuoteItemFragmentDoc}`;
export type QuotationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QuotationQuery, QuotationQueryVariables>, 'query'> & ({ variables: QuotationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const QuotationComponent = (props: QuotationComponentProps) => (
      <ApolloReactComponents.Query<QuotationQuery, QuotationQueryVariables> query={QuotationDocument} {...props} />
    );
    
export function useQuotationQuery(baseOptions: Apollo.QueryHookOptions<QuotationQuery, QuotationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuotationQuery, QuotationQueryVariables>(QuotationDocument, options);
      }
export function useQuotationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuotationQuery, QuotationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuotationQuery, QuotationQueryVariables>(QuotationDocument, options);
        }
export type QuotationQueryHookResult = ReturnType<typeof useQuotationQuery>;
export type QuotationLazyQueryHookResult = ReturnType<typeof useQuotationLazyQuery>;
export type QuotationQueryResult = Apollo.QueryResult<QuotationQuery, QuotationQueryVariables>;
export const QuoteItemsDocument = gql`
    query quoteItems($filter: QuoteItemFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  quoteItems(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...QuoteItem
    }
  }
}
    ${QuoteItemFragmentDoc}`;
export type QuoteItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QuoteItemsQuery, QuoteItemsQueryVariables>, 'query'>;

    export const QuoteItemsComponent = (props: QuoteItemsComponentProps) => (
      <ApolloReactComponents.Query<QuoteItemsQuery, QuoteItemsQueryVariables> query={QuoteItemsDocument} {...props} />
    );
    
export function useQuoteItemsQuery(baseOptions?: Apollo.QueryHookOptions<QuoteItemsQuery, QuoteItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuoteItemsQuery, QuoteItemsQueryVariables>(QuoteItemsDocument, options);
      }
export function useQuoteItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuoteItemsQuery, QuoteItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuoteItemsQuery, QuoteItemsQueryVariables>(QuoteItemsDocument, options);
        }
export type QuoteItemsQueryHookResult = ReturnType<typeof useQuoteItemsQuery>;
export type QuoteItemsLazyQueryHookResult = ReturnType<typeof useQuoteItemsLazyQuery>;
export type QuoteItemsQueryResult = Apollo.QueryResult<QuoteItemsQuery, QuoteItemsQueryVariables>;
export const RolesDocument = gql`
    query roles($filter: UsersPermissionsRoleFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  usersPermissionsRoles(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...Role
    }
  }
}
    ${RoleFragmentDoc}`;
export type RolesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RolesQuery, RolesQueryVariables>, 'query'>;

    export const RolesComponent = (props: RolesComponentProps) => (
      <ApolloReactComponents.Query<RolesQuery, RolesQueryVariables> query={RolesDocument} {...props} />
    );
    
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const ServiceApplicationsDocument = gql`
    query serviceApplications($filter: ApplicationFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  applications(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        uuid
        title
        status
        createdAt
        submittedDate
        progress
        applicationType {
          data {
            id
            attributes {
              title
            }
          }
        }
        company {
          data {
            id
            attributes {
              name
            }
          }
        }
        approvals {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type ServiceApplicationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ServiceApplicationsQuery, ServiceApplicationsQueryVariables>, 'query'>;

    export const ServiceApplicationsComponent = (props: ServiceApplicationsComponentProps) => (
      <ApolloReactComponents.Query<ServiceApplicationsQuery, ServiceApplicationsQueryVariables> query={ServiceApplicationsDocument} {...props} />
    );
    
export function useServiceApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<ServiceApplicationsQuery, ServiceApplicationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceApplicationsQuery, ServiceApplicationsQueryVariables>(ServiceApplicationsDocument, options);
      }
export function useServiceApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceApplicationsQuery, ServiceApplicationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceApplicationsQuery, ServiceApplicationsQueryVariables>(ServiceApplicationsDocument, options);
        }
export type ServiceApplicationsQueryHookResult = ReturnType<typeof useServiceApplicationsQuery>;
export type ServiceApplicationsLazyQueryHookResult = ReturnType<typeof useServiceApplicationsLazyQuery>;
export type ServiceApplicationsQueryResult = Apollo.QueryResult<ServiceApplicationsQuery, ServiceApplicationsQueryVariables>;
export const SettingsDocument = gql`
    query settings {
  setting {
    data {
      ...Settings
    }
  }
}
    ${SettingsFragmentDoc}`;
export type SettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SettingsQuery, SettingsQueryVariables>, 'query'>;

    export const SettingsComponent = (props: SettingsComponentProps) => (
      <ApolloReactComponents.Query<SettingsQuery, SettingsQueryVariables> query={SettingsDocument} {...props} />
    );
    
export function useSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
      }
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const TaskDocument = gql`
    query task($id: ID!) {
  task(id: $id) {
    data {
      ...Task
    }
  }
}
    ${TaskFragmentDoc}`;
export type TaskComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TaskQuery, TaskQueryVariables>, 'query'> & ({ variables: TaskQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TaskComponent = (props: TaskComponentProps) => (
      <ApolloReactComponents.Query<TaskQuery, TaskQueryVariables> query={TaskDocument} {...props} />
    );
    
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const TasksDocument = gql`
    query tasks($filter: TaskFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  tasks(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...Task
    }
  }
}
    ${TaskFragmentDoc}`;
export type TasksComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TasksQuery, TasksQueryVariables>, 'query'>;

    export const TasksComponent = (props: TasksComponentProps) => (
      <ApolloReactComponents.Query<TasksQuery, TasksQueryVariables> query={TasksDocument} {...props} />
    );
    
export function useTasksQuery(baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const TasksStatisticsDocument = gql`
    query tasksStatistics($filter: TaskFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  tasks(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      id
    }
  }
}
    `;
export type TasksStatisticsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TasksStatisticsQuery, TasksStatisticsQueryVariables>, 'query'>;

    export const TasksStatisticsComponent = (props: TasksStatisticsComponentProps) => (
      <ApolloReactComponents.Query<TasksStatisticsQuery, TasksStatisticsQueryVariables> query={TasksStatisticsDocument} {...props} />
    );
    
export function useTasksStatisticsQuery(baseOptions?: Apollo.QueryHookOptions<TasksStatisticsQuery, TasksStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksStatisticsQuery, TasksStatisticsQueryVariables>(TasksStatisticsDocument, options);
      }
export function useTasksStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksStatisticsQuery, TasksStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksStatisticsQuery, TasksStatisticsQueryVariables>(TasksStatisticsDocument, options);
        }
export type TasksStatisticsQueryHookResult = ReturnType<typeof useTasksStatisticsQuery>;
export type TasksStatisticsLazyQueryHookResult = ReturnType<typeof useTasksStatisticsLazyQuery>;
export type TasksStatisticsQueryResult = Apollo.QueryResult<TasksStatisticsQuery, TasksStatisticsQueryVariables>;
export const TasksTotalDocument = gql`
    query tasksTotal($filter: TaskFiltersInput) {
  tasks(filters: $filter) {
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type TasksTotalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TasksTotalQuery, TasksTotalQueryVariables>, 'query'>;

    export const TasksTotalComponent = (props: TasksTotalComponentProps) => (
      <ApolloReactComponents.Query<TasksTotalQuery, TasksTotalQueryVariables> query={TasksTotalDocument} {...props} />
    );
    
export function useTasksTotalQuery(baseOptions?: Apollo.QueryHookOptions<TasksTotalQuery, TasksTotalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksTotalQuery, TasksTotalQueryVariables>(TasksTotalDocument, options);
      }
export function useTasksTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksTotalQuery, TasksTotalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksTotalQuery, TasksTotalQueryVariables>(TasksTotalDocument, options);
        }
export type TasksTotalQueryHookResult = ReturnType<typeof useTasksTotalQuery>;
export type TasksTotalLazyQueryHookResult = ReturnType<typeof useTasksTotalLazyQuery>;
export type TasksTotalQueryResult = Apollo.QueryResult<TasksTotalQuery, TasksTotalQueryVariables>;
export const TemplateDocumentDocument = gql`
    query templateDocument($id: ID!) {
  documentTemplate(id: $id) {
    data {
      ...DocumentTemplate
    }
  }
}
    ${DocumentTemplateFragmentDoc}`;
export type TemplateDocumentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TemplateDocumentQuery, TemplateDocumentQueryVariables>, 'query'> & ({ variables: TemplateDocumentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TemplateDocumentComponent = (props: TemplateDocumentComponentProps) => (
      <ApolloReactComponents.Query<TemplateDocumentQuery, TemplateDocumentQueryVariables> query={TemplateDocumentDocument} {...props} />
    );
    
export function useTemplateDocumentQuery(baseOptions: Apollo.QueryHookOptions<TemplateDocumentQuery, TemplateDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateDocumentQuery, TemplateDocumentQueryVariables>(TemplateDocumentDocument, options);
      }
export function useTemplateDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateDocumentQuery, TemplateDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateDocumentQuery, TemplateDocumentQueryVariables>(TemplateDocumentDocument, options);
        }
export type TemplateDocumentQueryHookResult = ReturnType<typeof useTemplateDocumentQuery>;
export type TemplateDocumentLazyQueryHookResult = ReturnType<typeof useTemplateDocumentLazyQuery>;
export type TemplateDocumentQueryResult = Apollo.QueryResult<TemplateDocumentQuery, TemplateDocumentQueryVariables>;
export const TemplateSubjectDocument = gql`
    query templateSubject($id: ID!) {
  templateSubject(id: $id) {
    data {
      ...TemplateSubject
    }
  }
}
    ${TemplateSubjectFragmentDoc}
${DocumentTemplateFragmentDoc}`;
export type TemplateSubjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TemplateSubjectQuery, TemplateSubjectQueryVariables>, 'query'> & ({ variables: TemplateSubjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TemplateSubjectComponent = (props: TemplateSubjectComponentProps) => (
      <ApolloReactComponents.Query<TemplateSubjectQuery, TemplateSubjectQueryVariables> query={TemplateSubjectDocument} {...props} />
    );
    
export function useTemplateSubjectQuery(baseOptions: Apollo.QueryHookOptions<TemplateSubjectQuery, TemplateSubjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplateSubjectQuery, TemplateSubjectQueryVariables>(TemplateSubjectDocument, options);
      }
export function useTemplateSubjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplateSubjectQuery, TemplateSubjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplateSubjectQuery, TemplateSubjectQueryVariables>(TemplateSubjectDocument, options);
        }
export type TemplateSubjectQueryHookResult = ReturnType<typeof useTemplateSubjectQuery>;
export type TemplateSubjectLazyQueryHookResult = ReturnType<typeof useTemplateSubjectLazyQuery>;
export type TemplateSubjectQueryResult = Apollo.QueryResult<TemplateSubjectQuery, TemplateSubjectQueryVariables>;
export const TemplatesSubjectsDocument = gql`
    query templatesSubjects($filter: TemplateSubjectFiltersInput, $sort: [String] = ["createdAt:DESC"]) {
  templateSubjects(filters: $filter, sort: $sort, pagination: {limit: -1}) {
    data {
      ...TemplateSubject
    }
  }
}
    ${TemplateSubjectFragmentDoc}
${DocumentTemplateFragmentDoc}`;
export type TemplatesSubjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TemplatesSubjectsQuery, TemplatesSubjectsQueryVariables>, 'query'>;

    export const TemplatesSubjectsComponent = (props: TemplatesSubjectsComponentProps) => (
      <ApolloReactComponents.Query<TemplatesSubjectsQuery, TemplatesSubjectsQueryVariables> query={TemplatesSubjectsDocument} {...props} />
    );
    
export function useTemplatesSubjectsQuery(baseOptions?: Apollo.QueryHookOptions<TemplatesSubjectsQuery, TemplatesSubjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatesSubjectsQuery, TemplatesSubjectsQueryVariables>(TemplatesSubjectsDocument, options);
      }
export function useTemplatesSubjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesSubjectsQuery, TemplatesSubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatesSubjectsQuery, TemplatesSubjectsQueryVariables>(TemplatesSubjectsDocument, options);
        }
export type TemplatesSubjectsQueryHookResult = ReturnType<typeof useTemplatesSubjectsQuery>;
export type TemplatesSubjectsLazyQueryHookResult = ReturnType<typeof useTemplatesSubjectsLazyQuery>;
export type TemplatesSubjectsQueryResult = Apollo.QueryResult<TemplatesSubjectsQuery, TemplatesSubjectsQueryVariables>;
export const UserDocument = gql`
    query user($id: ID!) {
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        email
        uuid
        confirmed
        blocked
        userProfile {
          data {
            id
          }
        }
        organization {
          data {
            ...Organization
          }
        }
        role {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    ${OrganizationFragmentDoc}
${FileFragmentDoc}`;
export type UserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserQuery, UserQueryVariables>, 'query'> & ({ variables: UserQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserComponent = (props: UserComponentProps) => (
      <ApolloReactComponents.Query<UserQuery, UserQueryVariables> query={UserDocument} {...props} />
    );
    
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserProfileDocument = gql`
    query userProfile($id: ID!) {
  userProfile(id: $id) {
    data {
      ...UserProfile
    }
  }
}
    ${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}`;
export type UserProfileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserProfileQuery, UserProfileQueryVariables>, 'query'> & ({ variables: UserProfileQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserProfileComponent = (props: UserProfileComponentProps) => (
      <ApolloReactComponents.Query<UserProfileQuery, UserProfileQueryVariables> query={UserProfileDocument} {...props} />
    );
    
export function useUserProfileQuery(baseOptions: Apollo.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
      }
export function useUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
        }
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = Apollo.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const UserProfilesDocument = gql`
    query userProfiles($filter: UserProfileFiltersInput!, $pagination: PaginationArg, $sort: [String] = ["createdAt:DESC"]) {
  userProfiles(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      ...UserProfile
    }
    meta {
      ...Meta
    }
  }
}
    ${UserProfileFragmentDoc}
${FileFragmentDoc}
${CompanyFragmentDoc}
${ApplicationMethodFragmentDoc}
${DocumentTypeFragmentDoc}
${ApplicationTypeFragmentDoc}
${AreaFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type UserProfilesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserProfilesQuery, UserProfilesQueryVariables>, 'query'> & ({ variables: UserProfilesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserProfilesComponent = (props: UserProfilesComponentProps) => (
      <ApolloReactComponents.Query<UserProfilesQuery, UserProfilesQueryVariables> query={UserProfilesDocument} {...props} />
    );
    
export function useUserProfilesQuery(baseOptions: Apollo.QueryHookOptions<UserProfilesQuery, UserProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfilesQuery, UserProfilesQueryVariables>(UserProfilesDocument, options);
      }
export function useUserProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfilesQuery, UserProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfilesQuery, UserProfilesQueryVariables>(UserProfilesDocument, options);
        }
export type UserProfilesQueryHookResult = ReturnType<typeof useUserProfilesQuery>;
export type UserProfilesLazyQueryHookResult = ReturnType<typeof useUserProfilesLazyQuery>;
export type UserProfilesQueryResult = Apollo.QueryResult<UserProfilesQuery, UserProfilesQueryVariables>;
export const UserProfilesSelectOptionsDocument = gql`
    query userProfilesSelectOptions($filter: UserProfileFiltersInput!, $pagination: PaginationArg, $sort: [String] = ["createdAt:DESC"]) {
  userProfiles(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        fullName
        primaryEmail
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export type UserProfilesSelectOptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserProfilesSelectOptionsQuery, UserProfilesSelectOptionsQueryVariables>, 'query'> & ({ variables: UserProfilesSelectOptionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserProfilesSelectOptionsComponent = (props: UserProfilesSelectOptionsComponentProps) => (
      <ApolloReactComponents.Query<UserProfilesSelectOptionsQuery, UserProfilesSelectOptionsQueryVariables> query={UserProfilesSelectOptionsDocument} {...props} />
    );
    
export function useUserProfilesSelectOptionsQuery(baseOptions: Apollo.QueryHookOptions<UserProfilesSelectOptionsQuery, UserProfilesSelectOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfilesSelectOptionsQuery, UserProfilesSelectOptionsQueryVariables>(UserProfilesSelectOptionsDocument, options);
      }
export function useUserProfilesSelectOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfilesSelectOptionsQuery, UserProfilesSelectOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfilesSelectOptionsQuery, UserProfilesSelectOptionsQueryVariables>(UserProfilesSelectOptionsDocument, options);
        }
export type UserProfilesSelectOptionsQueryHookResult = ReturnType<typeof useUserProfilesSelectOptionsQuery>;
export type UserProfilesSelectOptionsLazyQueryHookResult = ReturnType<typeof useUserProfilesSelectOptionsLazyQuery>;
export type UserProfilesSelectOptionsQueryResult = Apollo.QueryResult<UserProfilesSelectOptionsQuery, UserProfilesSelectOptionsQueryVariables>;
export const UsersDocument = gql`
    query users($filter: UsersPermissionsUserFiltersInput, $sort: [String] = ["createdAt:DESC"], $pagination: PaginationArg) {
  usersPermissionsUsers(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      id
      attributes {
        email
        userProfile {
          data {
            attributes {
              company {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              fullName
              avatar {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type UsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UsersQuery, UsersQueryVariables>, 'query'>;

    export const UsersComponent = (props: UsersComponentProps) => (
      <ApolloReactComponents.Query<UsersQuery, UsersQueryVariables> query={UsersDocument} {...props} />
    );
    
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const WebsiteDocument = gql`
    query website {
  website {
    data {
      ...Website
    }
  }
}
    ${WebsiteFragmentDoc}
${SeoFragmentDoc}
${HomeFragmentDoc}
${FileFragmentDoc}
${PrivacyFragmentDoc}
${TermsFragmentDoc}
${FreeQuoteFragmentDoc}`;
export type WebsiteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<WebsiteQuery, WebsiteQueryVariables>, 'query'>;

    export const WebsiteComponent = (props: WebsiteComponentProps) => (
      <ApolloReactComponents.Query<WebsiteQuery, WebsiteQueryVariables> query={WebsiteDocument} {...props} />
    );
    
export function useWebsiteQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteQuery, WebsiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteQuery, WebsiteQueryVariables>(WebsiteDocument, options);
      }
export function useWebsiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteQuery, WebsiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteQuery, WebsiteQueryVariables>(WebsiteDocument, options);
        }
export type WebsiteQueryHookResult = ReturnType<typeof useWebsiteQuery>;
export type WebsiteLazyQueryHookResult = ReturnType<typeof useWebsiteLazyQuery>;
export type WebsiteQueryResult = Apollo.QueryResult<WebsiteQuery, WebsiteQueryVariables>;