import { PLATFORM_SETTINGS } from '@/components/app'
import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import { SvgIcon } from '@/components/icon'
import ItemTag from '@/components/item-tag/ItemTag'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useCreateApprovalMutation, useSendCompaniesNotificationsMutation, useUpdateApplicationMutation, useUpdateApprovalMutation } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { message, Row, Skeleton, Space } from 'antd'
import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Paragraph, Text } = Typography
const ApplyForRenewal: FC = () => {
  const { user } = useAuth()
  const { setIsModalOpen } = useGeneralContext()

  const [approvals, setApprovals] = useState<ApprovalEntity[]>([])

  const [updateApprovalItem] = useUpdateApprovalMutation()
  const [sendCompaniesNotifications, { loading: notificationIsSending }] = useSendCompaniesNotificationsMutation()
  const [updateApplication, { loading: applicationUpdating }] = useUpdateApplicationMutation()
  const { data: appDataQuery, loading: appDataLoading } = useApplicationByUuid()
  const [createApproval] = useCreateApprovalMutation()


  const appData = appDataQuery?.applications?.data?.[0] as ApplicationEntity


  // const companiesIds = outdatedApprovalItems?.length ? outdatedApprovalItems?.map(it => it?.attributes?.company?.data?.id) : []
  //
  // const { data, loading: companiesDataLoading } = useCompaniesQuery({
  //   fetchPolicy: globalFetchPolicy,
  //   skip: companiesIds?.length === 0,
  //   variables: { filter: { id: { in: companiesIds } }, pagination: { limit: -1 } },
  // })
  // const companies = data?.companies?.data as CompanyEntity[]


  useEffect(() => {
    const approvals = appData?.attributes?.approvals?.data as ApprovalEntity[]
    const daysToCheck = PLATFORM_SETTINGS.closeToExpireDays || 30
    const currentDate = moment()
    const outdatedApprovalItems = approvals?.filter(it => {
      const expiry = moment(it?.attributes?.expiryDate)
      const isAlreadyExpired = currentDate.isSameOrAfter(expiry)
      const closeToExpiry = expiry?.diff(moment(currentDate), 'days') <= daysToCheck
      return (it?.attributes?.status === 'approved' && !it?.attributes?.isRenewalApplied && (isAlreadyExpired || closeToExpiry))
    })
    setApprovals(outdatedApprovalItems)
  }, [appData?.attributes?.approvals?.data])

  const onCancel = () => {
    setIsModalOpen(false)
  }
  // approvals?.map(it => {
  //   return ({
  //     uuid: v4(),
  //     name: it?.attributes?.name,
  //     company: it?.id,
  //     baseInitialItemUuid: currentApproval?.uuid,
  //   } as ComponentDataApplicationServiceProviderInput)
  // })
  const onConfirm = async () => {
    // const selectedCompaniesIds = companies?.map(it => it.id) as string[]

    const newRenewals = await Promise.all(
      approvals?.map(async (it) => {
        const agentsInfo = it?.attributes?.agents?.map(item => ({
          name: item?.name,
          agentProfile: item?.agentProfile?.data?.id,
          assignStatus: item?.assignStatus,
        }))
        it?.id && await updateApprovalItem({
          variables: {
            id: it?.id,
            data: {
              isRenewalApplied: true,
            },
          },
        })
        const result = await createApproval({
          variables: {
            input: {
              type: 'renewal',
              uuid: v4(),
              name: it?.attributes?.name,
              company: it?.attributes?.company?.data?.id,
              refApproval: it?.id,
              contacts: it?.attributes?.contacts?.data?.map(it => it?.id),
              isAffected: it?.attributes?.isAffected,
              agents: agentsInfo,
              isSiteVisitRequired: it?.attributes?.isSiteVisitRequired,
            },
          },
          onError: (error) => {
            message.error(error.message)
          },
        })
        return result?.data?.createApproval?.data
      }),
    ) as ApprovalEntity[]
    console.log('NEW RENEWALS: ===> ', newRenewals)

    const newRenewalIds = newRenewals?.map(it => it?.id) || []
    const existingRenewalsIds = appData?.attributes?.renewals?.data?.map(it => it?.id) || []


    const renewalsToSave = [...newRenewalIds, ...existingRenewalsIds] as string[]

    appData?.id && await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: appData?.id,
        data: {
          renewals: renewalsToSave,
        },
      },
      onCompleted: () => {
        BlackNotification('Renewals created successfully. Application updated.')
        setIsModalOpen(false)
      },
      onError: (error) => {
        message.error(error.message)
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
    const approvalCompaniesIds = approvals?.map(it => it?.attributes?.company?.data?.id) as string[] || []
    approvalCompaniesIds && approvalCompaniesIds?.length && await sendCompaniesNotifications({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          companiesIds: approvalCompaniesIds,
          applicationId: appData?.id || '',
          organizationId: user?.organization?.data?.id || '',
          category: 'renewal',
          profileId: user?.userProfile?.data?.id || '',
        },
      },
      onCompleted: () => {
        BlackNotification('Notifications were sent successfully.')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(error.message)
        setIsModalOpen(false)
      },
    })
  }

  const onRemoveItemClick = (id: Maybe<string>) => {
    const filteredApprovals = approvals?.filter(it => it?.id !== id)
    setApprovals(filteredApprovals)
  }

  return (
    <>
      <FormItemDivider title={'Apply for renewal'}
                       subTitle={'Below is a list of approval items with expired date or approval items that will expire within the next 30 days.'} />
      {!approvals?.length ? <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>{'All approval items are up-to-date'}</Paragraph> : (
        <Paragraph style={{ marginTop: 12, marginBottom: 16 }} size={'sm'} weight={'w700'}>
          {`Notification email will be sent to all listed companies. Renewal items will be created under the Renewals tab.`}
        </Paragraph>
      )}
      {appDataLoading && (
        <Skeleton paragraph={{ rows: 5 }} loading={true} />
      )}
      <Space direction={'vertical'} className={'tag-list-container'}>
        {approvals?.map(it => (
          <ItemTag
            key={it?.id}
            mainText={it?.attributes?.company?.data?.attributes?.name}
            secondaryText={it?.attributes?.company?.data?.attributes?.companyEmail}
            iconType={'envelop'}
            closable={true}
            onClick={onRemoveItemClick}
            itemId={it?.id}
            extra={<Text color={'error'} size={'xs'}>{moment(it?.attributes?.expiryDate)?.format('DD-MMM, YYYY')}</Text>}
          />
        ))}

      </Space>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Cancel'}
          btnType={'text'}
          onClick={onCancel}
          loading={notificationIsSending || appDataLoading || applicationUpdating}
        />
        <Button
          text={'Apply for renewal'}
          btnType={'primary'}
          onClick={onConfirm}
          loading={notificationIsSending || appDataLoading || applicationUpdating}
          icon={<SvgIcon type={'envelop'} />}
          isIconWhite={true}
          disabled={!approvals?.length}
        />
      </Row>
    </>
  )
}
export default ApplyForRenewal
