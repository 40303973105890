import { SvgIcon } from '@/components/icon'
import WebsiteButton from '@/components/website-button/WebsiteButton'
import WebsiteTypography from '@/components/websiteTypography/WebsiteTypography'
import { scrollToSection } from '@/helpers/scrollToSection'
import { websiteRoutes } from '@/router/routes'
import { Col, Row, Space } from 'antd'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import fbIcon from './images/fb.png'
import sectionBg from './images/footer-bg.png'
import instaIcon from './images/instagram.png'
import linkedIcon from './images/lingedin.png'
import twitterIcon from './images/twitter-icon.png'
import logoWhite from './images/logo_company-white.png'

import { useBreakpoints } from '@/components/screen'

import './Footer.less'

const { Text, Paragraph } = WebsiteTypography


const Footer: FC = () => {

  const { isXxl, isXl, isLg, isXxs } = useBreakpoints()
  const navigate = useNavigate()
  const { website } = websiteRoutes

  const params = useParams()
  const currentOrgSlug = params.org

  const onPrivacyClick = () => {
    navigate(website.privacyPolicy)
  }
  const onTermsClick = () => {
    navigate(website.termsAndConditions)
  }

  const onSoftwareSolutionsClick = async () => {
    navigate(`/easetech${website.ourServices}`)
    await scrollToSection('software-solutions')
  }

  const onConsultingServicesClick = async () => {
    navigate(`/easetech${website.ourServices}`)
    await scrollToSection('our-consulting-services')
  }

  const onHomeClick = async () => {
    navigate(`/easetech${website.home}`)
  }

  return (
    <div className={'footer-section-wrapper'} id={'footer-section'}>
      <div className={'footer-bg-wrapper'}>
        <img src={sectionBg} alt={''} />
      </div>

      {(isXxl || isXl || isLg) ? (
          <div className={'footer-section-content'}>
            <div className={'logo-block'}>
              <div className={'logo-wrapper'}>
                <img src={logoWhite} alt={''} />
              </div>
              <div className={'soc-buttons-block'}>
                <WebsiteButton btnType={'icon'} className={'soc-btn sm'}>
                  <SvgIcon type={'footer-soc-btn'} />
                  <img src={fbIcon} alt={''} />
                </WebsiteButton>
                <WebsiteButton btnType={'icon'} className={'soc-btn sm'}>
                  <SvgIcon type={'footer-soc-btn'} />
                  <img src={twitterIcon} alt={''} />
                </WebsiteButton>
                <WebsiteButton btnType={'icon'} className={'soc-btn'}>
                  <SvgIcon type={'footer-soc-btn'} />
                  <img src={instaIcon} alt={''} />
                </WebsiteButton>
                <WebsiteButton btnType={'icon'} className={'soc-btn'}>
                  <SvgIcon type={'footer-soc-btn'} />
                  <img src={linkedIcon} alt={''} />
                </WebsiteButton>
              </div>
              <div className={'terms-block'}>
                <WebsiteButton btnType={'text'} onClick={onTermsClick}>
                  <Text color={'website-secondary'} size={'sm'}>{'Terms and conditions'}</Text>
                </WebsiteButton>
                <div className={'footer-divider'} />
                <WebsiteButton btnType={'text'} onClick={onPrivacyClick}>
                  <Text color={'website-secondary'} size={'sm'}>{'Privacy policy'}</Text>
                </WebsiteButton>
              </div>
              <div className={'rights-reserved-block'}>
                <Text color={'website-secondary'} weight={'w300'} size={'md'} className={'rights-text'}>
                  © {new Date().getFullYear()}
                  <span>
                 {' easetech. '}
               </span>
                  All Rights Reserved
                </Text>
              </div>
            </div>
            <div className={'content-block'}>
              <div className={'navigation'}>
                <div className={'footer-content-title'}>
                  <Text weight={'w700'} size={'lg'}>{'Navigation'}</Text>
                </div>
                <WebsiteButton btnType={'text'} onClick={onHomeClick}>
                  <Text weight={'w700'} color={'website-secondary'} size={'md'}>{'Home'}</Text>
                </WebsiteButton>
                <WebsiteButton btnType={'text'} onClick={() => scrollToSection('contact')}>
                  <Text weight={'w700'} color={'website-secondary'} size={'md'}>{'Quick contact'}</Text>
                </WebsiteButton>

                <Space
                  direction={'vertical'}
                  size={'small'}
                  className={'auth-btns-footer'}
                >
                  <WebsiteButton btnType={'ghost-white'} block>
                    <Link to={`/${currentOrgSlug}${website.auth}/login`}>
                      <Text>
                        {'SIGN IN'}
                      </Text>
                    </Link>
                  </WebsiteButton>
                  <WebsiteButton btnType={'primary'} block>
                    <Link to={`/${currentOrgSlug}${website.auth}/client`}>
                      <Text>
                        {'REGISTER'}
                      </Text>
                    </Link>
                  </WebsiteButton>
                </Space>
              </div>

            </div>
            <div className={'content-block links-block'}>
              <div className={'footer-content-title'}>
                <Text weight={'w700'} size={'lg'}>{'Our Services'}</Text>
              </div>
              <WebsiteButton btnType={'text'} onClick={onSoftwareSolutionsClick}>
                <Text weight={'w700'} color={'website-secondary'} size={'md'}>{'Software Solution'}</Text>
              </WebsiteButton>
              <WebsiteButton btnType={'text'} onClick={onConsultingServicesClick}>
                <Text weight={'w700'} color={'website-secondary'} size={'md'}>{'Consulting Services'}</Text>
              </WebsiteButton>
            </div>
            <div className={'content-block'}>
              <div className={'footer-content-title'}>
                <Text weight={'w700'} size={'lg'}>{'Contact us'}</Text>
              </div>
              <div className={'contact-us-item'}>
                <Text
                  size={'sm'}
                  lh={'lh-lg'}
                  color={'website-secondary'}
                  className={'number-text-title'}
                  id = {'phone-section'}
                >
                  {'Phone:'}
                </Text>

                <a
                  href={'tel:+0826996888'}
                  className={'contact-phone-item'}
                >
                  <SvgIcon type={'whatsapp'} color={'white'}/>
                  <Paragraph
                    weight={'w700'}
                    size={'lg'}
                    className={'number-text'}
                    centered
                    style={{ letterSpacing: '2px' }}
                  >
                    {'082 699 6888'}
                  </Paragraph>
                </a>

                <a
                  href={'tel:+0107454941'}
                  className={'contact-phone-item'}
                >
                  <SvgIcon type={'phone'} color={'white'} />
                  <Paragraph
                    weight={'w700'}
                    size={'lg'}
                    className={'number-text'}
                    centered
                    style={{ letterSpacing: '2px' }}
                  >
                    {'010 745 4941'}
                  </Paragraph>
                </a>
              </div>

              <div className={'contact-us-item email-section'} style={{ paddingTop: '12px' }}>
                <Text size={'sm'} color={'website-secondary'}>{'Email:'}</Text>
                <a href={'mailto:info@easetech.co.za'}>
                  <Text weight={'w300'} size={'md'} color={'orange'}>{'info@easetech.co.za'}</Text>
                </a>
              </div>
            </div>
          </div>
        ) :
        (
          <Row className={'footer-mobile-content'}>

            <Col span={24} style={{ paddingBottom: 26 }}>
              <Row className={'footer-mobile-logo'} justify={'center'} align={'middle'}>
                <Col className={'logo-wrapper'}>
                  <img src={logoWhite} alt={''} />
                </Col>
              </Row>
            </Col>
            {/*Navigation*/}
            <Col span={24} style={{ paddingBottom: 24 }}>
              <Row className={'footer-mobile-buttons-block'} justify={'center'}>
                <Col span={14} md={14} sm={18} xs={24}>
                  <Row justify={'space-between'} gutter={8}>
                    <Col span={11} md={11} sm={11} xs={isXxs ? 24 : 12} style={{ marginBottom: isXxs ? 24 : 0 }}>
                      <Space direction={'vertical'} size={'middle'} style={{ width: '100%', paddingBottom: 24 }}>
                        <Paragraph centered weight={'w700'} size={'xl'}>{'Navigation'}</Paragraph>

                        <WebsiteButton btnType={'text'} onClick={onHomeClick} style={{ width: '100%' }}>
                          <Text weight={'w700'} color={'website-secondary'} size={'lg'}>{'Home'}</Text>
                        </WebsiteButton>

                        <WebsiteButton btnType={'text'} onClick={() => scrollToSection('contact')} style={{ width: '100%' }}>
                          <Text weight={'w700'} color={'website-secondary'} size={'lg'}>{'Quick contact'}</Text>
                        </WebsiteButton>
                      </Space>
                    </Col>

                    <Col span={11} md={11} sm={11} xs={isXxs ? 24 : 12}>
                      <Space direction={'vertical'} size={'middle'} style={{ width: '100%', paddingBottom: 24 }}>
                        <Paragraph centered weight={'w700'} size={'xl'}>{'Our Services'}</Paragraph>

                        <WebsiteButton btnType={'text'} onClick={onSoftwareSolutionsClick} style={{ width: '100%' }}>
                          <Text weight={'w700'} color={'website-secondary'} size={'lg'}>{'Software Solution'}</Text>
                        </WebsiteButton>

                        <WebsiteButton btnType={'text'} onClick={onConsultingServicesClick} style={{ width: '100%' }}>
                          <Text weight={'w700'} color={'website-secondary'} size={'lg'}>{'Consulting Services'}</Text>
                        </WebsiteButton>
                      </Space>
                    </Col>
                  </Row>

                  {/*SIGN IN, REGISTER*/}
                  <Row justify={isXxs ? 'center' : 'space-between'} gutter={8}>
                    <Col span={11} md={11} sm={11} xs={isXxs ? 18 : 12} style={{ marginBottom: isXxs ? 24 : 0 }}>
                      <WebsiteButton size={'large'} btnType={'primary'} block>
                        <Link to={`/${currentOrgSlug}${website.auth}/client`}>
                          <Text size={'lg'}>{'REGISTER'}</Text>
                        </Link>
                      </WebsiteButton>
                    </Col>

                    <Col span={11} md={11} sm={11} xs={isXxs ? 18 : 12} style={{ marginBottom: isXxs ? 24 : 0 }}>
                      <WebsiteButton size={'large'} btnType={'ghost-white'} block>
                        <Link to={`/${currentOrgSlug}${website.auth}/login`}>
                          <Text size={'lg'}>{'SIGN IN'}</Text>
                        </Link>
                      </WebsiteButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={24} style={{ paddingBottom: 24 }}>
              <Row justify={'center'}>
                <Col span={16}>
                  <Paragraph centered weight={'w700'} size={'xl'}>{'Contact Us'}</Paragraph>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row justify={'center'}>
                <Col span={14} md={14} sm={18} xs={22}>
                  <Row justify={'space-between'}>
                    <Col span={11} md={11} sm={11} xs={isXxs ? 24 : 12}>
                      <Space
                        direction={'vertical'}
                        style={{ width: '100%', paddingBottom: 24 }}
                        className={'contact-items-space-wrapper'}
                      >
                        <Paragraph
                          size={'md'}
                          lh={'lh-lg'}
                          color={'website-secondary'}
                          centered
                          className={'number-text-title'}
                          id = {'phone-section'}
                        >
                          {'Phone:'}
                        </Paragraph>

                        <a
                          href={'tel:+0826996888'}
                          className={'contact-phone-mobile-item'}
                        >
                          <SvgIcon type={'whatsapp'} color={'white'} />
                          <Paragraph
                            weight={'w700'}
                            size={'lg'}
                            className={'number-text'}
                            centered
                            style={{ letterSpacing: '2px' }}
                          >
                            {'082 699 6888'}
                          </Paragraph>
                        </a>

                        <a
                          href={'tel:+0107454941'}
                          className={'contact-phone-mobile-item'}
                        >
                          <SvgIcon type={'phone'} color={'white'} />
                          <Paragraph
                            weight={'w700'}
                            size={'lg'}
                            className={'number-text'}
                            centered
                            style={{ letterSpacing: '2px' }}
                          >
                            {'010 745 4941'}
                          </Paragraph>
                        </a>
                      </Space>
                    </Col>

                    <Col span={11} md={11} sm={11} xs={isXxs ? 24 : 12}>
                      <Space direction={'vertical'} style={{ width: '100%' }}>
                        <Paragraph
                          size={'md'}
                          lh={'lh-lg'}
                          color={'website-secondary'}
                          className={'number-text-title'}
                          centered
                          style={{ width: '100%' }}
                        >
                          {'Email:'}
                        </Paragraph>

                        <a href={'mailto:info@easetech.co.za'} style={{ width: '100%' }}>
                          <Paragraph
                            weight={'w700'}
                            size={'lg'}
                            color={'orange'}
                            centered
                            lh={'lh-sm'}
                          >
                            {'info@easetech.co.za'}
                          </Paragraph>
                        </a>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {/*Social media icons*/}
            <Col span={24} style={{ paddingBottom: 24 }}>
              <Row justify={'center'} style={{ paddingTop: (isXxs ? 28 : 0) }}>
                <Col span={16} md={16} sm={16} xs={24} className={'soc-buttons-block'}>
                  <Row justify={'center'}>
                    <Col>
                      <Space>
                        <WebsiteButton btnType={'icon'} className={'soc-btn sm'}>
                          <SvgIcon type={'footer-soc-btn'} />
                          <img src={fbIcon} alt={''} />
                        </WebsiteButton>
                        <WebsiteButton btnType={'icon'} className={'soc-btn sm'}>
                          <SvgIcon type={'footer-soc-btn'} />
                          <img src={twitterIcon} alt={''} />
                        </WebsiteButton>
                        <WebsiteButton btnType={'icon'} className={'soc-btn'}>
                          <SvgIcon type={'footer-soc-btn'} />
                          <img src={instaIcon} alt={''} />
                        </WebsiteButton>
                        <WebsiteButton btnType={'icon'} className={'soc-btn'}>
                          <SvgIcon type={'footer-soc-btn'} />
                          <img src={linkedIcon} alt={''} />
                        </WebsiteButton>
                      </Space>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            {/* terms and conditions*/}
            <Col span={24} style={{ paddingBottom: 36 }}>
              <Row justify={'center'}>
                <Col span={14} md={14} sm={19} xs={24}>
                  <Row justify={'space-between'}>
                    <Col span={11}>
                      <WebsiteButton btnType={'text'} onClick={onTermsClick} style={{ width: '100%' }}>
                        <Paragraph
                          color={'website-secondary'}
                          size={'lg'}
                          weight={'w400'}
                        >
                          {'Terms and conditions'}
                        </Paragraph>
                      </WebsiteButton>

                    </Col>
                    <Col span={11}>
                      <WebsiteButton btnType={'text'} onClick={onPrivacyClick} style={{ width: '100%' }}>
                        <Paragraph
                          color={'website-secondary'}
                          size={'lg'}
                          weight={'w400'}
                        >
                          {'Privacy policy'}
                        </Paragraph>
                      </WebsiteButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row justify={'center'}>
                <Col span={12} md={12} sm={20} xs={24}>
                  <Paragraph
                    centered
                    color={'website-secondary'}
                    weight={'w500'}
                    size={'md'}
                    className={'rights-text'}
                    style={{ letterSpacing: '1.5px', width: '100%', paddingLeft: '10px' }}
                  >
                    © {new Date().getFullYear()}
                    <span className={'bold'}>
                 {' easetech. '}
               </span>
                    All Rights Reserved
                  </Paragraph>
                </Col>
              </Row>
            </Col>

          </Row>
        )
      }

    </div>
  )
}

export default Footer
