import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { WebsiteLoader } from '@/components/layout/WebsiteLoader'
import { Row, message, Space } from 'antd'
import React, { useState } from 'react'
import { useApplicationQuery, useApprovalQuery, useDeleteApplicationMutation, useDeleteProjectMutation, useUpdateApprovalMutation } from '@/graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const UnassignAgentConfirmation = () => {
  const { setIsModalOpen, currentApplicationId, currentApprovalItemId, currentId } = useGeneralContext()
  const [updateApproval, {loading: updatingApproval}] = useUpdateApprovalMutation()


  const { data, loading: appDataLoading } = useApplicationQuery({
    variables: { id: currentApplicationId },
    fetchPolicy: globalFetchPolicy,
  })
  const currentApplication = data?.application?.data

  const {data: approvalData, loading: approvalDataLoading} = useApprovalQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {id: currentApprovalItemId}
  })

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const agentsInfoData = approvalData?.approval?.data?.attributes?.agents as ComponentDataAgentInfo[]

  const onConfirm = async () => {
    const newAgentsInfo = agentsInfoData?.map(it => {
      if (it?.agentProfile?.data?.id !== currentId) {
        return {
          agentProfile: it?.agentProfile?.data?.id,
          assignStatus: it?.assignStatus,
          name: it?.name
        }
      }
      return null
    }).filter(it => it) || []

    await updateApproval({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentApprovalItemId || '',
        data: {
          agents: newAgentsInfo,
        },
      },
      onCompleted: () => {
        BlackNotification('Agent unassigned successfully')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(`Error approval item updating. ${error.message}`)
      },
      refetchQueries: 'active',
    })
  }
  if (appDataLoading) return <WebsiteLoader />
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Unassign agent'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
        {`You are going to unassign selected agent from the current application `}
        <Text size={'lg'} weight={'w700'}>{currentApplication?.attributes?.title}</Text>
      </Paragraph>
      <Row justify={'end'}>
        <Space>
          <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
          <Button text={'Confirm'} btnType={'primary'} onClick={onConfirm} loading={approvalDataLoading || appDataLoading || updatingApproval} />
        </Space>
      </Row>
    </>
  )
}
export default UnassignAgentConfirmation
